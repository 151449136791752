import './Testimonials.scss';

export default function Testimonials() {
  return (
    <div className="app-Testimonials flex items-center justify-center py-24 bg-white min-w-screen">
      <div className="px-11 md:px-16 bg-white">
        <div className="container flex flex-col items-start mx-auto lg:items-center">
          <p className="relative flex items-start justify-start w-full text-l md:text-xl font-bold tracking-wider text-indigo-500 uppercase lg:justify-center lg:items-center">
            Welcome To The Next Generation Of
          </p>

          <h2 className="leading-10 mx-auto text-3xl md:text-4xl relative mt-4 mb-2 flex items-start justify-start w-full md:max-w-3xl font-bold lg:justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="absolute right-0 hidden w-12 h-12 -mt-2 -mr-16 text-gray-200 lg:inline-block"
              viewBox="0 0 975.036 975.036"
            >
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
            </svg>
            Custom Miniature Tabletop Gaming
          </h2>
          <div className="block w-full h-1 max-w-lg mt-6 lg:mb-5 bg-gray-100 rounded-full"></div>

          <div className="items-baseline justify-center w-full mt-12 mb-4 lg:flex ">
            <div className="flex flex-col items-start justify-start w-full h-auto mb-12 lg:w-1/3 lg:mb-0 lg:px-8">
              <div className="flex items-center justify-center">
                <div className="w-full h-full overflow-hidden">
                  <a
                    href="https://anvl.co/share/51246005"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('../images/anvl-custom-mini-1.png').default}
                      className="object-contain w-3/4 h-3/4 sm:w-9/12 sm:h-9/12 mx-auto"
                      alt="ANVL Custom Mini - Legacy"
                    />
                  </a>
                </div>
              </div>
              <blockquote className="mt-8 text-lg text-gray-500">
                "Best birthday gift ever! My daughter had so much fun designing
                her custom figurine and it was super easy. Highly recommended"
              </blockquote>
              <div className="mt-4 flex flex-col items-start justify-center">
                <h4 className="font-bold text-gray-800">Ian Marshall</h4>
                <p className="text-gray-600 mt-1">⭐️⭐️⭐️⭐️⭐️</p>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full h-auto px-0 mx-0 mb-12 border-l border-r border-transparent lg:w-1/3 lg:mb-0 lg:px-8 lg:border-gray-200">
              <div className="flex items-center justify-center">
                <div className="w-full h-full overflow-hidden">
                  <a
                    href="https://anvl.co/share/42031f47"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('../images/anvl-custom-mini-2.png').default}
                      className="object-contain w-3/4 h-3/4 sm:w-9/12 sm:h-9/12 mx-auto"
                      alt="ANVL Custom Mini - Legacy"
                    />
                  </a>
                </div>
              </div>
              <blockquote className="mt-8 text-lg text-gray-500">
                "I’ve been tabletop gaming for over 35 years and this is the
                closest thing I’ve experienced to real life magic. Amazing!"
              </blockquote>
              <div className="mt-4 flex flex-col items-start justify-center">
                <h4 className="font-bold text-gray-800">Gareth Miller</h4>
                <p className="text-gray-600 mt-1">⭐️⭐️⭐️⭐️⭐️</p>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full h-auto lg:w-1/3 lg:px-8">
              <div className="flex items-center justify-center">
                <div className="w-full h-full overflow-hidden">
                  <a
                    href="https://anvl.co/share/84c49398"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('../images/anvl-custom-mini-3.png').default}
                      className="object-contain w-3/4 h-3/4 sm:w-9/12 sm:h-9/12 mx-auto"
                      alt="ANVL Custom Mini - Legacy"
                    />
                  </a>
                </div>
              </div>
              <blockquote className="mt-8 text-lg text-gray-500">
                "So much to choose from I just keep coming back for more!
                Definitely worth checking out if you're in to TTRPG miniatures"
              </blockquote>
              <div className="mt-4 flex flex-col items-start justify-center">
                <h4 className="font-bold text-gray-800">Eveline Carter</h4>
                <p className="text-gray-600 mt-1">⭐️⭐️⭐️⭐️⭐️</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="app-Testimonials flex items-center justify-center w-full px-8 pt-8 pb-0 sm:pb-8 bg-white md:py-16 lg:py-14 xl:px-0 ">
    //   <div className="max-w-6xl mx-auto">
    //     <div className="flex-col items-center">
    //       <div className="flex flex-col items-center justify-center max-w-2xl py-8 mx-auto xl:flex-row xl:max-w-full">
    //         <div className="w-full xl:w-1/2 xl:pr-8 testimonialCol">
    //           <BlockQuote
    //             name="Ian Marshall"
    //             quote="I’ve been tabletop gaming for over 35 years and this is the closest thing I’ve experienced to real life magic. It was absolutely mesmerizing to see exactly what I designed come to life before my eyes."
    //           />

    //           <BlockQuote
    //             name="Eveline Carter"
    //             quote="Best birthday gift ever! My daughter had so much fun designing her custom figurine and it was super easy for us to 3D print at home. Would highly recommend to all tabletop gamers!"
    //           />
    //         </div>

    //         <div className="w-full xl:w-1/2 xl:pl-8 testimonialCol">
    //           <BlockQuote
    //             name="Kamil Gilliam
    //             "
    //             quote="I was so happy when I found out I could make my own custom mini with ANVL. Looking forward to taking my new character to my next tabletop gaming session to do battle!"
    //           />
    //           <BlockQuote
    //             name="Gareth Miller
    //             "
    //             quote="So much to choose from I just keep coming back for more! I love this app so much I can already tell I’m going to be using it for years to come. Definitely worth checking out!"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // <section>
    //   <div>
    //     <article>
    //       <div>
    //         <p>
    //           I’ve been tabletop gaming for over 35 years and this is the
    //           closest thing I’ve experienced to real life magic. It was
    //           absolutely mesmerizing to see exactly what I designed come to life
    //           before my eyes.
    //         </p>
    //         Ian Marshall
    //       </div>
    //       <div>
    //         <p>
    //           Best birthday gift ever! My daughter had so much fun designing her
    //           custom figurine and it was super easy for us to 3D print at home.
    //           Would highly recommend to all tabletop gamers!
    //         </p>
    //         Eveline Carter
    //       </div>
    //       <div>
    //         <p>
    //           I was so happy when I found out I could make my own custom mini
    //           with ANVL. Looking forward to taking my new character to my next
    //           tabletop gaming session to do battle!
    //         </p>
    //         Kamil Gilliam
    //       </div>
    //       <div>
    //         <p>
    //           So much to choose from I just keep coming back for more! I love
    //           this app so much I can already tell I’m going to be using it for
    //           years to come. Definitely worth checking out!
    //         </p>
    //         Gareth Miller
    //       </div>
    //     </article>
    //   </div>
    //   <div> </div>
    // </section>
  );
}
