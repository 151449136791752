import { useState, useEffect } from 'react';

import * as services from './services';

export function useServices(callServiceFn, dependencies = []) {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const fetch = () => {
    if (isFetching) {
      console.log('already fetching');
      return;
    }

    setIsFetching(true);
    setError(null);

    callServiceFn(services)
      .then((data) => {
        console.log('got data>', data);
        setIsFetching(false);
        setData(data);
      })
      .catch((err) => {
        setIsFetching(false);
        setError(err.message);
        console.error('useServices failed:', err);
      });
  };

  useEffect(() => {
    fetch();
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return [data, isFetching, error, fetch];
}

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
