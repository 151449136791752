import React from 'react';
import Heading from '../components/Heading';

import './Materials.scss';

function MaterialBox(props) {
  return (
    <div className="materialBox my-3">
      <img
        className="w-72 sm:w-auto mx-auto"
        src={props.img}
        alt={props.name}
      />
      <h3>{props.name}</h3>
      <p>{props.description}</p>
    </div>
  );
}

export default function Materials() {
  return (
    <section className="app-Materials">
      <section className="py-20 lg:py-28">
        <div className="container items-center max-w-7xl px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
          <Heading
            mainHeading={
              <React.Fragment>
                Available In <strong className="text-indigo-500">7</strong> High
                Quality Materials
              </React.Fragment>
            }
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
            <MaterialBox
              name="Digital Download"
              img={require('../images/materials/download.png').default}
              description="Download high resolution STL files and 3D print your custom miniatures using your own equipment"
            />

            <MaterialBox
              name="Premium Resin"
              img={require('../images/materials/resin.png').default}
              description="Accurately capture every detail of your custom minis with our world-class quality Premium Resin plastic"
            />

            {/* <MaterialBox
              name="Full-Color Resin"
              img={require('../images/materials/color.png').default}
              description="3D print your digitally painted custom minis in spectacular full-color using our state of the art equipment"
            /> */}

            <MaterialBox
              name="Steel"
              img={require('../images/materials/steel.png').default}
              description="Crafted with absolute accuracy using a binder jetting process, this a great robust and affordable metal finish"
            />

            <MaterialBox
              name="Bronze"
              img={require('../images/materials/bronze.png').default}
              description="Give your unique miniature the perfect vintage feel with our affordable high-end Bronze finish"
            />

            <MaterialBox
              name="Brass"
              img={require('../images/materials/brass.png').default}
              description="Our polished Brass material is brilliant at showcasing every feature on your unique miniatures"
            />

            <MaterialBox
              name="Silver"
              img={require('../images/materials/silver.png').default}
              description="A beautiful high-end sterling Silver material that showcases your miniature’s intricate features"
            />

            <MaterialBox
              name="Gold"
              img={require('../images/materials/gold.png').default}
              description="Our premium 14K Gold finish will guarantee your miniature remains a timeless deluxe classic"
            />
          </div>
        </div>
      </section>
    </section>
  );
}
