import { ArrowCircleDownIcon } from '@heroicons/react/outline';

import { useMediaQuery } from 'beautiful-react-hooks';

import './GoldHero.scss';

export default function GoldHero() {
  const smallScreen = useMediaQuery('(max-width: 1500px)');

  return (
    <section className="app-GoldHero relative">
      <div
        className="heroBg bg-gray-900 box-border flex-grow w-full sm:px-10 pt-8 pb-12 mx-auto leading-6 lg:pt-64 lg:pb-80"
        id=""
      >
        <div className="top">
          <div className="verticalCenter relative w-full px-0 my-12 leading-6 text-left md:text-center lg:flex-grow-0 lg:flex-shrink-0">
            <h3 className="subHeading">Introducing</h3>
            <h1
              className="mt-0 mb-5 text-8xl md:text-4xl lg:text-5xl xl:text-6xl"
              id=""
            >
              ANVL Gold
            </h1>

            <h2 className="ribbon box-border mt-0 text-base font-normal" id="">
              <strong>
                Premium membership for the 3D printing connoisseur
              </strong>
              <span className="ribbonBg" />
            </h2>

            {/* <p
              className="box-border mt-0 text-base font-normal opacity-90 lg:text-xl xl:text-2xl"
              id=""
            >
              The best way to download and <strong>3D print </strong>
              your own <strong>custom miniatures</strong>
            </p> */}
            {/* <div className="box-border text-left  mt-5 md:text-center" id="">
            <a
            className="goldButton inline-block mt-5 mb-5 cursor-pointer select-none"
            href="#_"
            >
            Create Your Custom Miniature
            </a>
          </div> */}
          </div>

          <a
            href="#features"
            onClick={(e) => {
              e.preventDefault();

              const features = document.getElementById('features');
              if (features) {
                features.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }}
          >
            <ArrowCircleDownIcon className="icon" />
          </a>
        </div>

        <video
          src={process.env.PUBLIC_URL + (smallScreen ? '/1.mp4' : '/2.mp4')}
          autoPlay={true}
          muted
          loop
          playsInline
          controls={false}
        ></video>
      </div>
    </section>
  );
}
