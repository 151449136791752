import { useState } from 'react';

import { Link } from 'react-router-dom';
import { FaShoppingCart, FaCog, FaRegHeart } from 'react-icons/fa';
import CollectionQuickview from './CollectionQuickview';
import CollectionShareModal from './CollectionShareModal';
import COLLECTION_ITEMS from './CollectionItems';

import config from '../config';
import { runCheckout } from '../utils/frame';

import './CollectionGrid.scss';
import { BsGridFill, BsStar } from 'react-icons/bs';

function CollectionItem({ item }) {
  const [open, setOpen] = useState(false);
  const [previewMini, setPreviewMini] = useState({
    uuid: '',
    name: '',
    tagline: '',
    description: '',
    image: '',
  });

  function showPreviewMini(uuid, name, tagline, description, image) {
    setPreviewMini({ uuid, name, tagline, description, image });
    setOpen(true);
  }

  return (
    <div className="collectionItem">
      <div className="preview">
        <img
          src={item.image}
          alt={item.name}
          onClick={() =>
            showPreviewMini(
              item.uuid,
              item.name,
              item.tagline,
              item.description,
              item.image
            )
          }
        />
      </div>
      <div className="content">
        <h2>
          <span>{item.name}</span>
        </h2>

        {/* <div className="designedBy">
        <p>
          samasante - <span>June 23 20</span>
        </p>
      </div> */}

        <p className="description">{item.tagline}</p>

        {/* <p className="tagCloud">
          <span>Human</span>
          <span>Fantasy</span>
          <span>Fighter</span>
        </p> */}

        <div className="CTA w-full">
          <a
            href={`${config.appUrl}/buy/${item.uuid}`}
            className="goldButton inline-flex justify-center w-full my-4 text-center"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              runCheckout(`/buy/${item.uuid}`);
            }}
          >
            <FaShoppingCart className="mr-2" />
            <span>Add To Cart</span>
          </a>

          {/* <button
            className="goldButton inline-flex justify-center w-full my-4 text-center"
            onClick={(e) => {
              e.preventDefault();
              setOpen(true);
            }}
          >
            <FaShoppingCart className="mr-2" />
            <span>Add To Cart</span>
          </button> */}
          <a
            href={`${config.appUrl}/share/${item.uuid}`}
            className="featureButton w-full inline-flex justify-center text-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaCog className="mr-2" />
            <span>Customize</span>
          </a>
        </div>
      </div>
      <CollectionQuickview
        open={open}
        setOpen={setOpen}
        previewMini={previewMini}
      />
    </div>
  );
}

export default function CollectionGrid({ category }) {
  const [shareModalOpen, setShareModalOpen] = useState(false);

  return (
    <section className="app-CollectionGrid">
      <div className="max-w-4xl mx-auto py-12 px-4 sm:py-14 sm:px-6 lg:max-w-7xl lg:px-8">
        {category === 'Featured' ? (
          <div className="md:flex md:items-center md:justify-between  max-w-7xl mx-auto">
            <div className="flex-1 min-w-0">
              <h2 className="flex items-center text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                <BsStar className="mr-2.5 text-gray-400" />
                Custom Minis Of The Month
              </h2>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <button
                to="/collection"
                type="button"
                onClick={() => setShareModalOpen(true)}
                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <FaRegHeart className="mr-2" /> Submit Your Design
              </button>
            </div>
          </div>
        ) : (
          <div className="md:flex md:items-center md:justify-between  max-w-7xl mx-auto">
            <div className="flex-1 min-w-0">
              <h2 className="flex items-center text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                <Link to="/collection" className="flex items-center h-full">
                  Category
                </Link>
                <svg
                  className="flex-shrink-0 w-3 mt-1 items-center mx-4 h-full text-gray-500"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <span className="flex items-center h-full">{category}</span>
              </h2>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <Link
                to="/collection"
                type="button"
                className="mt-4 md:mt-0 inline-flex items-center px-4 py-2 transition featureButton no-underline align-middle border border-gray-300 shadow-sm text-lg font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <BsGridFill className="mr-2 text-gray-400" />
                View All Categories
              </Link>
            </div>
          </div>
        )}

        <hr className="my-12 max-w-7xl mx-auto" />

        <div className="gridContainer mt-14 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 w-full max-w-7xl mx-auto">
          {category === 'Featured'
            ? COLLECTION_ITEMS.map((item, idx) =>
                item.featured ? <CollectionItem key={idx} item={item} /> : ''
              )
            : COLLECTION_ITEMS.map((item, idx) =>
                item.race === category ? (
                  <CollectionItem key={idx} item={item} />
                ) : (
                  ''
                )
              )}
        </div>
      </div>

      <CollectionShareModal
        shareModalOpen={shareModalOpen}
        setShareModalOpen={setShareModalOpen}
      />
    </section>
  );
}
