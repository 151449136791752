// carbon copy of the one from frontend

import axios from 'axios';
import urljoin from 'url-join';

import config from '../config';

// const API_CLIENT_TOKEN = 'asdasdasd';

let token = '';
let store = null;

export function setToken(t) {
  token = t || '';
}

export function setStore(s) {
  store = s;
}

async function makeRequest({
  method = 'get',
  url,
  data = {},
  apiUrl = config.apiUrl,
  extraHeaders = {},
}) {
  // let authHeader = null;

  // if (isAuthenticated()) {
  //   authHeader = getAuthToken();
  // }

  const res = await axios({
    method: method,
    url: urljoin(apiUrl, url),
    [method.toLowerCase() === 'get' ? 'params' : 'data']: {
      ...data,
    },
    headers: {
      Accept: 'application/json',
      // 'X-API-CLIENT': API_CLIENT_TOKEN,
      'x-api-token': token,
      ...extraHeaders,
    },
  }).catch((err) => {
    // sometimes the request fails with no response, CORS problems etc.
    if (!err.response) {
      throw new Error('API request failed with no response');
    }

    if (err.response.data && typeof err.response.data.error === 'string') {
      if (err.response.data.error === 'User not found') {
        store.logout();
      }

      const e = new Error(err.response.data.error);
      e.code = err.response.status;
      throw e;
    }

    throw err;
  });

  if (res.status === 401 && res.data.error === 'InvalidSessionTokenError') {
    // userService.logout();

    throw new Error('You have been logged out');
  }

  return res.data;
}

export async function register(email, password, name) {
  return await makeRequest({
    method: 'post',
    url: '/register',
    data: {
      email,
      password,
      name,
    },
  });
}

export async function login(email, password) {
  return await makeRequest({
    method: 'post',
    url: '/login',
    data: {
      email,
      password,
    },
  });
}

export async function authPaypal(token) {
  return await makeRequest({
    method: 'post',
    url: '/auth/paypal',
    data: {
      token,
    },
  });
}

export async function authStripe(token) {
  return await makeRequest({
    method: 'post',
    url: '/auth/stripe',
    data: {
      token,
    },
  });
}

export async function getSelf() {
  return await makeRequest({
    method: 'get',
    url: `/users/self`,
  });
}

export async function changeSettings(name, email) {
  return await makeRequest({
    method: 'put',
    url: `/users/self`,
    data: {
      name: name,
      email: email,
    },
  });
}

export async function changePassword(oldPassword, newPassword) {
  return await makeRequest({
    method: 'put',
    url: `/users/self/password`,
    data: {
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
  });
}

export async function forgotPassword(email) {
  return await makeRequest({
    method: 'post',
    url: `/forgot-password`,
    data: {
      email: email,
    },
  });
}

export async function resetPassword(token, password) {
  return await makeRequest({
    method: 'post',
    url: `/reset-password`,
    data: {
      token: token,
      password: password,
    },
  });
}

export async function createBasket({
  countryCode,
  subscriptionId,
  isSubscribing,
}) {
  return await makeRequest({
    method: 'post',
    url: '/basket',
    data: {
      countryCode,
      subscriptionId,
      isSubscribing,
    },
  });
}

export async function getBasket(
  basketId,
  { countryCode, subscriptionId, isSubscribing }
) {
  return await makeRequest({
    method: 'get',
    url: `/basket/${basketId}`,
    data: {
      countryCode,
      subscriptionId,
      isSubscribing,
    },
  });
}

export async function addBasketItem(basketId, { model, image, quantity }) {
  return await makeRequest({
    method: 'post',
    url: `/basket/${basketId}/items`,
    data: {
      model,
      image,
    },
  });
}

export async function updateBasketItem(
  basketId,
  basketItemId,
  { model, image, quantity }
) {
  return await makeRequest({
    method: 'post',
    url: `/basket/${basketId}/items/${basketItemId}`,
    data: {
      model,
      image,
      quantity,
    },
  });
}

export async function removeBasketItem(basketId, basketItemId) {
  return await makeRequest({
    method: 'delete',
    url: `/basket/${basketId}/items/${basketItemId}`,
    data: {},
  });
}

export async function addBasketCoupon(basketId, couponCode) {
  return await makeRequest({
    method: 'post',
    url: `/basket/${basketId}/coupon`,
    data: {
      couponCode,
    },
  });
}

export async function removeBasketCoupon(basketId) {
  return await makeRequest({
    method: 'delete',
    url: `/basket/${basketId}/coupon`,
  });
}

export async function shareCharacter({ name, model, ...data }) {
  return await makeRequest({
    method: 'post',
    url: '/shared/characters',
    data: {
      name: name,
      model: model,
      ...data,
    },
  });
}

export async function getSharedCharacter(shareUuid, forceProduction = false) {
  return await makeRequest({
    method: 'get',
    url: `/shared/characters/${shareUuid}`,

    // allow dev to fetch data from production
    apiUrl: forceProduction ? 'https://api.anvl.co' : undefined,
  });
}

export async function getSavedCharacters() {
  return await makeRequest({
    method: 'get',
    url: '/users/self/characters',
    extraHeaders: {
      'x-anvl-charactersresponsetype': 'legacy',
    },
  });
}

export async function saveCharacter({ name, model, ...data }) {
  return await makeRequest({
    method: 'post',
    url: '/users/self/characters',
    data: {
      name: name,
      model: model,
      ...data,
    },
  });
}

export async function deleteCharacter(saveUuid) {
  return await makeRequest({
    method: 'delete',
    url: `/users/self/characters/${saveUuid}`,
  });
}

export async function getSavedCharacter(saveUuid) {
  return await makeRequest({
    method: 'get',
    url: `/users/self/characters/${saveUuid}`,
  });
}

export async function updateSavedCharacter(saveUuid, { name, model, ...data }) {
  return await makeRequest({
    method: 'put',
    url: `/users/self/characters/${saveUuid}`,
    data: {
      name: name,
      model: model,
      ...data,
    },
  });
}

export async function orderBasket({
  amount,
  currency,
  items,
  shippingAddress,
  paymentToken,
  couponCode = '',
  paymentType,
  subscriptionId,
  basketId,
}) {
  return await makeRequest({
    method: 'post',
    url: '/users/self/orders',
    data: {
      amount,
      currency,
      items,
      shippingAddress,
      paymentToken,
      couponCode,
      paymentType,
      subscriptionId,
      basketId,
    },
  });
}

export async function getOrders() {
  return await makeRequest({
    method: 'get',
    url: '/users/self/orders',
    extraHeaders: {
      // @todo in a future update we can use this to control old cients
      // we also do this in anvl-website
      'x-anvl-ordersresponsetype': 'legacy',
    },
  });
}

export async function getOrder(orderId) {
  return await makeRequest({
    method: 'get',
    url: `/users/self/orders/${orderId}`,
  });
}

export async function validateCoupon(couponCode) {
  return await makeRequest({
    method: 'get',
    url: `/coupons/${couponCode}`,
  });
}

export async function downloadSTL(orderId, orderItemId) {
  const { stlUrl } = await makeRequest({
    method: 'get',
    url: `/users/self/orders/${orderId}/items/${orderItemId}/download`,
  });

  window.location = stlUrl;
}

export async function getDownloadInfo(orderItemId) {
  return await makeRequest({
    method: 'get',
    url: `/orderItem/${orderItemId}/download`,
  });
}

export async function createPaymentIntent(
  amount,
  currency = 'USD',
  type = 'stripe'
) {
  return await makeRequest({
    method: 'post',
    url: `/users/self/create_payment_intent`,
    data: {
      type: type,
      amount: amount,
      currency: currency,
    },
  });
}

export async function getSubscriptionPlans() {
  return await makeRequest({
    method: 'get',
    url: `/plans`,
  });
}

export async function getSubscriptionStatus() {
  return await makeRequest({
    method: 'get',
    url: `/users/self/subscriptions/status`,
  });
}

export async function getSubscriptions() {
  return await makeRequest({
    method: 'get',
    url: `/users/self/subscriptions`,
  });
}

export async function createSubscription(
  subscriptionPlanId,
  paymentSubscriptionToken,
  type = 'paypal'
) {
  return await makeRequest({
    method: 'post',
    url: `/users/self/subscriptions`,
    data: {
      type: type,
      subscriptionPlanId: subscriptionPlanId,
      paymentSubscriptionToken: paymentSubscriptionToken,
    },
  });
}

export async function cancelSubscription(subscriptionId, type = 'paypal') {
  return await makeRequest({
    method: 'delete',
    url: `/users/self/subscriptions/${subscriptionId}`,
    data: {},
  });
}

export async function getProfile() {
  return await makeRequest({
    method: 'get',
    url: `/users/self/profile`,
  });
}

export async function createProfile(userData = {}) {
  return await makeRequest({
    method: 'post',
    url: `/users/self/profile`,
    data: {
      ...userData,
    },
  });
}

export async function updateProfile(userData = {}) {
  return await makeRequest({
    method: 'put',
    url: `/users/self/profile`,
    data: {
      ...userData,
    },
  });
}

export async function getProfileById(profileIdOrName) {
  return await makeRequest({
    method: 'get',
    url: `/profiles/${profileIdOrName}`,
  });
}

export async function getProfileSharedCharacters(profileIdOrName) {
  return await makeRequest({
    method: 'get',
    url: `/profiles/${profileIdOrName}/shared/characters`,
  });
}

export async function followProfile(profileIdOrName) {
  return await makeRequest({
    method: 'post',
    url: `/users/self/profiles/${profileIdOrName}/follow`,
  });
}

export async function unfollowProfile(profileIdOrName) {
  return await makeRequest({
    method: 'delete',
    url: `/users/self/profiles/${profileIdOrName}/follow`,
  });
}

export async function getOrderQuote(order) {
  return await makeRequest({
    method: 'post',
    url: `/pricing/quote/order`,
    data: {
      ...order,
    },
  });
}

export async function getCredits() {
  const { data } = await makeRequest({
    method: 'get',
    url: '/users/self/credits',
    data: {
      available: true,
    },
  });

  return data;
}

export async function getCreditsPaginated(page, filters = {}) {
  return await makeRequest({
    method: 'get',
    url: '/users/self/credits',
    data: {
      page: page,
      ...filters,
    },
  });
}
