import { Link } from 'react-router-dom';

import config from '../config';
import Heading from '../components/Heading';

import './Inspiration.scss';

function CharacterRender(props) {
  return (
    <div className="app-CharacterRender flex items-center justify-center">
      <a href={props.href}>
        <img
          src={props.image}
          alt="custom miniature"
          className="block object-contain h-64"
        />
      </a>
    </div>
  );
}

export default function Inspiration({
  moreButtonText = 'Learn More',
  moreButtonUrl = '/features',
  mainHeading = 'Create Your Own Custom Miniatures',
  ribbon = 'Need some inspiration? Start with one of the characters below',
  description = `ANVL Custom Miniatures gives you complete control over the look and
            feel of your tabletop figurines and allows you to design your
            perfect custom miniature with any game in mind. Get started by
            choosing from a huge selection of content and enjoy crafting your
            perfect character with ease using trillions of unique combinations of
            weapons, clothing and accessories!`,
}) {
  return (
    <section className="app-Inspiration w-full py-16 pb-20 bg-white">
      <div className="text-center container px-8 mx-auto sm:px-12 lg:px-20">
        {/* <h1 className="mb-3 text-3xl font-bold leading-tight text-center text-gray-900 lg:text-4xl">
          Create Your Own Custom Miniatures
        </h1> */}

        <Heading mainHeading={mainHeading} ribbon={ribbon} />

        <div className="grid grid-cols-2 gap-2 py-16 mb-0 text-center lg:grid-cols-7">
          <CharacterRender
            image={require('../images/marq-model1.png').default}
            href="https://anvl.co/share/fac9dc57"
          />
          <CharacterRender
            image={require('../images/marq-model2.png').default}
            href="https://anvl.co/share/b3a0f55a"
          />
          <CharacterRender
            image={require('../images/marq-model3.png').default}
            href="https://anvl.co/share/85dca11f"
          />
          <CharacterRender
            image={require('../images/marq-model4.png').default}
            href="https://anvl.co/share/1c3e2376"
          />
          <CharacterRender
            image={require('../images/marq-model5.png').default}
            href="https://anvl.co/share/0694b271"
          />
          <CharacterRender
            image={require('../images/marq-model6.png').default}
            href="https://anvl.co/share/ed74b47a"
          />
          <div className="hidden lg:block">
            <CharacterRender
              image={require('../images/marq-model7.png').default}
              href="https://anvl.co/share/e20b07e3"
            />
          </div>
        </div>
        <div className="w-full text-center">
          <p className="max-w-5xl mx-auto text-lg text-center text-gray-600">
            {description}
          </p>
          <div className="flex flex-wrap items-center justify-center mt-3">
            <a
              href={config.appUrl}
              className="goldButton mt-7 mx-3 inline-flex items-center"
            >
              <span className="">Start Creating</span>
              <svg viewBox="0 0 20 20" fill="currentColor" className="h-4 ml-1">
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>

            <Link
              to={moreButtonUrl}
              className="featureButton mx-3 mt-7 inline-flex items-center"
            >
              <span>{moreButtonText}</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
