import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';
import { Helmet } from 'react-helmet-async';

import './App.css';

import config from './config';

import Header from './components/Header';
import Footer from './components/Footer';

import Hero from './components/Hero';
import ThreeSteps from './components/ThreeSteps';
import Features from './components/Features';
import CollectionCTA from './components/CollectionCTA';
import Testimonials from './components/Testimonials';
import Newsletter from './components/Newsletter';
import Inspiration from './components/Inspiration';

import FeatureHero from './components/FeatureHero';
import FourPoints from './components/FourPoints';
import ShowReel from './components/ShowReel';
import Blueprint from './components/Blueprint';
import Materials from './components/Materials';
import NewsletterSlim from './components/NewsletterSlim';

import SubpageHero from './components/SubpageHero';
import CollectionGrid from './components/CollectionGrid';

import GoldFeatures from './components/GoldFeatures';

import ContactForm from './components/ContactForm';
import ContactExtras from './components/ContactExtras';
import NewsletterAlt from './components/NewsletterAlt';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Deals from './components/Deals';
import GoldCallout from './components/GoldCallout';

import GoldHero from './components/GoldHero';
import GoldPricing from './components/GoldPricing';
import GoldCTA from './components/GoldCTA';
import DealsCTA from './components/DealsCTA';

import ContactAlt from './components/ContactAlt';

import AmbassadorFeatures from './components/AmbassadorFeatures';
import PressKit from './components/PressKit';
import MissionStatement from './components/MissionStatement';

import ContactAdvent from './components/ContactAdvent';
import StoreResin from './components/StoreResin';
import StoreDownload from './components/StoreDownload';
import ViewMini from './components/ViewMini';
import SavedCharacters from './components/SavedCharacters';
import Orders from './routes/Orders';
import Credits from './routes/Credits';
// import Subscriptions from './routes/Subscriptions';
import CollectionCategories from './components/CollectionCategories';
import SuggestForm from './components/SuggestForm';
import PatreonCTA from './components/PatreonCTA';

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollMemory />
        <Helmet>
          <title>ANVL Custom Miniatures - Bring Your Characters To Life</title>
          <link rel="canonical" href={`${config.appUrl}/custom-miniatures`} />

          <meta
            name="description"
            content="Bring your characters to life and design your perfect custom miniature figurines with over two trillion combinations of weapons, items and outfits to choose from!"
          />
        </Helmet>

        <Header />

        <Switch>
          <Route exact path={['/', '/custom-miniatures']}>
            <Hero />
            <ThreeSteps />
            <Features />
            <CollectionCTA />
            <Testimonials />
            <Newsletter />
            <Inspiration
              moreButtonText="Learn More"
              moreButtonUrl="/features"
            />

            <Helmet>
              <title>
                ANVL Custom Miniatures - Bring Your Characters To Life
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/custom-miniatures`}
              />

              <meta
                name="title"
                content="ANVL Custom Miniatures - Bring Your Characters To Life"
              />
              <meta
                name="description"
                content="Design your perfect custom miniature figurines with over two trillion combinations of weapons, items and outfits to choose from!"
              />

              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content="https://anvl.co/custom-miniatures"
              />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Bring Your Characters To Life"
              />
              <meta
                property="og:description"
                content="Design your perfect custom miniature figurines with over two trillion combinations of weapons, items and outfits to choose from!"
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-home.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:url"
                content="https://anvl.co/custom-miniatures"
              />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Bring Your Characters To Life"
              />
              <meta
                property="twitter:description"
                content="Design your perfect custom miniature figurines with over two trillion combinations of weapons, items and outfits to choose from!"
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-home.jpg"
              />
            </Helmet>
          </Route>

          <Route path="/features">
            <FeatureHero />
            <FourPoints />
            <ShowReel
              moreButtonText="View Collection"
              moreButtonUrl="/collection"
            />
            <Blueprint />
            <Materials />
            <NewsletterSlim />
            <Inspiration
              moreButtonText="View Collection"
              moreButtonUrl="/collection"
            />

            <Helmet>
              <title>ANVL Custom Miniatures - Features</title>
              <link rel="canonical" href={`${config.appUrl}/features`} />

              <meta name="title" content="ANVL Custom Miniatures - Features" />
              <meta
                name="description"
                content="Create your own custom miniatures with ANVL. Dive straight in with our beautiful and easy to use web and mobile application."
              />

              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://anvl.co/features" />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Features"
              />
              <meta
                property="og:description"
                content="Create your own custom miniatures with ANVL. Dive straight in with our beautiful and easy to use web and mobile application."
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-features.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://anvl.co/features" />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Features"
              />
              <meta
                property="twitter:description"
                content="Create your own custom miniatures with ANVL. Dive straight in with our beautiful and easy to use web and mobile application."
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-features.jpg"
              />
            </Helmet>
          </Route>

          <Route exact path="/collection">
            <SubpageHero
              title="Explore The Collection"
              subtitle="The world's finest miniatures. Designed with"
              image="collectionHero"
              subtitleAnvlSuffix
            />
            <CollectionCategories />
            <CollectionGrid category="Featured" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>ANVL Custom Miniatures - Collection</title>
              <link rel="canonical" href={`${config.appUrl}/collection`} />

              <meta
                name="title"
                content="ANVL Custom Miniatures - Collection"
              />
              <meta
                name="description"
                content="Choose from our hand-picked selection of the finest custom tabletop miniatures designed with ANVL. Available to customize or purchase today."
              />

              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://anvl.co/collection" />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Collection"
              />
              <meta
                property="og:description"
                content="Choose from our hand-picked selection of the finest custom tabletop miniatures designed with ANVL. Available to customize or purchase today."
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-collection.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:url"
                content="https://anvl.co/collection"
              />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Collection"
              />
              <meta
                property="twitter:description"
                content="Choose from our hand-picked selection of the finest custom tabletop miniatures designed with ANVL. Available to customize or purchase today."
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-collection.jpg"
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-human-miniatures">
            <SubpageHero title="Custom Human Minis" image="collectionHero" />

            <CollectionGrid category="Human" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>Custom Human Miniatures - Custom DnD Minis - ANVL</title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-human-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom human miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-dwarf-miniatures">
            <SubpageHero title="Custom Dwarf Minis" image="collectionHero" />
            <CollectionGrid category="Dwarf" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>Custom Dwarf Miniatures - Custom RPG Minis - ANVL</title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-dwarf-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom dwarf miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-orc-miniatures">
            <SubpageHero title="Custom Orc Minis" image="collectionHero" />
            <CollectionGrid category="Orc" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Orc Miniatures - Custom Tabletop Minis - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-orc-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom orc miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-cyclops-miniatures">
            <SubpageHero title="Custom Cyclops Minis" image="collectionHero" />
            <CollectionGrid category="Cyclops" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Cyclops Miniatures - Custom DnD Miniatures - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-cyclops-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom cyclops miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-goblin-miniatures">
            <SubpageHero title="Custom Goblin Minis" image="collectionHero" />
            <CollectionGrid category="Goblin" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Goblin Miniatures - Custom TTRPG Miniatures - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-goblin-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom goblin miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-skeleton-miniatures">
            <SubpageHero title="Custom Skeleton Minis" image="collectionHero" />
            <CollectionGrid category="Skeleton" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Skeleton Miniatures - Custom RPG Miniatures - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-skeleton-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom skeleton miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-elf-miniatures">
            <SubpageHero title="Custom Elf Minis" image="collectionHero" />
            <CollectionGrid category="Elf" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Elf Miniatures - Dungeons &amp; Dragons Minis - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-elf-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom elf miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-halfling-miniatures">
            <SubpageHero title="Custom Halfling Minis" image="collectionHero" />
            <CollectionGrid category="Halfling" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Halfling Miniatures - Custom Board Game Minis - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-halfing-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom halfling miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-tiefling-miniatures">
            <SubpageHero title="Custom Tiefling Minis" image="collectionHero" />
            <CollectionGrid category="Tiefling" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Tiefling Miniatures - Custom Pathfinder Minis - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-tiefling-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom tiefling miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-dragon-miniatures">
            <SubpageHero title="Custom Dragon Minis" image="collectionHero" />
            <CollectionGrid category="Dragon" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Dragon Miniatures - Create Custom Minis - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-dragon-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom dragon miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-catfolk-miniatures">
            <SubpageHero title="Custom Catfolk Minis" image="collectionHero" />
            <CollectionGrid category="Cat" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Catfolk Miniatures - Custom DnD Miniatures - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-catfolk-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom catfolk miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-wolf-miniatures">
            <SubpageHero title="Custom Wolf Minis" image="collectionHero" />
            <CollectionGrid category="Wolf" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Wolf Miniatures - Create Tabletop Minis - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-wolf-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom wolf miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-frog-miniatures">
            <SubpageHero title="Custom Frog Minis" image="collectionHero" />
            <CollectionGrid category="Frog" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Frog Miniatures - Create DnD Miniatures - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-frog-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom frog miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-eagle-miniatures">
            <SubpageHero title="Custom Eagle Minis" image="collectionHero" />
            <CollectionGrid category="Eagle" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Eagle Miniatures - Create Tabletop Miniatures - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-human-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom human miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-crystal-miniatures">
            <SubpageHero title="Custom Crystal Minis" image="collectionHero" />
            <CollectionGrid category="Crystal" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>Custom Crystal Miniatures - Design DnD Minis - ANVL</title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-crystal-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom crystal miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/custom-minotaur-miniatures">
            <SubpageHero title="Custom Minotaur Minis" image="collectionHero" />
            <CollectionGrid category="Minotaur" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Minotaur Miniatures - Design Tabletop Minis - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-minotaur-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom minotaur miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route exact path="/collection/custom-treant-miniatures">
            <SubpageHero title="Custom Treant Minis" image="collectionHero" />
            <CollectionGrid category="Treant" />

            <NewsletterSlim />
            <ShowReel moreButtonText="View Store" moreButtonUrl="/store" />

            <Helmet>
              <title>
                Custom Treant Miniatures - Create DnD Characters - ANVL
              </title>
              <link
                rel="canonical"
                href={`${config.appUrl}/collection/custom-treant-miniatures`}
              />
              <meta
                name="description"
                content="Explore our hand-picked range of custom treant miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/collection/mini">
            <SubpageHero />

            <ViewMini />

            <Helmet>
              <title>ANVL Custom Miniatures - Collection</title>
              <link rel="canonical" href={`${config.appUrl}/collection`} />
              <meta
                name="description"
                content="Choose from our hand-picked selection of the finest custom tabletop miniatures designed with ANVL. Available to customize or purchase today."
              />
            </Helmet>
          </Route>

          <Route path="/deals">
            <SubpageHero
              title="Deals & Subscriptions"
              subtitle="Stay ahead of the game with"
              image="dealsHero"
              subtitleAnvlSuffix={true}
            />

            <Deals />

            <NewsletterAlt />
                {/* <GoldCallout /> */}

            <Helmet>
              <title>ANVL Custom Miniatures - Deals</title>
              <link rel="canonical" href={`${config.appUrl}/deals`} />

              <meta name="title" content="ANVL Custom Miniatures - Deals" />
              <meta
                name="description"
                content="Enjoy huge savings on all ANVL custom tabletop minis."
              />

              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://anvl.co/deals" />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Deals"
              />
              <meta
                property="og:description"
                content="Enjoy huge savings on all ANVL custom tabletop minis."
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-deals.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://anvl.co/deals" />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Deals"
              />
              <meta
                property="twitter:description"
                content="Enjoy huge savings on all ANVL custom tabletop minis."
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-deals.jpg"
              />
            </Helmet>
          </Route>

          <Route path="/disabled/gold" exact>
            <GoldHero />
            <GoldFeatures />
            <GoldCTA />
            <GoldPricing />
            <ShowReel
              moreButtonText="View Collection"
              moreButtonUrl="/collection"
            />
            <NewsletterSlim />
            <Inspiration
              mainHeading="Design Your Perfect Custom Figurine"
              ribbon="Need some inspiration? Start with one of the characters below"
              description="Forge the perfect hero for your tabletop games by stepping up to our virtual workshop. The sky's the limit and no idea is out of reach with ANVL Custom Miniatures. With trillions of unique combinations our library of high-quality items, accessories and outfits is guaranteed to deliver the perfect custom figurine to a tabletop near you!"
            />

            <DealsCTA />

            <Helmet>
              <title>ANVL Custom Miniatures - ANVL Gold</title>
              <link rel="canonical" href={`${config.appUrl}/gold`} />

              <meta name="title" content="ANVL Custom Miniatures - Gold" />
              <meta
                name="description"
                content="ANVL Gold is the best way to design and download your one of a kind custom miniature figurines. Ideal for 3D printing and tabletop gaming enthusiasts."
              />

              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://anvl.co/gold" />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Gold"
              />
              <meta
                property="og:description"
                content="ANVL Gold is the best way to design and download your one of a kind custom miniature figurines. Ideal for 3D printing and tabletop gaming enthusiasts."
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-gold.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://anvl.co/gold" />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Gold"
              />
              <meta
                property="twitter:description"
                content="ANVL Gold is the best way to design and download your one of a kind custom miniature figurines. Ideal for 3D printing and tabletop gaming enthusiasts."
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-gold.jpg"
              />
            </Helmet>
          </Route>

          <Route path="/store" exact>
            <SubpageHero
              title="Shop Custom Miniatures"
              subtitle="Buy credits and discover bundle deals from"
              subtitleAnvlSuffix={true}
              image="storeHero"
            />
            <StoreResin />
            <StoreDownload />

            <NewsletterSlim />
            {/* <Inspiration
              mainHeading="Create Your Own Custom TTRPG Minis"
              ribbon="Need some inspiration? Start with one of the characters below"
              description="Forge the perfect hero for your tabletop games by stepping up to our virtual workshop. The sky's the limit and no idea is out of reach with ANVL Custom Miniatures. With trillions of unique combinations our library of high-quality items, accessories and outfits is guaranteed to deliver the perfect custom figurine to a tabletop near you!"
            /> */}

            <ShowReel
              mainHeading="Create Your Own Custom TTRPG Minis"
              moreButtonText="View Collection"
              moreButtonUrl="/collection"
            />
            <DealsCTA />

            <Helmet>
              <title>ANVL Custom Miniatures - Store</title>
              <link rel="canonical" href={`${config.appUrl}/store`} />

              <meta name="title" content="ANVL Custom Miniatures - Store" />
              <meta
                name="description"
                content="Shop our super affordable bundle packs, get instant access to our powerful character creator and start designing your custom miniatures right away!"
              />

              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://anvl.co/store" />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Store"
              />
              <meta
                property="og:description"
                content="Shop our super affordable bundle packs, get instant access to our powerful character creator and start designing your custom miniatures right away!"
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-store.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://anvl.co/store" />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Store"
              />
              <meta
                property="twitter:description"
                content="Shop our super affordable bundle packs, get instant access to our powerful character creator and start designing your custom miniatures right away!"
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-store.jpg"
              />
            </Helmet>
          </Route>

          <Route path="/press">
            <SubpageHero
              title="Press Inquiries"
              subtitle="Discover the future of tabletop gaming"
              subtitleAnvlSuffix={false}
              image="pressHero"
            />

            <ContactAlt
              title="Get In Touch"
              description="Interested in writing about ANVL Custom Miniatures? Let us know how we can help!"
            />
            <MissionStatement />
            <PressKit />

            <Helmet>
              <title>ANVL Custom Miniatures - Press Kit</title>
              <link rel="canonical" href={`${config.appUrl}/press`} />

              <meta name="title" content="ANVL Custom Miniatures - Press" />
              <meta
                name="description"
                content="Interested in writing about ANVL Custom Miniatures? Let us know how we can help!"
              />

              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://anvl.co/press" />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Press"
              />
              <meta
                property="og:description"
                content="Interested in writing about ANVL Custom Miniatures? Let us know how we can help!"
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-press.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://anvl.co/press" />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Press"
              />
              <meta
                property="twitter:description"
                content="Interested in writing about ANVL Custom Miniatures? Let us know how we can help!"
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-press.jpg"
              />
            </Helmet>
          </Route>

          <Route path="/partnership-requests">
            <SubpageHero
              title="Partnership requests"
              subtitle="Become a member of the alliance"
              image="partnersHero"
              subtitleAnvlSuffix={false}
            />

            <ContactAlt
              title="Let's Join Forces"
              description="We're shaping the future of the tabletop gaming industry. Want to
            get involved? We'd love to hear more!"
            />
            <ContactExtras />

            <Helmet>
              <title>ANVL Custom Miniatures - Partnership Requests</title>
              <link
                rel="canonical"
                href={`${config.appUrl}/partnership-requests`}
              />

              <meta
                name="title"
                content="ANVL Custom Miniatures - Partnership Requests"
              />
              <meta
                name="description"
                content="We're shaping the future of the tabletop gaming industry. Want to
                get involved? We'd love to hear more!"
              />

              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content="https://anvl.co/partnership-requests"
              />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Partnership Requests"
              />
              <meta
                property="og:description"
                content="We're shaping the future of the tabletop gaming industry. Want to
                get involved? We'd love to hear more!"
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-partners.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:url"
                content="https://anvl.co/partnership-requests"
              />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Partnership Requests"
              />
              <meta
                property="twitter:description"
                content="We're shaping the future of the tabletop gaming industry. Want to
                get involved? We'd love to hear more!"
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-partners.jpg"
              />
            </Helmet>
          </Route>

          <Route path="/ambassador-program">
            <SubpageHero
              title="Ambassador Program"
              subtitle="Spread the word & earn commission"
              subtitleAnvlSuffix={false}
              image="ambassadorHero"
            />
            <AmbassadorFeatures />
            <ContactAlt
              title="Register Your Interest"
              description="If you're interested in becoming an ANVL Brand Ambassador, simply fill in the form below. We aim to respond to all inquiries within 72 hours."
            />
            <ContactExtras />

            <Helmet>
              <title>ANVL Custom Miniatures - Ambassador Program</title>
              <link
                rel="canonical"
                href={`${config.appUrl}/ambassador-program`}
              />

              <meta
                name="title"
                content="ANVL Custom Miniatures - Ambassador Program"
              />
              <meta
                name="description"
                content="Join our brand ambassador program and receive 10% commission on every sale you refer to us using your own personal link."
              />

              <meta property="og:type" content="website" />
              <meta
                property="og:url"
                content="https://anvl.co/ambassador-program"
              />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Ambassador Program"
              />
              <meta
                property="og:description"
                content="Join our brand ambassador program and receive 10% commission on every sale you refer to us using your own personal link."
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-ambassador.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta
                property="twitter:url"
                content="https://anvl.co/ambassador-program"
              />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Ambassador Program"
              />
              <meta
                property="twitter:description"
                content="Join our brand ambassador program and receive 10% commission on every sale you refer to us using your own personal link."
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-ambassador.jpg"
              />
            </Helmet>
          </Route>

          <Route path="/privacy">
            <PrivacyPolicy />

            <Helmet>
              <title>ANVL Custom Miniatures - Privacy Policy</title>
              <link rel="canonical" href={`${config.appUrl}/privacy`} />
            </Helmet>
          </Route>

          <Route path="/tos">
            <TermsAndConditions />

            <Helmet>
              <title>ANVL Custom Miniatures - Terms & Conditions</title>
              <link rel="canonical" href={`${config.appUrl}/tos`} />
            </Helmet>
          </Route>

          <Route path="/contact">
            <SubpageHero
              title="Contact Us"
              subtitle="Available to help you on your quest"
              image="contactHero"
              subtitleAnvlSuffix={false}
            />

            <ContactForm />
            <ContactExtras />
            <NewsletterSlim />
            <Inspiration />

            <Helmet>
              <title>ANVL Custom Miniatures - Contact Us</title>
              <link rel="canonical" href={`${config.appUrl}/contact`} />

              <meta
                name="title"
                content="ANVL Custom Miniatures - Contact Us"
              />
              <meta
                name="description"
                content="Our friendly support team are here to help you every step of the way."
              />

              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://anvl.co/contact" />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Contact Us"
              />
              <meta
                property="og:description"
                content="Our friendly support team are here to help you every step of the way."
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-contact.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://anvl.co/contact" />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Contact Us"
              />
              <meta
                property="twitter:description"
                content="Our friendly support team are here to help you every step of the way."
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-contact.jpg"
              />
            </Helmet>
          </Route>

          <Route path="/licenses">
            <SubpageHero
              title="Licensing"
              subtitle="Questions? We're here to help"
              image="licensesHero"
              subtitleAnvlSuffix={false}
            />

            <ContactForm title="Inquire today" description="" />

            <Helmet>
              <title>ANVL Custom Miniatures - Licensing</title>
              <link rel="canonical" href={`${config.appUrl}/licenses`} />
            </Helmet>
          </Route>

          <Route path="/advent">
            <SubpageHero
              title="Advent Calendar"
              subtitle="Get 25 days of exclusive discounts from"
              image="adventHero"
              subtitleAnvlSuffix={true}
            />

            <ContactAdvent
              title="The Best Deals Of The Year"
              description="If you like free minis, discounts and special offers, you're not going to want to miss this! Sign up to receive a brand new deal in your inbox every day. Be sure to subscribe now, as offers change daily 🎁 "
            />
            <ContactExtras title="Discover ANVL Custom Miniatures" />

            <Helmet>
              <title>ANVL Custom Miniatures - Advent Calendar</title>
              <link
                rel="canonical"
                href={`${config.appUrl}/partnership-requests`}
              />
              <meta
                name="description"
                content="We're shaping the future of the tabletop gaming industry. Want to
                get involved? We'd love to hear more!"
              />
            </Helmet>
          </Route>

          <Route path="/characters">
            <SubpageHero
              title="My Characters"
              image="charactersHero"
              subtitleAnvlSuffix={true}
            />

            <SavedCharacters />

            <Helmet>
              <title>ANVL Custom Miniatures - My Characters</title>
              <link rel="canonical" href={`${config.appUrl}/characters`} />
              <meta
                name="description"
                content="View and manage your saved characters from ANVL Custom Miniatures."
              />
            </Helmet>
          </Route>

          <Route path="/orders">
            <SubpageHero
              title="Orders"
              image="charactersHero"
              subtitleAnvlSuffix={true}
            />

            <Orders />

            <Helmet>
              <title>ANVL Custom Miniatures - Orders</title>
              <link rel="canonical" href={`${config.appUrl}/characters`} />
              <meta
                name="description"
                content="Keep track of all your custom mini orders from ANVL Custom Miniatures."
              />
            </Helmet>
          </Route>

          <Route path="/credits">
            <SubpageHero
              // title="Subscriptions & Credits"
              title="Credits"
              image="dealsHero"
              subtitleAnvlSuffix={true}
            />

            <div className="w-full overflow-x-auto">
              <Credits />
              {/* <Subscriptions /> */}
            </div>

            <Helmet>
              {/* <title>ANVL Custom Miniatures - Credits & Subscriptions</title> */}
              <title>ANVL Custom Miniatures - Credits</title>
              <link rel="canonical" href={`${config.appUrl}/credits`} />
              <meta
                name="description"
                // content="Manage your ANVL custom mini credits and tabletop gaming miniature subscriptions."
                content="Manage your ANVL custom mini credits."
              />
            </Helmet>
          </Route>

          <Route path="/suggest">
            <SubpageHero
              title="Suggest New Content"
              image="suggestHero"
              subtitleAnvlSuffix={true}
            />

            <SuggestForm
              title="Something you need?"
              description="Looking for something specific to help create your next custom mini? ANVL are here to help! Suggest new content and feature requests here and we'll do our best to include your suggestions in a future update."
            />

            <PatreonCTA />
            {/* <Testimonials /> */}

            <hr />

            <ShowReel />

            <ContactExtras />

            <Helmet>
              <title>ANVL Custom Miniatures - Suggest New Content</title>
              <link rel="canonical" href={`${config.appUrl}/suggest`} />

              <meta
                name="title"
                content="ANVL Custom Miniatures - Suggest New Content"
              />
              <meta
                name="description"
                content="Need something specific for your next custom mini? ANVL is here to help! Suggest new content here and we'll do our best to add your request in a future update."
              />

              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://anvl.co/suggest" />
              <meta
                property="og:title"
                content="ANVL Custom Miniatures - Suggest New Content"
              />
              <meta
                property="og:description"
                content="Need something specific for your next custom mini? ANVL is here to help! Suggest new content here and we'll do our best to add your request in a future update."
              />
              <meta
                property="og:image"
                content="https://anvl.co/custom-miniatures/og-suggest.jpg"
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content="https://anvl.co/suggest" />
              <meta
                property="twitter:title"
                content="ANVL Custom Miniatures - Suggest New Content"
              />
              <meta
                property="twitter:description"
                content="Need something specific for your next custom mini? ANVL is here to help! Suggest new content here and we'll do our best to add your request in a future update."
              />
              <meta
                property="twitter:image"
                content="https://anvl.co/custom-miniatures/og-suggest.jpg"
              />
            </Helmet>
          </Route>
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
