// import { Link } from 'react-router-dom';
import { FaClock } from 'react-icons/fa';

import config from '../config';
import { useAuth } from '../utils/auth';
import { formatDate } from '../utils/date';
import { runCheckout } from '../utils/frame';
import { useServices } from '../utils/hooks';
import { formatMaterial } from '../utils/materials';

export default function Orders() {
  useAuth();
  const [orders] = useServices((s) => s.getOrders());

  if (!orders) {
    return null;
  }

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 py-0 sm:py-4 sm:px-6">
        {orders.length === 0 && (
          <div className="my-12 flex items-center flex-col justify-center bg-indigo-100 border border-indigo-200 rounded-md p-8">
            <h2 className="headingFont text-2xl">
              You do not currently have any orders to display
            </h2>
            <a className="mt-6 goldButtonApp p-4 px-7" href={config.appUrl}>
              Create new character
            </a>
          </div>
        )}

        {/* <div className="max-w-xl">
          <h1
            id="your-orders-heading"
            className="text-3xl font-extrabold tracking-tight text-gray-900"
          >
            Your Orders
          </h1>
          <p className="mt-2 text-sm text-gray-500">
            Check the status of recent orders, manage returns, and discover
            similar products.
          </p>
        </div> */}

        {orders.map((order) => (
          <div key={order.id} className="my-12 border p-8 rounded-lg shadow-sm">
            <section key={order.id} aria-labelledby={`${order.id}-heading`}>
              <div className="space-y-1 md:flex md:items-baseline md:space-y-0 md:space-x-4">
                <h2
                  id={`${order.id}-heading`}
                  className="text-lg font-medium text-gray-900 md:flex-shrink-0"
                >
                  Order #{order.id}
                </h2>
                <div className="space-y-5 md:flex-1 md:min-w-0 sm:flex sm:items-baseline sm:justify-between sm:space-y-0">
                  <p className="text-sm font-bold text-white bg-green-400 p-1 px-3 rounded-full inline-flex">
                    Complete
                  </p>
                  <div className="flex text-sm font-medium">
                    <span className="text-gray-600 flex gap-2 items-center">
                      <FaClock className="text-gray-400" />
                      {formatDate(order.createdAt)}
                    </span>
                    <div className="border-l border-gray-200 ml-4 pl-4 sm:ml-6 sm:pl-6">
                      <a
                        href={`/order/${order.id}`}
                        className="text-indigo-600 hover:text-indigo-500"
                      >
                        View Invoice
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 -mb-6 flow-root border-t border-gray-200 divide-y divide-gray-200">
                {order.items.map((item) => (
                  <div key={item.id} className="py-6 sm:flex">
                    <div className="flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8 ">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="bg-gray-100 flex-none w-20 h-20 rounded-md object-center object-contain sm:w-48 sm:h-48"
                      />
                      <div className="pt-1.5 min-w-0 flex-1 sm:pt-0">
                        <h3 className="text-lg font-medium text-gray-900">
                          <a href={item.href}>
                            {item.name ??
                              item.product?.name ??
                              item.model?.name}{' '}
                            {item.quantity > 1 && (
                              <span className="text-gray-400">
                                &times; {item.quantity}
                              </span>
                            )}
                          </a>
                        </h3>
                        <p className="text-sm text-gray-500 truncate">
                          <span>
                            {formatMaterial(
                              item.product?.itemRestrictions?.material ??
                                item.model?.material
                            )}
                          </span>{' '}
                          <span
                            className="mx-1 text-gray-400"
                            aria-hidden="true"
                          >
                            &middot;
                          </span>
                        </p>
                        <p className="mt-1 font-medium text-gray-900">
                          ${item.priceAmount.toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <div className="mt-6 space-y-4 sm:mt-0 sm:ml-6 sm:flex-none sm:w-40">
                      <button
                        type="button"
                        className="w-full flex items-center justify-center bg-indigo-600 py-2 px-2.5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-full sm:flex-grow-0"
                        onClick={(e) => {
                          if (item.product) {
                            runCheckout(`/buy/product/${item.product.id}`);
                          } else {
                            runCheckout(`/buy/${item.id}`);
                          }
                        }}
                      >
                        Buy again
                      </button>
                      {item.model && (
                        <button
                          type="button"
                          className="w-full flex items-center justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-full sm:flex-grow-0"
                          onClick={(e) => {
                            window.location = `${config.appUrl}/share/${item.id}`;
                          }}
                        >
                          Customize
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        ))}
      </div>
    </div>
  );
}
