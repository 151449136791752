import Iubenda from '../components/Iubenda';

import './PrivacyPolicy.scss';

export default function PrivacyPolicy() {
  return (
    <section className="app-PrivacyPolicy">
      <Iubenda type="privacy-policy" />
    </section>
  );
}
