import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import Snowfall from 'react-snowfall';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
      {/* <Snowfall
        snowflakeCount={235}
        speed={[1, 2]}
        wind={[0, 0]}
        radius={[0.75, 2.15]}
      /> */}
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
