import React from 'react';

import './Heading.scss';

export default function Heading(props) {
  return (
    <div className="app-Heading">
      {props.topHeading ? (
        <h3 className="subHeading">{props.topHeading}</h3>
      ) : (
        ''
      )}

      {props.mainHeading ? (
        <h2 className="heading text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
          {props.mainHeading}
        </h2>
      ) : (
        ''
      )}

      {props.bottomHeading ? (
        <h3 className="subHeading">{props.bottomHeading}</h3>
      ) : (
        ''
      )}

      {props.ribbon ? (
        <p className="ribbon text-l md:text-xl lg:text-2xl text-center text-gray-600 mt-5">
          {props.ribbon}
        </p>
      ) : (
        ''
      )}
    </div>
  );
}
