const ANVL_ENV = process.env.REACT_APP_ANVL_ENV || 'development';

export const config = {
  env: ANVL_ENV,
  appUrl:
    ANVL_ENV === 'production'
      ? 'https://anvl.co'
      : window.location.host.startsWith('localhost')
      ? `${window.location.protocol}//${window.location.host}`
      : 'https://dev.anvl.co',
  apiUrl:
    ANVL_ENV === 'production'
      ? 'https://api.anvl.co'
      : ANVL_ENV === 'local'
      ? 'http://localhost:4000'
      : 'https://api-dev.anvl.co',
};

export default config;
