import React from 'react';
import cx from 'classnames';

import { TagIcon } from '@heroicons/react/outline';

import config from '../config';
import Heading from '../components/Heading';

import './Deals.scss';

export function DealItem({
  code = '',
  url = '',
  description = '',
  timeRemaining = '',
  timeRemainingCritical = false,
}) {
  return (
    <div className="app-DealItem">
      <div className="bg-white shadow overflow-hidden sm:rounded-md mx-7 my-7">
        <ul className="divide-y divide-gray-200">
          <li>
            <a
              href={
                url
                  ? url
                  : code
                  ? `${config.appUrl}/coupon/${code}`
                  : config.appUrl
              }
              className="block relative hover:bg-gray-50"
            >
              <div className="px-5 py-5 sm:px-7">
                <div className="deal flex items-center justify-between">
                  <div className="top-0 p-2 inline-block bg-indigo-600 rounded-lg shadow-lg mr-5">
                    <TagIcon
                      className="h-7 w-7 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <p
                    className="text-xl font-medium text-gray-800 truncate"
                    style={{ flex: 1 }}
                  >
                    {description}
                  </p>
                  {timeRemaining && (
                    <div className="ml-2 flex-shrink-0 flex">
                      <p
                        className={cx(
                          'px-4 inline-flex text-lg leading-8 font-semibold rounded-full ',
                          timeRemainingCritical
                            ? 'bg-red-100 text-red-800'
                            : 'bg-green-100 text-green-800'
                        )}
                      >
                        {timeRemaining}
                      </p>
                    </div>
                  )}
                  <div className="arrow ml-2 flex-shrink-0 flex">
                    <svg
                      className="h-7 w-7 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default function Deals() {
  return (
    <div className="app-Deals overflow-hidden py-16 pb-0">
      <div className="px-8">
        <Heading
          // topHeading="Turn your ideas into stunning 3D models with just a few easy clicks"
          mainHeading="Custom Miniature Discounts"
          bottomHeading="Enjoy huge savings on all ANVL custom tabletop minis"
        />
      </div>
      <div className="dealContainer bg-gray-100 py-12 mt-14">
        <DealItem
          code="ANVL10"
          description={
            <span>
              Save <b>10%</b> on your first custom miniature order
            </span>
          }
          timeRemaining="ANVL10"
          timeRemainingCritical={false}
        />

            {/* <DealItem
          code="DOWNLOAD25"
          description={
            <span>
              Save <b>25%</b> on all character downloads
            </span>
          }
          timeRemaining="DOWNLOAD25"
          // timeRemainingCritical={true}
        /> */}

        <DealItem
          code="PARTYPACK"
          description={
            <span>
              Get <b>3 for 2</b> on Premium Resin miniatures
            </span>
          }
          timeRemaining="PARTYPACK"
          // timeRemainingCritical={true}
        />
      </div>
    </div>
  );
}
