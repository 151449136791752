import config from '../config';
import { useAuth } from '../utils/auth';
import { formatDate } from '../utils/date';
// import { runCheckout } from '../utils/frame';
import { useServices } from '../utils/hooks';
import { formatMaterial } from '../utils/materials';

export default function Credits() {
  useAuth();
  const [credits] = useServices((s) => s.getCredits());

  if (!credits) {
    return null;
  }

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 py-0 sm:py-4 sm:px-6">
        <div className="pb-5 border-b border-gray-200">
          <h3 className="mt-10 text-2xl leading-6 font-medium text-gray-900">
            Credits{' '}
            <span className="rounded-full ml-1 items-center bg-indigo-600 text-white inline-flex p-0.5 px-3 text-lg">
              {credits.length}
            </span>
          </h3>
        </div>

        {credits.length === 0 ? (
          <div className="my-12 flex items-center flex-col justify-center bg-indigo-100 border border-indigo-200 rounded-md p-8">
            <h2 className="headingFont text-2xl">
              You do not currently have any credits to use
            </h2>
            <a
              className="mt-6 goldButtonApp p-4 px-7"
              href="https://anvl.co/store"
            >
              Visit ANVL Store
            </a>
          </div>
        ) : (
          <div className="flex flex-col my-10">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Credit
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Purchased
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>

                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Use</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {credits.map((credit) => (
                        <tr key={credit.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={
                                    credit.itemRestrictions?.material ===
                                    'resin'
                                      ? 'https://anvl-models-images-dev.s3.amazonaws.com/product/1-resin.png'
                                      : 'https://anvl-models-images-dev.s3.amazonaws.com/product/1-stl.png'
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="ml-4 flex">
                                <div className="text-sm font-medium text-gray-900">
                                  1 &times;&nbsp;
                                </div>
                                <div className="text-sm font-medium text-gray-500">
                                  {formatMaterial(
                                    credit.itemRestrictions?.material
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {formatDate(credit.createdAt)}
                            </div>
                            <div className="text-sm font-medium text-gray-500">
                              {credit.claimedAt
                                ? `Used: ${formatDate(credit.claimedAt)}`
                                : ''}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={
                                !credit.claimedAt
                                  ? 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800'
                                  : credit.claimedAt
                                  ? 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800'
                                  : ''
                              }
                            >
                              {credit.claimedAt ? 'Used' : 'Active'}
                            </span>
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <a
                              href={config.appUrl}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Use
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
