import config from '../config';

import './MissionStatement.scss';

export default function MissionStatement() {
  return (
    <div className="app-MissionStatement bg-white py-16">
      <div className="relative py-24 px-8 bg-gray-700 shadow-2xl overflow-hidden lg:px-16 lg:grid lg:grid-cols-1 lg:gap-x-8">
        <div className="absolute inset-0">
          <img
            src={require('../images/features-banner-bg.jpg').default}
            alt="Custom Minis"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="relative lg:col-span-1">
          <div className="max-w-6xl mx-auto">
            <img
              className="h-7 w-auto"
              src={require('../images/logo-white.png').default}
              alt="ANVL"
            />
            <blockquote className="mt-6 text-white">
              <p className="text-xl font-medium sm:text-2xl">
                Bring your character to life and design your perfect custom
                miniature figurine with trillions of unique combinations of
                weapons, items and outfits to choose from!
              </p>
              <footer className="mt-6">
                <p className="font-medium">
                  <a className="tryLink" href={config.appUrl}>
                    Try out the app
                    <span>&#8594;</span>
                  </a>
                </p>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  );
}
