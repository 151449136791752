import React, { useState } from 'react';
import './NewsletterSlim.scss';

export default function NewsletterSlim() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    setIsSubmitted(true); // Update state to show "Success!" after submission
  };

  return (
    <section className="app-NewsletterSlim box-border relative block py-16 overflow-hidden leading-6 text-center text-white bg-indigo-900 bg-no-repeat bg-cover">
      <div className="w-full mx-auto leading-6 text-center px-16">
        <div className="box-border flex flex-wrap justify-center -mx-4 text-indigo-900">
          <div className="relative w-full px-4 leading-6 text-center xl:flex-grow-0 xl:flex-shrink-0 lg:flex-grow-0 lg:flex-shrink-0">
            <form
              onSubmit={handleSubmit} // Only used to change button text
              method="POST"
              action="https://customerioforms.com/forms/submit_action?site_id=6d540b6f9fd37b2a9c89&form_id=01jckfv3e9xn1r4a7h2zhp4je5&success_url=https://anvl.co/store"
              className="box-border text-center text-indigo-900"
            >
              <div className="mx-auto max-w-7xl flex flex-col flex-nowrap justify-center md:space-x-4 space-y-5 leading-6 text-indigo-900 lg:flex-row lg:space-y-0">
                <h2 className="w-full flex-shrink-0 lg:w-auto box-border mx-0 mt-0 font-sans font-bold text-black sm:text-3xl">
                  Want exclusive discounts &amp; updates?
                </h2>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className="input inline-block h-16 px-5 py-0 mt-0 overflow-visible text-xl align-top md:w-auto focus:outline-none cursor-text md:text-left"
                  autoComplete="off"
                  required
                />

                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="input inline-block h-16 px-5 py-0 mt-0 overflow-visible text-xl align-top md:w-auto focus:outline-none cursor-text md:text-left"
                  autoComplete="off"
                  required
                />
                <button
                  type="submit"
                  disabled={isSubmitted} // Disable button if submitted
                  className={`subscribeButton inline-block w-full h-16 px-6 py-0 m-0 overflow-visible text-xl font-semibold text-center text-white normal-case align-middle ${
                    isSubmitted ? 'bg-green-600' : 'bg-green-400'
                  } border border-transparent border-solid rounded-full cursor-pointer select-none md:w-auto focus:outline-none focus:shadow-xs`}
                >
                  {isSubmitted ? 'Success!' : 'Subscribe'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
