import { Link } from 'react-router-dom';

export default function HybridLink({ href, ...props }) {
  if (href?.startsWith('/')) {
    return (
      <Link to={href} {...props}>
        {props.children}
      </Link>
    );
  } else {
    return (
      <a href={href} {...props}>
        {props.children}
      </a>
    );
  }
}
