import config from '../config';

import './Blueprint.scss';

export default function Blueprint() {
  return (
    <section className="app-Blueprint">
      <section className="py-28">
        <div className="container items-center max-w-7xl px-10 mx-auto sm:px-20 md:px-32 lg:px-16">
          <div className="flex flex-wrap items-center -mx-3">
            <div className="leftBlock order-1 w-full px-3 lg:w-1/2 lg:order-0">
              <div className="w-full">
                <p className="subHeading mb-4 font-medium tracking-tight text-gray-400 xl:mb-6">
                  Cutting-edge 3D printing technology
                </p>
                <h2 className="heading text-4xl font-bold leading-tight tracking-tight sm:text-5xl font-heading">
                  Bringing your miniatures to life
                </h2>

                {/* <Heading
                  topHeading="Cutting-edge 3D printing technology"
                  mainHeading="Bringing your miniatures to life"
                /> */}

                <ul className="steps flex">
                  <li className="sm:flex items-center mr-7 py-2 sm:space-x-4 xl:py-3">
                    <i className="w-full text-center sm:w-auto fas fa-magic text-xl sm:text-2xl md:text-3xl" />
                    <span className="block font-medium text-gray-500 text-md xs:text-xl sm:text-lg lg:text-xl xl:text-2xl">
                      Design
                    </span>
                  </li>
                  <li className="sm:flex items-center mr-7 py-2 sm:space-x-4 xl:py-3">
                    <i className="w-full text-center sm:w-auto fas fa-cubes text-xl sm:text-2xl md:text-3xl" />

                    <span className="block font-medium text-gray-500 text-md xs:text-xl lg:text-xl sm:text-lg xl:text-2xl">
                      3D Print
                    </span>
                  </li>
                  <li className="sm:flex items-center py-2 sm:space-x-4 xl:py-3">
                    <i className="w-full text-center sm:w-auto fas fa-dice-d20 text-xl sm:text-2xl md:text-3xl" />
                    <span className="block font-medium text-gray-500 text-md xs:text-xl lg:text-xl sm:text-lg xl:text-2xl">
                      Enjoy
                    </span>
                  </li>
                </ul>

                <p className="paragraph">
                  Immortalize your unique character in almost any high-quality
                  material! We produce your custom figurines using our state of
                  the art additive manufacturing equipment. You can also
                  download the STL for your model and 3D print at home.
                </p>

                <a
                  href={config.appUrl}
                  className="goldButton mt-9 inline-flex items-center"
                  // maybe we want the opener / referrer stuff for tracking or fancy features?
                  // target="_blank"
                  // rel="noopener noreferrer"
                >
                  <span className="">Design Your Custom Miniature</span>
                  <svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-4 ml-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div className="rightBlock w-full px-3 mb-12 lg:w-1/2 order-0 lg:order-1 lg:mb-0">
              <img
                className="mx-auto sm:max-w-sm lg:max-w-full"
                src={require('../images/placeholder.png').default}
                alt="ANVL Custom Mini"
              />
            </div>
          </div>
        </div>
      </section>

      <div className="gradient" />
    </section>
  );
}
