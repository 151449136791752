import axios from 'axios';

export async function sendContactForm({ name, email, message, ...rest }) {
  const restString = Object.entries(rest)
    .map(([k, v]) => `${k}: ${v}`)
    .join('\n');

  const res = await axios.post(
    'https://formspree.io/f/xrbgejol', // Formspree endpoint
    {
      name,
      email,
      message: message + '\n\n' + restString,
    },
    {
      headers: {
        'Content-Type': 'application/json', // JSON format
      },
    }
  );

  const data = res.data;

  if (data?.ok) { // Formspree success response uses `ok` property
    return data;
  } else {
    const error = data?.error ?? data?.message ?? 'Unknown';
    throw new Error('Failed to send contact form: ' + error);
  }
}
