import config from '../config';
import HybridLink from './HybridLink';
import './Features.scss';

function FeatureLeft(props) {
  return (
    <div className="featureBlock py-20 md:py-20 lg:py-36 border-b border-gray-150">
      <div className="box-border flex flex-col items-center content-center px-8 mx-auto leading-6 text-black md:flex-row max-w-7xl lg:px-16">
        <div className="box-border relative w-full max-w-md text-center md:ml-0 md:mt-0 md:max-w-none md:w-1/2">
          <video
            src={process.env.PUBLIC_URL + '/anvl-custom-minis-demo.mp4'}
            autoPlay
            loop
            controls={false}
            disableRemotePlayback={true}
            muted
            playsInline
          ></video>
        </div>

        <div className="featureContent box-border order-first w-full md:w-1/2 md:pl-10 md:order-none">
          <h2 className="featureHeading m-0 border-0 border-gray-300 text-3xl lg:text-4xl xl:text-5xl">
            Get started in seconds
          </h2>
          <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-4 xl:pr-8 lg:text-lg">
            Bring your characters to life in spectacular fashion with ANVL
            Custom Miniatures. Enjoy full customization and control over your
            character’s race, items, clothing, and much more!
            <br />
            <br />
            Dive straight in with our beautifully simple web and mobile
            application and start creating your perfect custom miniature
            figurines in seconds.
          </p>
          <ul className="p-0 m-0 leading-6 border-0 border-gray-300 lg:grid lg:grid-cols-2">
            <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
              <div className="float-left">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>{' '}
              </div>
              <div>Design any character</div>
            </li>
            <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
              <div className="float-left">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>{' '}
              </div>
              <div>Simple &amp; easy to use</div>
            </li>
            <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
              <div className="float-left">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>{' '}
              </div>
              <div>Works on any device</div>
            </li>
            <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
              <div className="float-left">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>{' '}
              </div>
              <div>No sign up required</div>
            </li>
          </ul>

          <a
            className="transition featureButton inline-flex mt-8 mb-3  justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            href={config.appUrl}
          >
            Create Your Custom Miniature
          </a>
        </div>
      </div>
    </div>
  );
}

function FeatureRight(props) {
  return (
    <div className="featureBlock pb-0 pt-20 md:py-20 lg:py-36">
      <div className="box-border flex flex-col items-center content-center px-8 mx-auto mt-2 leading-6 text-black border-0 border-gray-300 border-solid xl:mt-0 md:flex-row max-w-7xl lg:px-16">
        <div className="featureContent box-border w-full md:w-1/2 md:pl-6 xl:pl-10">
          <h2 className="featureHeading m-0 border-0 border-gray-300 text-3xl lg:text-4xl xl:text-5xl">
            Create custom miniatures
          </h2>
          <p className="pt-4 pb-8 m-0 leading-7 text-gray-700 border-0 border-gray-300 sm:pr-10 lg:text-lg">
            We’ve made everything completely customizable! From body shapes and
            sizes, to weapons, accessories, bases and fully editable stances.
            <br />
            <br />
            Easily access trillions of design combinations and create your truly
            unique custom figurine ready for your next tabletop adventure!
          </p>
          <ul className="p-0 m-0 leading-6 border-0 border-gray-300">
            <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
              <div className="float-left">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>{' '}
              </div>
              <div>Choose from over 10 starting races</div>
            </li>
            <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
              <div className="float-left">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>{' '}
              </div>
              <div>Explore trillions of item combinations</div>
            </li>
            <li className="box-border relative py-1 pl-0 text-left text-gray-500 border-solid">
              <div className="float-left">
                <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-white bg-yellow-300 rounded-full">
                  <span className="text-sm font-bold">✓</span>
                </span>{' '}
              </div>
              <div>Custom designed miniatures for every scale</div>
            </li>
          </ul>

          <HybridLink
            className="transition featureButton inline-block mt-8 mb-3  no-underline align-middle border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            href="/features"
          >
            Explore More Features
          </HybridLink>
        </div>

        <div className="hidden md:block box-border relative w-full max-w-md px-4 mt-10 mb-4 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
          <div className="featureImage"></div>
          <div className="animProps hidden lg:block"></div>
          <div className="animProps2 hidden lg:block"></div>
        </div>

        <div className="block md:hidden box-border relative w-full max-w-md px-4 mt-10 mb-0 text-center bg-no-repeat bg-contain border-solid md:mt-0 md:max-w-none lg:mb-0 md:w-1/2">
          <img
            src={require('../images/handprops.png').default}
            className="pl-4 sm:pr-10 xl:pl-10 lg:pr-32"
            alt="ANVL on phone in hand"
          />
        </div>
      </div>
    </div>
  );
}

export default function Features() {
  return (
    <section className="app-Features w-full bg-white">
      <FeatureLeft />
      <FeatureRight />
    </section>
  );
}
