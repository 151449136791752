import { useCallback } from 'react';
import cx from 'classnames';
import { FaShoppingCart } from 'react-icons/fa';
import { IoIosPeople } from 'react-icons/io';

import { runCheckout } from '../utils/frame';
import Heading from './Heading';
import './StoreGrid.scss';

function PartnerCTA() {
  return (
    <div className="bg-indigo-600">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center">
            <span className="flex p-2 rounded-lg bg-indigo-800">
              <IoIosPeople className="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <p className="ml-4 font-medium text-lg text-white truncate">
              <span className="inline">
                Looking to partner with us or place a bulk order? Get in touch!
              </span>
            </p>
          </div>
          <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href="/partnership-requests"
              className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50"
            >
              Custom Order
            </a>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
}

function StoreProduct({
  id,
  imageUrl,
  title,
  description,
  rrp,
  price,
  recommended,
  saving,
}) {
  const handleClickBuyProduct = useCallback(
    (e) => {
      e.preventDefault();
      runCheckout(`/buy/product/${id}`);
    },
    [id]
  );

  return (
    <div className={cx('collectionItem', { recommended: recommended })}>
      <div className="preview">
        <img src={imageUrl} alt="" />
        {saving ? <span className="quantity">{saving}</span> : ''}
      </div>
      <div className="content">
        <h2>{title}</h2>

        <p className="description">{description}</p>
        <p className="price">
          <span className=" text-gray-400 mr-2 line-through">{rrp}</span>
          {price}
        </p>

        <div className="CTA">
          <a
            href="#buy-product"
            className="goldButton inline-flex justify-center w-full my-3 text-center"
            rel="noopener noreferrer"
            onClick={handleClickBuyProduct}
          >
            <FaShoppingCart className="mr-2" />
            <span>Add To Cart</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default function StoreResin() {
  return (
    <section className="app-StoreGrid">
      <div className="overflow-hidden py-16 pb-16 md:pt-0 md:pb-24 px-7" id="">
        <span className="resinBg" />

        <Heading
          // topHeading="Turn your ideas into stunning 3D models with just a few easy clicks"
          mainHeading="Ultra-High Detail Premium Resin"
          bottomHeading="Design your own custom miniature and we'll print it to perfection"
        />

        <p className="max-w-5xl mx-auto text-left text-lg md:text-center text-gray-600 mt-8">
          Buy one of our super affordable bundle packs, get instant access to
          our powerful character creator and start designing your custom
          miniatures right away! Once you're happy with your designs, we'll
          print them for you in stunning 4K detail. Not ready to start designing
          yet? Buy now and claim this great price and your credits will be
          available to use when you're ready!
        </p>

        <div className="gridContainer mt-14 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8 w-full max-w-7xl mx-auto">
          <StoreProduct
            id="9d8963b9-41a1-4080-9c93-af0c376c4a90"
            imageUrl="https://anvl-models-images-dev.s3.amazonaws.com/product/1-resin.png"
            // saving="1 Mini"
            title="1 Custom Miniature"
            description="Premium Resin"
            price="$24.99"
          />
          <StoreProduct
            id="fe3a086b-c14b-43d7-ab55-2fdab7a23e36"
            imageUrl="https://anvl-models-images-dev.s3.amazonaws.com/product/3-resin.png"
            saving="Save 15%"
            title="3 Custom Miniatures"
            description="Premium Resin"
            rrp="$74.97"
            price="$63.72"
            recommended
          />
          <StoreProduct
            id="f6a11bc5-1a4a-44d6-9af3-84739e8e51bf"
            imageUrl="https://anvl-models-images-dev.s3.amazonaws.com/product/5-resin.png"
            saving="Save 30%"
            title="5 Custom Miniatures"
            description="Premium Resin"
            rrp="$123.95"
            price="$86.76"
          />
        </div>
      </div>

      <PartnerCTA />
    </section>
  );
}
