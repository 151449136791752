import { useCallback } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';

import { runCheckout } from '../utils/frame';
import Heading from './Heading';
import './StoreGrid.scss';

function StoreProduct({
  id,
  imageUrl,
  title,
  description,
  price,
  rrp,
  recommended,
  saving,
}) {
  const handleClickBuyProduct = useCallback(
    (e) => {
      e.preventDefault();
      runCheckout(`/buy/product/${id}`);
    },
    [id]
  );

  return (
    <div className={cx('collectionItem', { recommended: recommended })}>
      <div className="preview">
        <img src={imageUrl} alt="" />
        {saving ? <span className="quantity">{saving}</span> : ''}
      </div>
      <div className="content">
        <h2>{title}</h2>

        <p className="description">{description}</p>
        <p className="price">
          <span className=" text-gray-400 mr-2 line-through">{rrp}</span>
          {price}
        </p>

        <div className="CTA">
          <a
            href="#buy-product"
            className="goldButton inline-flex justify-center w-full my-3 text-center"
            onClick={handleClickBuyProduct}
          >
            <FaShoppingCart className="mr-2" />
            <span>Add To Cart</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export function GoldCallout({ title, description, price }) {
  return (
    <div className={cx('collectionItem')}>
      <div className="preview goldCallout">
        <img src={require('../images/anvl-gold.png').default} alt="" />
      </div>
      <div className="content">
        <h2>{title}</h2>

        <p className="description">{description}</p>
        <p className="price">{price}</p>

        <div className="CTA">
          <Link
            className="featureButton w-full inline-flex justify-center my-3 text-center"
            to="/gold"
          >
            <span>Learn More</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default function StoreResin() {
  return (
    <section className="app-StoreGrid">
      <div className="overflow-hidden py-16 pb-16 md:pt-0 md:pb-24 px-7" id="">
        <span className="downloadBg mt-20 mb-10" />

        <Heading
          // topHeading="Turn your ideas into stunning 3D models with just a few easy clicks"
          mainHeading="Digital STL Download Credit Bundles"
          bottomHeading="Already own a 3D printer? You're going to love these deals!"
        />

        <p className="max-w-5xl mx-auto text-left text-lg md:text-center text-gray-600 mt-8">
          ANVL allows you to create your own custom minis and download them as
          STL files to be 3D printed at home. Want to print an entire army of
          orcs? Need to see your character in 50 different poses or outfit
          variations? We've got you covered!
        </p>

        <div className="gridContainer mt-14 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-8 w-full max-w-7xl mx-auto">
          <StoreProduct
            id="a4ba2f7e-7d4e-414c-bded-e6eba001b279"
            imageUrl="https://anvl-models-images-dev.s3.amazonaws.com/product/1-stl.png"
            // saving="1 STL"
            title="1 Download Credit"
            description="STL Format"
            price="$5.99"
          />
          <StoreProduct
            id="f6aeae4d-0db1-49c4-97d9-c74895be265e"
            imageUrl="https://anvl-models-images-dev.s3.amazonaws.com/product/3-stl.png"
            saving="Save 10%"
            title="3 Download Credits"
            description="STL Format"
            rrp="$17.97"
            price="$16.17"
          />
          <StoreProduct
            id="3c90cd68-5aef-40b6-9ba0-d612842f726c"
            imageUrl="https://anvl-models-images-dev.s3.amazonaws.com/product/5-stl.png"
            saving="Save 15%"
            title="5 Download Credits"
            description="STL Format"
            rrp="$29.95"
            price="$25.45"
            recommended
          />
          <StoreProduct
            id="7de456a9-1fe6-436e-aa1c-0b79d1a441d3"
            imageUrl="https://anvl-models-images-dev.s3.amazonaws.com/product/10-stl.png"
            saving="Save 20%"
            title="10 Download Credits"
            description="STL Format"
            rrp="$59.90"
            price="$47.92"
          />
          <StoreProduct
            id="86ede0e1-9347-4616-9b85-53b5b6016059"
            imageUrl="https://anvl-models-images-dev.s3.amazonaws.com/product/20-stl.png"
            saving="Save 30%"
            title="20 Download Credits"
            description="STL Format"
            rrp="$119.80"
            price="$83.86"
          />
          {/* <GoldCallout
            imageUrl="https://anvl-models-images-dev.s3.amazonaws.com/product/anvl-gold.png"
            saving="Subscribe & Save"
            title="ANVL Gold"
            description="Premium Membership"
            price="From $19.97"
          /> */}
        </div>
      </div>
    </section>
  );
}
