import React from 'react';

// pad with a space to avoid matching substrings of other classname
const pad = (string) => ` ${string} `;

export default class AttachClassToElement extends React.Component {
  componentDidMount() {
    this.attachClassName(this.props.className);
  }

  componentWillUnmount() {
    this.detachClassName(this.props.className);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.className !== this.props.className) {
      this.detachClassName(prevProps.className);
      this.attachClassName(this.props.className);
    }
  }

  attachClassName(className) {
    const element = this.getElement();

    // duplicate classnames will still be duplcated in the string
    element.className += pad(className);
  }

  detachClassName(className) {
    const element = this.getElement();

    // replace first instance of classname
    element.className = element.className.replace(pad(className), '');
  }

  getElement() {
    if (typeof this.props.getElement === 'function') {
      return this.props.getElement();
    }

    const element = document.getElementById('root');

    if (!element) {
      throw new Error('AttachClassToElement: No element found');
    }

    return element;
  }

  render() {
    return null;
  }
}
