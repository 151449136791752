import React, { useState } from 'react';
import './Newsletter.scss';

export default function Newsletter() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    setIsSubmitted(true); // Update state to show "Success!" after submission
  };

  return (
    <section className="app-Newsletter box-border relative block py-24 overflow-hidden leading-6 text-center text-white bg-indigo-900 bg-no-repeat bg-cover">
      <div className="px-8 mx-auto max-w-7xl xl:px-5">
        <div className="flex flex-col justify-center space-y-8 md:space-y-11">
          <h2 className="sm:max-w-5xl mx-auto text-2xl xs:text-3xl text-center leading-10 text-white sm:text-4xl md:text-5xl">
            Subscribe for exclusive discounts and updates
          </h2>
          <p className="max-w-4xl mx-auto text-xl text-center md:leading-9 leading-relaxed md:text-2xl">
            Stay up to date with the latest updates from ANVL Custom Miniatures.
            Plus, you'll be the first to know about our exclusive discounts.
          </p>

          <form
            onSubmit={handleSubmit} // Attach onSubmit handler here to update state
            method="POST"
            action="https://customerioforms.com/forms/submit_action?site_id=6d540b6f9fd37b2a9c89&form_id=01jckfv3e9xn1r4a7h2zhp4je5&success_url=https://anvl.co/store"
            className="flex flex-col w-full max-w-4xl mx-auto space-y-5 md:space-y-0 md:space-x-5 md:flex-row"
          >
            <input
              type="text"
              name="name"
              className="flex-1 w-full px-5 py-5 text-2xl rounded-lg"
              placeholder="Your Name"
              required
            />
            <input
              type="email"
              name="email"
              className="flex-1 w-full px-5 py-5 text-2xl rounded-lg"
              placeholder="Email Address"
              required
            />
            <button
              type="submit"
              disabled={isSubmitted} // Disable button if submitted
              className={`subscribeButton flex-shrink-0 px-10 py-5 text-2xl font-medium text-center text-white ${
                isSubmitted ? 'bg-green-600' : 'bg-green-400'
              }`}
            >
              {isSubmitted ? 'Success!' : 'Sign Me Up'}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
