import { useState, useEffect } from 'react';
// import cx from 'classnames';

// import config from '../config';
import { useAuth } from '../utils/auth';
import * as services from '../utils/services';
import { runCheckout } from '../utils/frame';
import BasketIcon from '../components/BasketIcon';

import './UserBasket.scss';

window.__services = services;

const GET_BASKET_DEFAULTS = {};

export default function UserBasket() {
  const [auth] = useAuth();
  const basketId = auth?.basket?.basketData?.id;
  const [basket, setBasket] = useState(null);
  // const [error, setError] = useState(null);

  useEffect(() => {
    window.__auth = auth;
  }, [auth]);

  useEffect(() => {
    if (!auth || !basketId) {
      return;
    }

    services
      .getBasket(basketId, GET_BASKET_DEFAULTS)
      .then((basketData) => setBasket(basketData))
      .catch((err) => {
        console.error('failed to load basket>', err.message);
        // setError(err.message);
      });
  }, [auth, basketId]);

  // if (!basket && error) {
  //   return <div className="app-UserBasket">Error: {error}</div>;
  // }

  // if (!auth || !basket) {
  //   return null;
  // }

  // if (basket.orderId || basket.items?.length === 0) {
  //   return null;
  // }

  return (
    <BasketIcon
      quantity={basket?.items?.length}
      onClick={() => runCheckout()}
    />
  );
}
