import { useEffect, useState } from 'react';
import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import './Header.scss';

import config from '../config';

// import SocialIcons from '../components/SocialIcons';
import MenuIcons from '../components/MenuIcons';
import ClickOffEvent from '../components/ClickOffEvent';

export default function Header() {
  const location = useLocation();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    setMobileNavOpen(false);
  }, [location]);

  return (
    <ClickOffEvent
      handler={() => setMobileNavOpen(false)}
      disabled={!mobileNavOpen}
      preventDefault
    >
      <nav
        className="app-Header box-border w-full h-20 px-8 my-0 leading-6 text-gray-800"
        id=""
      >
        <div className="mx-auto max-w-7xl flex items-center justify-between">
          <h1 className="m-0">
            <Link to="/custom-miniatures">ANVL</Link>
          </h1>

          <ul
            className={cx(
              'mobileNav navLinks ml-16 flex-1 fixed top-0 left-0 z-10 flex-wrap items-center p-0 mt-20 space-y-5 leading-6 text-gray-800 lg:space-x-9 lg:static lg:w-auto lg:flex lg:space-y-0 lg:mt-0',
              {
                '-mobileNav-open': mobileNavOpen,
              }
            )}
          >
            <li className="box-border w-full text-center lg:w-auto">
              <Link
                to="/custom-miniatures"
                className={cx(
                  {
                    active:
                      location.pathname === '/custom-miniatures' ||
                      location.pathname === '/',
                  },
                  'opacity-90 hover:opacity-100 hover:text-white hover:no-underline focus-within:opacity-100 focus-within:no-underline'
                )}
              >
                Welcome
              </Link>
            </li>
            <li className="box-border w-full text-center lg:w-auto">
              <Link
                to="/features"
                className={cx(
                  { active: location.pathname === '/features' },
                  'opacity-90 hover:opacity-100 hover:text-white hover:no-underline focus-within:opacity-100 focus-within:no-underline'
                )}
              >
                Features
              </Link>{' '}
            </li>

            <li className="box-border w-full text-center lg:w-auto">
              <Link
                to="/store"
                className={cx(
                  { active: location.pathname === '/store' },
                  'opacity-90 hover:opacity-100 hover:text-white hover:no-underline focus-within:opacity-100 focus-within:no-underline'
                )}
              >
                Store
              </Link>{' '}
            </li>

            <li className="box-border w-full text-center lg:w-auto">
              <Link
                to="/collection"
                className={cx(
                  { active: location.pathname === '/collection' },
                  'opacity-90 hover:opacity-100 hover:text-white hover:no-underline focus-within:opacity-100 focus-within:no-underline'
                )}
              >
                Collection
              </Link>{' '}
            </li>
            {/* <li className="box-border w-full text-center lg:w-auto">
              <Link
                to="/gold"
                className={cx(
                  { active: location.pathname === '/gold' },
                  'opacity-90 hover:opacity-100 hover:text-white hover:no-underline focus-within:opacity-100 focus-within:no-underline'
                )}
              >
                Gold
              </Link>{' '}
            </li>{' '} */}

            <li className="box-border w-full text-center lg:w-auto">
              <a className="headerCTA" href={config.appUrl}>
                Create Your Character
              </a>
            </li>
          </ul>

          {/* <SocialIcons /> */}

          <MenuIcons user basket />
          <div className="relative list-none lg:block hidden">
            <a className="headerCTA" href={config.appUrl}>
              Create Your Character
            </a>
          </div>
          <div
            className="absolute right-0 flex flex-col items-center items-end justify-center w-10 h-10 mr-5 rounded-full cursor-pointer lg:hidden hover:bg-gray-100"
            onClick={(e) => setMobileNavOpen((open) => !open)}
          >
            <svg
              className="w-6 h-6 text-gray-700"
              x-show="!showMenu"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
            {/* 
          <svg
            className="w-6 h-6 text-gray-700"
            x-show="showMenu"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg> */}
          </div>
        </div>
      </nav>
    </ClickOffEvent>

    // <header>
    //   <article className="container mx-auto px-1 flex flex-wrap justify-between">
    //     <h1 className="m-0">
    //       <a href="/custom-miniatures">ANVL</a>
    //     </h1>
    //     <nav className="flex-grow mt-6 ml-14">
    //       <ul className="flex">
    //         <li className="mr-7">
    //           <a href="/custom-miniatures">Welcome</a>
    //         </li>
    //         <li className="mr-7">
    //           <a href="/features">Features</a>
    //         </li>
    //         <li className="mr-7">
    //           <a href="/accessories">Accessories</a>
    //         </li>
    //         <li>
    //           <a href="https://help.anvl.co" target="_blank">
    //             Help
    //           </a>
    //         </li>
    //       </ul>
    //     </nav>

    //     <nav>
    //       <ul className="flex justify-between flex-wrap">
    //         {/* <li>
    //           <a href="https://www.instagram.com/anvlminis/" target="_blank">
    //             Instagram{' '}
    //           </a>
    //         </li>
    //         <li>
    //           <a href="https://twitter.com/anvlminis" target="_blank">
    //             Twitter{' '}
    //           </a>
    //         </li>
    //         <li>
    //           <a href="https://www.facebook.com/anvlminis" target="_blank">
    //             Facebook{' '}
    //           </a>
    //         </li> */}
    //         <li className="mt-6">
    //           <a href={config.appUrl}>
    //             {' '}
    //             <strong>Create Your Character</strong>{' '}
    //           </a>
    //         </li>
    //         <li></li>
    //       </ul>
    //     </nav>
    //   </article>
    // </header>
  );
}
