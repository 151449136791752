import { useState, useCallback } from 'react';
import { Switch } from '@headlessui/react';

import { sendContactForm } from '../utils/contactForm';

import './SuggestForm.scss';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SuggestForm({ title, description }) {
  const [agreed, setAgreed] = useState(false);

  const [pending, setPending] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmitForm = useCallback((e) => {
    e.preventDefault();

    const name = e.target.first_name.value + ' ' + e.target.last_name.value;
    const email = e.target.email.value;
    const requestType = e.target.requestType.value;
    const message = e.target.message.value;

    setPending(true);
    setSuccess(false);
    setError(null);

    sendContactForm({ name, email, requestType, message })
      .then((res) => {
        console.log('sendContactForm res>', res);
        setSuccess(true);

        e.target.first_name.value = '';
        e.target.last_name.value = '';
        e.target.email.value = '';
        e.target.requestType.value = '';
        e.target.message.value = '';
      })
      .catch((err) => {
        console.error('sendContactForm failed>', err);
        setError('Failed to send message');
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  return (
    <div className="app-SuggestForm bg-white py-7 px-4 overflow-hidden sm:px-6 lg:px-8 lg:pb-24">
      <span className="suggestBg" />

      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full transform translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute right-full bottom-0 transform -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <div className="text-center">
          <h2 className="heading text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">{description}</p>
        </div>
        <div className="mt-12">
          <form
            action="#"
            method="POST"
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            onSubmit={handleSubmitForm}
          >
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700"
              >
                First name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  autoComplete="given-name"
                  className="transition py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  disabled={pending}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700"
              >
                Last name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  autoComplete="family-name"
                  className="transition py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  disabled={pending}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="transition py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  disabled={pending}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="requestType"
                className="block text-sm font-medium text-gray-700"
              >
                Request Type
              </label>
              <div className="mt-1">
                <select
                  id="requestType"
                  name="requestType"
                  type="text"
                  className="transition py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  disabled={pending}
                >
                  <option>Content Request</option>
                  <option>Feature Suggestion</option>
                  <option>Bug Report</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Additional Information
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="transition py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  defaultValue={''}
                  disabled={pending}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <Switch
                    checked={agreed}
                    onChange={setAgreed}
                    className={classNames(
                      agreed ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                    disabled={pending}
                  >
                    <span className="sr-only">Agree to policies</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        agreed ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </div>
                <div className="ml-3">
                  <p className="text-base text-gray-500">
                    By selecting this, you agree to the{' '}
                    <a
                      href="/privacy"
                      className="font-medium text-gray-700 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      href="/privacy"
                      className="font-medium text-gray-700 underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cookie Policy
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>

            {error && (
              <div className="sm:col-span-2">
                <span className="text-red-500">{error}</span>
              </div>
            )}

            {success && (
              <div className="sm:col-span-2">
                <span className="text-green-500">Message sent!</span>
              </div>
            )}

            <div className="sm:col-span-2">
              <button
                type="submit"
                className="transition w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={pending || !agreed}
                style={
                  pending || !agreed
                    ? {
                        opacity: 0.5,
                        pointerEvents: 'none',
                      }
                    : undefined
                }
              >
                Submit Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
