import { Fragment, useState, useRef, useCallback, useMemo } from 'react';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import {
  FaInfoCircle,
  FaCog,
  FaTrashAlt,
  FaSearch,
  FaSort,
  FaPencilAlt,
  FaCopy,
  FaClock,
  FaCalendar,
  FaSortAlphaDown,
} from 'react-icons/fa';
import { RadioGroup, Popover, Transition, Dialog } from '@headlessui/react';
import { DuplicateIcon, ExclamationIcon } from '@heroicons/react/outline';

import { useAuth } from '../utils/auth';
import { formatDate } from '../utils/date';
import { useServices } from '../utils/hooks';
import * as services from '../utils/services';

import './SavedCharacters.scss';

const sortOptions = [
  {
    id: 'modified',
    name: (
      <span className="flex items-center">
        <FaClock className="mr-2 text-gray-500" /> Modified
      </span>
    ),
  },
  {
    id: 'created',
    name: (
      <span className="flex items-center">
        <FaCalendar className="mr-2 text-gray-500" /> Created
      </span>
    ),
  },
  {
    id: 'alphabetical',
    name: (
      <span className="flex items-center">
        <FaSortAlphaDown className="mr-2 text-gray-500" /> Alphabetical
      </span>
    ),
  },
];

function SavedCharacterItem({
  character,
  onOpenRename,
  onOpenDuplicate,
  onOpenDelete,
}) {
  const characterOptions = [
    {
      name: (
        <span
          className="flex items-center cursor-pointer"
          onClick={() => onOpenRename?.()}
        >
          <FaPencilAlt className="mr-2 text-gray-500" /> Rename
        </span>
      ),
    },
    {
      name: (
        <span
          className="flex items-center cursor-pointer"
          onClick={() => onOpenDuplicate?.()}
        >
          <FaCopy className="mr-2 text-gray-500" /> Duplicate
        </span>
      ),
    },
  ];

  return (
    <a
      key={character.id}
      href={`/share/${character.id}`}
      className="group border rounded-md"
    >
      <div className="w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-w-2 sm:aspect-h-3">
        <img
          src={character.image}
          alt="Character Preview"
          className="w-full h-full object-bottom object-contain group-hover:opacity-75"
        />
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
        className="mt-12 flex items-center justify-between text-base font-medium text-gray-900 bg-gray-100 p-5 rounded-md rounded-t-none"
      >
        <h3>{character.name}</h3>
        <div className="flex justify-between items-center gap-x-3">
          <FaInfoCircle
            data-tip={`Updated: ${formatDate(character.updatedAt)}`}
            className=" text-gray-500 hover:text-gray-400"
          />

          <Popover className="relative flex hidden">
            {({ open }) => (
              <>
                <Popover.Button
                  className={cx(
                    open ? 'text-gray-900' : 'text-gray-500',
                    'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  )}
                >
                  <FaCog className=" text-gray-500 hover:text-gray-400" />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                        {characterOptions.map((item, idx) => (
                          <a
                            key={idx}
                            href={item.href}
                            className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                          >
                            <p className="text-base font-medium text-gray-900">
                              {item.name}
                            </p>
                            <p className="mt-1 text-sm text-gray-500">
                              {item.description}
                            </p>
                          </a>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          <FaTrashAlt
            onClick={(e) => onOpenDelete?.()}
            className="text-gray-500 hover:text-red-400"
          />
        </div>
      </div>
    </a>
  );
}

export default function SavedCharacters() {
  useAuth();
  const [characters, , , fetchCharacters] = useServices((s) =>
    s.getSavedCharacters()
  );
  const [folder, setFolder] = useState(0);

  const [sortMode, setSortMode] = useState('modified');
  const [searchQuery, setSearchQuery] = useState('');
  const [deletingCharacter, setDeletingCharacter] = useState(null);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const handleDeleteCharacter = useCallback(() => {
    const character = deletingCharacter;

    if (!character) {
      return;
    }

    setDeletingCharacter(null);

    services.deleteCharacter(character.id).then(() => {
      console.log('done');
      fetchCharacters();
    });
  }, [deletingCharacter, fetchCharacters]);

  const sortedCharacters = useMemo(
    () =>
      characters &&
      [...characters]
        .filter(
          (c) =>
            !searchQuery ||
            c.name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          switch (sortMode) {
            case 'modified':
              return moment(a.updatedAt).isBefore(b.updatedAt);

            case 'created':
              return moment(b.createdAt).isBefore(a.createdAt);

            case 'alphabetical':
              return a.name.localeCompare(b.name);

            default:
              return a;
          }
        }),
    [sortMode, searchQuery, characters]
  );

  if (!characters || !sortedCharacters) {
    return null;
  }

  const characterFolders = [
    {
      id: 1,
      name: (
        <span className="flex items-center">
          Saved
          <strong className="bg-indigo-500 text-sm font-bold ml-2 px-2 py-1 text-white rounded-full">
            {characters.length}
          </strong>
        </span>
      ),
      disabled: false,
    },
    {
      id: 2,
      name: (
        <span className="flex items-center">
          Purchased
          <strong className="bg-indigo-500 text-sm font-bold ml-2 px-2 py-1 text-white rounded-full">
            12
          </strong>
        </span>
      ),
      disabled: false,
    },
  ];

  return (
    <div className="app-SavedCharacters bg-white">
      <div className="max-w-2xl mx-auto py-10 px-4 sm:py-12 sm:pb-20 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="flex items-center w-full justify-between flex-wrap gap-4">
          <RadioGroup value={folder} onChange={setFolder} className="">
            <div className="flex gap-4">
              {characterFolders.slice(0, 1).map((folder, idx) => (
                <RadioGroup.Option
                  key={folder.id}
                  value={idx}
                  className={({ active, checked }) =>
                    cx(
                      !folder.disabled
                        ? 'cursor-pointer focus:outline-none px-6'
                        : 'opacity-25 cursor-not-allowed',
                      active ? ' ring-2 ring-offset-2 ring-indigo-500' : '',
                      checked
                        ? 'radioChecked bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
                        : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                      'border rounded-md py-3 px-3 flex items-center justify-center text-lg font-medium uppercase sm:flex-1'
                    )
                  }
                  disabled={folder.disabled}
                >
                  <RadioGroup.Label as="p">{folder.name}</RadioGroup.Label>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>

          <div className="flex items-center gap-5">
            <Popover className="relative flex">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={cx(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium w-28 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    <FaSort className="mr-2 text-gray-400 hover:text-gray-700" />

                    <label className="capitalize">{sortMode}</label>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-8 px-2 w-screen max-w-xs sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {sortOptions.map((item) => (
                            <a
                              href="#sort"
                              key={item.id}
                              className="-m-3 p-3 block rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                              onClick={(e) => {
                                e.preventDefault();
                                setSortMode(item.id);
                              }}
                            >
                              <p className="text-base capitalize font-medium text-gray-900">
                                {item.name}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {item.description}
                              </p>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm">
                  <FaSearch />
                </span>
              </div>
              <input
                type="text"
                name="search"
                id="search"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-8 pr-8 sm:text-md border-gray-300 rounded-md"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
        </div>

        <hr className="my-10" />

        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-4 xl:gap-x-8 ">
          {sortedCharacters.map((character) => (
            <SavedCharacterItem
              key={character.id}
              character={character}
              onOpenRename={() => setRenameModalOpen(true)}
              onOpenDuplicate={() => setDuplicateModalOpen(true)}
              onOpenDelete={() => setDeletingCharacter(character)}
            />
          ))}
        </div>
      </div>
      <Transition.Root show={!!deletingCharacter} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => setDeletingCharacter(null)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg headingFont leading-6 font-medium text-gray-900"
                    >
                      Confirm Character Removal
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete{' '}
                        <strong>{deletingCharacter?.name}</strong>? This action
                        cannot be undone. Once it's gone, it's gone!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleDeleteCharacter}
                  >
                    Delete Character
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setDeletingCharacter(null)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={duplicateModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setDuplicateModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <DuplicateIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Duplicate Character
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to duplicate this character? You
                        can enter a name for your new character below.
                      </p>

                      <input
                        type="text"
                        defaultValue="Untitled Character (Copy)"
                        className="mt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 pr-10 sm:text-md border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                    onClick={() => setDuplicateModalOpen(false)}
                  >
                    Duplicate
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setDuplicateModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={renameModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setRenameModalOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <DuplicateIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Rename Character
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to rename this character? You can
                        enter a new name for your character below.
                      </p>

                      <input
                        type="text"
                        defaultValue="Untitled Character"
                        className="mt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-2 pr-10 sm:text-md border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm"
                    onClick={() => setRenameModalOpen(false)}
                  >
                    Rename
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setRenameModalOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <ReactTooltip />
    </div>
  );
}
