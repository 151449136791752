import { useEffect } from 'react';

export default function Iubenda({ policyId = 95802696 }) {
  useEffect(() => {
    var s = document.createElement('script');
    let tag = document.getElementsByTagName('script')[0];

    s.src = 'https://cdn.iubenda.com/iubenda.js';

    tag.parentNode.insertBefore(s, tag);
  }, []);

  return (
    <a
      href={`https://www.iubenda.com/privacy-policy/${policyId}`}
      className="iubenda-nostyle iubenda-embed iub-legal-only iub-body-embed"
      title="Privacy Policy"
    >
      Privacy Policy
    </a>
  );
}
