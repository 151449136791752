import { FaDiceD20 } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import COLLECTION_ITEMS from './CollectionItems.js';

import './CollectionCategories.scss';
import { BsGrid } from 'react-icons/bs';

const breakpointColumnsObj = {
  default: 5,
  1024: 3,
  // 768: 2,
};

const modelIDs = [];

COLLECTION_ITEMS.map((item) => {
  return modelIDs.push(item.uuid);
});

const getRandomCharacter = () => {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  let idx = getRandomInt(0, COLLECTION_ITEMS.length);
  let randomCharacterURL =
    'https://anvl.co/share/' + COLLECTION_ITEMS[idx].uuid;

  openInNewTab(randomCharacterURL);

  return randomCharacterURL;
};

const categories = [
  {
    id: 1,
    name: 'Human',
    href: 'custom-human-miniatures',
    imageSrc: require('../images/collection/categories/human.jpg').default,
  },
  {
    id: 2,
    name: 'Dwarf',
    href: 'custom-dwarf-miniatures',
    imageSrc: require('../images/collection/categories/dwarf.jpg').default,
  },
  {
    id: 3,
    name: 'Orc',
    href: 'custom-orc-miniatures',
    imageSrc: require('../images/collection/categories/orc.jpg').default,
  },
  {
    id: 4,
    name: 'Cyclops',
    href: 'custom-cyclops-miniatures',
    imageSrc: require('../images/collection/categories/cyclops.jpg').default,
  },
  {
    id: 5,
    name: 'Goblin',
    href: 'custom-goblin-miniatures',
    imageSrc: require('../images/collection/categories/goblin.jpg').default,
  },
  {
    id: 6,
    name: 'Skeleton',
    href: 'custom-skeleton-miniatures',
    imageSrc: require('../images/collection/categories/skeleton.jpg').default,
  },
  {
    id: 7,
    name: 'Elf',
    href: 'custom-elf-miniatures',
    imageSrc: require('../images/collection/categories/elf.jpg').default,
  },
  {
    id: 8,
    name: 'Halfling',
    href: 'custom-halfling-miniatures',
    imageSrc: require('../images/collection/categories/halfling.jpg').default,
  },
  {
    id: 9,
    name: 'Tiefling',
    href: 'custom-tiefling-miniatures',
    imageSrc: require('../images/collection/categories/tiefling.jpg').default,
  },
  {
    id: 10,
    name: 'Dragon',
    href: 'custom-dragon-miniatures',
    imageSrc: require('../images/collection/categories/dragon.jpg').default,
  },
  {
    id: 11,
    name: 'Cat',
    href: 'custom-catfolk-miniatures',
    imageSrc: require('../images/collection/categories/cat.jpg').default,
  },
  {
    id: 12,
    name: 'Wolf',
    href: 'custom-wolf-miniatures',
    imageSrc: require('../images/collection/categories/wolf.jpg').default,
  },
  {
    id: 13,
    name: 'Frog',
    href: 'custom-frog-miniatures',
    imageSrc: require('../images/collection/categories/frog.jpg').default,
  },
  {
    id: 14,
    name: 'Eagle',
    href: 'custom-eagle-miniatures',
    imageSrc: require('../images/collection/categories/eagle.jpg').default,
  },
  {
    id: 15,
    name: 'Crystal',
    href: 'custom-crystal-miniatures',
    imageSrc: require('../images/collection/categories/crystal.jpg').default,
  },
  {
    id: 17,
    name: 'Minotaur',
    href: 'custom-minotaur-miniatures',
    imageSrc: require('../images/collection/categories/minotaur.jpg').default,
  },
  {
    id: 18,
    name: 'Treant',
    href: 'custom-treant-miniatures',
    imageSrc: require('../images/collection/categories/treant.jpg').default,
  },
];

export default function CollectionCategories() {
  return (
    <div className="bg-white">
      <div className="max-w-4xl mx-auto py-12 px-4 sm:py-14 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="flex items-center text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              <BsGrid className="mr-2.5 text-gray-400" /> Browse By Category
            </h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            <button
              type="button"
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => getRandomCharacter()}
            >
              <FaDiceD20 className="mr-2" />
              Random Character
            </button>
          </div>
        </div>

        <hr className="my-12" />

        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {categories.map((category) => (
            <Link
              to={'/collection/' + category.href}
              className="block"
              key={category.id}
            >
              <div className="relative h-full">
                <div className="relative h-full rounded-xl overflow-hidden">
                  <img
                    src={category.imageSrc}
                    alt={category.name}
                    className="w-full h-full object-center object-cover hover:scale-110 filter blur-lg transform transition ease-linear duration-1000"
                  />
                </div>

                <div className="absolute top-0 inset-x-0 h-full rounded-lg p-4 flex items-end justify-end overflow-hidden pointer-events-none">
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-28 bg-gradient-to-t from-black opacity-50"
                  />
                  <p className="relative text-2xl text-shadow-lg  font-semibold text-white">
                    {category.name}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </Masonry>
      </div>
      {/* <div className="max-w-2xl mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="flex flex-wrap gap-y-5 sm:grid-cols-2 sm:gap-x-6   xl:gap-x-5">
          {categories.map((category) => (
            <div key={category.id}>
              <div className="relative">
                <div className="relative h-28 rounded-lg overflow-hidden">
                  <img
                    src={category.imageSrc}
                    alt={category.name}
                    className="w-full h-full object-center object-cover"
                  />
                </div>

                <div className="absolute top-0 inset-x-0 h-28 rounded-lg p-4 flex items-end justify-end overflow-hidden">
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-28 bg-gradient-to-t from-black opacity-50"
                  />
                  <p className="relative text-2xl text-shadow-lg  font-semibold text-white">
                    {category.name}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}
