import { Link } from 'react-router-dom';

import './CollectionCTA.scss';

export default function CollectionCTA() {
  return (
    <section className="app-CollectionCTA py-20 md:py-28 md:bg-left-top">
      <div className="flex flex-col items-center justify-between px-10 mx-auto max-w-7xl xl:px-12">
        <div className="relative mb-8">
          <h2 className="w-full leading-10 mx-auto mb-8 text-4xl md:text-5xl">
            Explore The Collection
          </h2>
          <p className="w-full max-w-4xl mx-auto text-center text-xl md:text-2xl md:leading-9 leading-relaxed">
            Choose from our hand-picked selection of the finest custom tabletop
            miniatures designed with ANVL. Available to customize or purchase
            today.
          </p>
        </div>
        <Link
          to="/collection"
          className="collectionCTA relative flex-shrink-0 text-xl md:text-2xl"
        >
          Browse Collection <i className="fa fa-arrow-right" />
        </Link>
      </div>
    </section>

    // <div>
    //   <div>
    //     <div>
    //       <h3>
    //         The <strong>One Stop Shop</strong> For All Your Miniature Needs
    //       </h3>
    //       <p>
    //         Enjoy our range of tabletop gaming accessories. From affordable
    //         model paint and deluxe fine tip brush sets to realistic terrain and
    //         custom dice, we’ve got you covered.
    //       </p>
    //       <a href="/accessories">Shop Accessories </a>
    //     </div>
    //   </div>
    // </div>
  );
}
