import './FourPoints.scss';
import Heading from '../components/Heading';

export default function FourPoints() {
  return (
    <section className="app-FourPoints">
      <div className="overflow-hidden py-16 pb-16 md:py-24 md:pb-28 px-7" id="">
        <span className="anvil"></span>

        {/* <h2 className="heading leading-10 mx-auto text-3xl md:text-4xl relative mt-4 flex items-start w-full font-bold justify-center mb-4">
          Hand craft the figurine of your dreams
        </h2>
        <h3 className="subHeading relative flex items-start w-full text-l md:text-xl font-bold tracking-wider text-indigo-500 uppercase justify-center lg:items-center">
          Enjoy flexibility and control beyond your wildest imagination
        </h3> */}

        <Heading
          // topHeading="Turn your ideas into stunning 3D models with just a few easy clicks"
          mainHeading="Hand craft the figurine of your dreams"
          bottomHeading="Enjoy flexibility and control beyond your wildest imagination"
        />

        {/* <hr className="my-14"></hr> */}

        <img
          className="mobileImg"
          src={require('../images/octo.png').default}
          alt="ANVL Custom Mini - Octo"
        />

        <div className="featureGrid md:mt-20 w-full max-w-7xl mx-auto">
          <div className="featureBlock">
            <div className="feature feature1">
              <i></i>

              <div className="featureText">
                <h3 className="featureHeading">Get started in seconds</h3>

                <p>
                  With no sign up required you can start crafting your one of a
                  kind custom miniature in seconds. It’s that easy!
                </p>
              </div>
            </div>

            <div className="feature feature2">
              <i></i>
              <div className="featureText">
                <h3 className="featureHeading">Affordable pricing</h3>

                <p>
                  Develop your high-quality tabletop miniature and get ready for
                  your next adventure for as little as $4.97
                </p>
              </div>
            </div>
          </div>

          <div className="featureBlock">
            <img
              src={require('../images/octo.png').default}
              alt="ANVL Custom Mini - Octo"
            />
            {/* <img className="blurBg" src={require('../images/octo.png').default} /> */}
          </div>

          <div className="featureBlock">
            <div className="feature feature3">
              <i></i>
              <div className="featureText">
                <h3 className="featureHeading">Highest quality available</h3>

                <p>
                  Each of your custom miniatures are crafted with precision
                  using cutting-edge 3D printing technology
                </p>
              </div>
            </div>

            <div className="feature feature4">
              <i></i>
              <div className="featureText">
                <h3 className="featureHeading">Worldwide delivery</h3>

                <p>
                  Wherever you are in the world we’ve got you covered with our
                  fast and secure tracked delivery service
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
