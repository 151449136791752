import { useLocation } from 'react-router-dom';
import { NewspaperIcon, StarIcon, SupportIcon } from '@heroicons/react/outline';

import HybridLink from '../components/HybridLink';

import './ContactExtras.scss';

export default function ContactExtras({ title }) {
  const location = useLocation();

  const supportLinks = [
    {
      name: 'Frequently Asked Questions',
      href: 'https://hub.anvl.co/categories/frequently-asked-questions',
      description:
        'Need an answer in a hurry? Explore our knowledgebase of Frequently Asked Questions about ANVL Custom Miniatures.',
      icon: SupportIcon,
      cta: 'View FAQs',
    },
    location.pathname.startsWith('/partnership-requests')
      ? {
          name: 'Ambassador Program',
          href: '/ambassador-program',
          description:
            'Join our brand ambassador program and receive 10% commission on every sale you refer to us using your own personal link.',
          icon: StarIcon,
          cta: 'Become A Brand Ambassador',
        }
      : {
          name: 'Partnership Requests',
          href: '/partnership-requests',
          description:
            'We’re shaping the future of the tabletop gaming industry. Want to get involved? We’d love to hear more!',
          icon: StarIcon,
          cta: 'Partnership Requests',
        },
    {
      name: 'Media Inquiries',
      href: '/press',
      description:
        'ANVL is at the forefront of innovation in the tabletop gaming industry. Get in touch to learn more about our mission and upcoming projects.',
      icon: NewspaperIcon,
      cta: 'Download Press Kit',
    },
  ];

  return (
    <div className="app-ContactExtras bg-white">
      {/* Header */}
      <div className="relative pb-32">
        <div className="absolute inset-0">
          <div
            className="absolute inset-0 bg-gradient-to-br from-gray-900 to-indigo-900"
            aria-hidden="true"
          />
          <img
            className="opacity-20 mix-blend-luminosity w-full h-full object-cover"
            src={require('../images/A1.jpg').default}
            alt="Custom Miniatures"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="heading text-4xl font-extrabold tracking-tight text-white md:text-5xl">
            {title ? title : 'Need Something Else?'}
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">
            Got questions about custom minis? You've come to the right place!
            We're extremely passionate about tabletop gaming and are happy to
            help in any way we can.
          </p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div
              key={link.name}
              className="flex flex-col bg-white rounded-2xl shadow-lg"
            >
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                  <link.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="text-xl font-medium text-gray-900">
                  {link.name}
                </h3>
                <p className="mt-4 text-base text-gray-500">
                  {link.description}
                </p>
              </div>
              <div className="linkContainer p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                <HybridLink
                  href={link.href}
                  className="text-base font-medium text-indigo-700 hover:text-indigo-600"
                >
                  {link.cta}
                  <span aria-hidden="true"> &rarr;</span>
                </HybridLink>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
