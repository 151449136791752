const COLLECTION_ITEMS = [
  {
    featured: true,
    uuid: 'b0f126de-ccdc-4d57-a9df-59138143a7b3',
    name: 'Jim Brock',
    image: require('../images/collection/Jim Brock.png').default,
    tagline: 'Occult Detective',
    race: 'Goblin',
    description:
      "When you've got a problem with the supernatural, the police will either laugh you off or try to have you committed. That's when you turn to Jim Brock. Brock has been tangling with occult mysteries since before anyone can remember. Hauntings, witchcraft, even eldritch beings from beyond the dawn of man are all old hat for him. There may even be something not-quite-human about him...",
  },

  {
    featured: true,
    uuid: '37b4eb52-cc56-4267-8ba5-2f61d5b06fc3',
    name: 'Captain Francois Cornwall',
    image: require('../images/collection/Captain Francois Cornwall.png')
      .default,
    tagline: ' Catfolk Swashbuckler',
    race: 'Cat',
    description:
      "Sold into slavery at a young age, Francois Cornwall took to the pirates's life when he successfully led a revolt on a slave ship and comandeered the vessel. Since then, he's led a life of combating injustice on the high seas; Few things strike as much fear into the heart of a pirate's or slaver's ship as seeing his battle flag be run up a mast behind them...",
  },

  {
    featured: true,
    uuid: '54290780-2e2a-4e6f-9063-2d1ff848cf94',
    name: 'Whalford',
    image: require('../images/collection/Whalford.png').default,
    tagline: ' Goblin Artificer',
    race: 'Goblin',
    description:
      "Most Goblin tactics boil down to either 'stab it' or 'set it on fire.' Whalford, however, is committed to finding cutting edge ways to stab and set things on fire! His signature tactic is to use his homemade wing suit to fly over targets, lobbing alchemical bombs down on them to set the area ablaze! His custom made throwing-sickles also deliver a devastating razor-sharp edge!",
  },
  {
    featured: true,
    uuid: 'ea56845b-06aa-4962-b886-43022c1d2671',
    name: "Gregory 'Boots' Cornwall",
    image: require("../images/collection/Gregory 'Boots' Cornwall.png").default,
    tagline: ' Catfolk Explorer',
    race: 'Cat',
    description:
      "Born into slavery, Gregory Cornwall knew nothing of his home or his heritage. When freed as a young man, he made it his life's mission to find both of those things. Often with little more than the clothes on his back, he set out to survey as much of the world as possible, often becoming the first known humanoid to set foot in some of the world's more remote regions. ",
  },
  {
    featured: true,
    uuid: '1d3de1a7-0bc1-4c1c-8817-0badec9bbb17',
    name: 'Silverscale',
    image: require('../images/collection/Silverscale.png').default,
    tagline: 'Cleric of Righteousness',
    race: 'Dragon',
    description:
      'There is a popular perception of those with dragon blood as being tainted by evil and greed. Silverscale has made it his goal to combat that perception. He reveres dragon gods of goodness and healing, and travels the land, often on his own, to preach his sermons and offer magical healing to the sick and injured.',
  },

  {
    featured: true,
    uuid: 'f778654f-3ecd-4a9d-ad63-43f04295ac61',
    name: 'Muttoneater',
    image: require('../images/collection/Muttoneater.png').default,
    tagline: ' Canine Bounty Hunter',
    race: 'Wolf',
    description:
      "Few names strike fear into the hearts of wanted men as Muttoneater. Frequently underestimated because of his appearance, he's brought in hundreds of wanted criminals and has a strict code of honor; once he accepts a job, he cannot be bartered with or bribed into not delivering his end of the bargain.",
  },
  {
    uuid: 'e259d985-5ded-43af-a834-f85c51387af7',
    name: 'Conrad Clearskies',
    image: require('../images/collection/Conrad Clearskies.png').default,
    tagline: ' Unflappable Courier',
    race: 'Halfling',
    description:
      "If you have a parcel that absolutely has to reach its destination, Conrad Clearskies is the halfling for hire. Riding on his faithful mount, the dire chicken Wormbiter, he enthusiastically crosses the most perilous regions of the land and braves unthinkable weather to ensure his job is done and that his cargo gets to its recipient on time. It's rare to see such an unshakable work ethic in such a jolly, enthusiastic halfling.",
  },
  {
    featured: true,
    uuid: '23d15730-c82f-40f0-aa14-a7dc803bf6d3',
    name: 'Walton Shade',
    image: require('../images/collection/Walton Shade.png').default,
    tagline: 'Plague Doctor Necromancer',
    race: 'Human',
    description:
      "A bizarre disease overtakes a town, and its victims begin rising from the dead in something out of a horrific grimoire. This goes on for days, turning the town to chaos, when suddenly a mysterious plague doctor shows up with a miracle cure that the citizenry is more than willing to fork over their gold for. In reality, this 'plague doctor' is a necromancer who caused the dead to rise in the first place. Classic self-cleaning con.",
  },
  {
    uuid: '2a001ed3-dae4-46f8-8250-d915d30619de',
    name: 'Sister Beatrice Catherine',
    image: require('../images/collection/Sister Beatrice Catherine.png')
      .default,
    tagline: ' Cleric of Secrets',
    race: 'Cat',
    description:
      "While she appears to be nothing more than a simple nun on the surface, Sister Catherine is the head of a shadowy order that trades in secrets and forgotten information. They trade a secret for a secret; give her information on a local nobleman's extramarital affair, and she can provide you with the true name of a forgotten demon lord. She brokers power and influence, though some may want to ensure her silence...",
  },
  {
    uuid: '6ce1fdb6-1442-40af-9154-41cb9bfa4363',
    name: 'Rhonka Mightsmash',
    image: require('../images/collection/Rhonka Mightsmash.png').default,
    tagline: ' Minotaur Barfly',
    race: 'Minotaur',
    description:
      "Visit the local tavern after dark and you're almost certain to see this minotaur regular sitting at the bar, six or seven ales deep, bragging about her battlefield exploits. If you happen to need extra muscle or information on local adventuring spots, she'll agree to almost anything for the promise of booze.",
  },
  {
    uuid: 'd513e783-3cb9-4706-b049-6f9fba9bcd64',
    name: 'Rikishi',
    image: require('../images/collection/Rikishi.png').default,
    tagline: ' Flighty Assassin',
    race: 'Eagle',
    description:
      "Local legend tells of a ghastly birdlike-figures that appears to the wealthy and powerful before their death. Some say it's some sort of angel of death, an omen of their misdeeds. In truth, it's Rikishi, an assassin who, while a mere mortal, dispatches his targets with such grace and speed that many attribute it to the supernatural.",
  },
  {
    uuid: '7a0f11c6-d638-4195-ac77-689055f09c04',
    name: 'Adam Moonstrider',
    image: require('../images/collection/Adam Moonstrider.png').default,
    tagline: ' Wandering Spiritualist',
    race: 'Human',
    description:
      "Adam Moonstrider wanders the land preaching a new form of spirituality. He denies the good of all the existing temples, believing they're just out to make gold for themselves. However, he's been known to not at all be shy about accepting gold for himself...",
  },
  {
    uuid: '00d93a78-c230-46cb-86da-7b11daf6e49e',
    name: 'Don LeBon',
    image: require('../images/collection/Don LeBon.png').default,
    tagline: ' Cycloptic Scourge of the Seas',
    race: 'Cyclops',
    description:
      "Don't let his lack of depth perception fool you, he's still a lethally good shot! Don LeBon is one of the most feared pirates in the world. Most pirates, ruthless as they may be, follow some code of honor, but LeBon is known for doing anything that will ensure the profits and success of his operation, honor be damned.",
  },
  {
    uuid: 'fb229aee-eeca-4f0d-8c5c-e46ca8a9217c',
    name: 'Jogle the Clown',
    image: require('../images/collection/Jogle the Clown.png').default,
    tagline: ' Puerile Minstrel',
    race: 'Human',
    description:
      "Jogle the Clown is a travelling entertainer, playing the role of an incompetent knight in service to a local noble, using rumors and gossip from the township to mock the aristocracy in a show of entertainment for the common folk. Many view his humor as crude and immature, but his fans say the upper-crust just doesn't like being made fun of!",
  },

  //   {
  //     uuid: 'f3b4792c-94ab-41b6-93db-92e9edce1545',
  //     name: 'Herr Wilhelm Braun',
  //     image: require('../images/collection/Herr Wilhelm Braun.png').default,
  //     tagline: ' Third Reich Enforcer',
  //     race: 'Human',
  //     description:
  //       "As the Nazis grew desperate in the final days of the war, their experimental time machine was pushed into unsafe trials. The head of the project, Wilhelm Braun, was hurled into the timestream, and now travels the spacetime continuum with virtually no control over where he ends up next. But you can be sure that wherever he lands, he'll do whatever he can to further the Third Reich's agenda.",
  //   },
  {
    uuid: '4aa26adc-9888-4ba6-b04a-2508ec17d459',
    name: 'The Wanderer',
    image: require('../images/collection/The Wanderer.png').default,
    tagline: ' Mysterious Cultist',
    race: 'Tiefling',
    description:
      "The Wanderer's arrival in a town is sure to be followed by many folks' disappearances. No one knows for sure whether the missing citizens are being inducted into the cult, or being used as blood sacrifices for their occult rituals. One thing is for sure, you don't want to find out the hard way!",
  },
  {
    featured: true,
    uuid: '614e95b1-e8f9-4704-97e2-cb3dbd596970',
    name: 'Dusk Starfighter',
    image: require('../images/collection/Dusk Starfighter.png').default,
    tagline: ' Hardcore Rock Star ',
    race: 'Orc',
    description:
      'Rock and Roll has always been fueled by rebellion. Dusk Starfighter personifies this, travelling the land to both fight injustices at the hands of evil governments, and spread his anti-aristocratic philosophy through the power of his music. Despite his apparent philosophy of freedom and equality, he can be quite confrontational, so take care when talking to him.',
  },
  {
    uuid: 'ad9bb853-f395-444d-933a-e6ab00f6022f',
    name: 'Stanford Drider',
    image: require('../images/collection/Stanford Drider.png').default,
    tagline: ' Desert World Mercenary',
    race: 'Human',
    description:
      "When you need something done in the Desert Kingdoms, you call Stanford Drider. Spice smuggling, assassination, bandit ambush, you name it, he gets it done and doesn't ask questions. Just don't expect him to come cheap. But the results he gets are worth every credit.",
  },
  {
    uuid: '6c6f8435-e131-48f9-aebf-36cd5daf2d58',
    name: 'Eddward Werner',
    image: require('../images/collection/Eddward Werner.png').default,
    tagline: ' Gentlemanly Frog Explorer',
    race: 'Frog',
    description:
      'Blessed with an inquiring mind, Eddward Werner grew fascinated by the world that lay beyond his home swamp. He turned that passion into a career as an explorer, seeking to discover new frontiers and record their secrets in his books that have earned him a considerable fortune.',
  },
  {
    uuid: '2d18e346-1309-4e99-b00f-61cbdc6da88e',
    name: 'Blackcloak',
    image: require('../images/collection/Blackcloak.png').default,
    tagline: ' Wasteland Mercenary',
    race: 'Human',
    description:
      "Only the truly desperate will hire Blackcloak for mercenary work. He gets results, but the violent methods he uses often land his clients in more trouble than they started with. It's rare for his jobs to end without a body count...",
  },
  //   {
  //     uuid: '606ba6fa-53fe-4a51-91b9-4b5c5fd71438',
  //     name: 'Quartzine',
  //     image: require('../images/collection/Quartzine.png').default,
  //     tagline: ' Crystal Warrior',
  //     race: 'Crystal',
  //     description:
  //       'Crystals are often conduits of magical power. On exceedingly rare occasions, a crystal formation can be magically embued with sentience. Quartzine is an example of this phenomenon. Feeling blessed with the gift of life, he travels the world searching for a place where he truly belongs. ',
  //   },
  //   {
  //     uuid: '5e06793e-87b1-4a9d-9b84-0bee733a835d',
  //     name: 'Dask Rendar',
  //     image: require('../images/collection/Dask Rendar.png').default,
  //     tagline: ' Fearsome Tomb Raider',
  //     race: 'Orc',
  //     description:
  //       "As every adventurer knows, the tombs and catacombs of long-dead rulers and civilizations are home to much treasure and forgotten artifacts. But few as effectively hunt these ruins as Dask Rendar. Though he's very knowledgeable about the ancient items he plunders, he cares little for their historic value and simply sells to the highest bidder.",
  //   },
  {
    uuid: 'e5b02362-1a09-49fd-9949-93dbec392c14',
    name: 'Regina Josephs',
    image: require('../images/collection/Regina Josephs.png').default,
    tagline: ' Bawdy Barmaid',
    race: 'Human',
    description:
      "It's a barmaid's job to make sure every patron in the tavern feels welcome and special, and gets loose enough to lighten their coin pouches of course. Regina Josephs excels at these skills, knowing when to be funny, flirty, and flattering to give every guest the perfect evening. And if she slips a few GP in her own purse now and then, who's gonna notice?",
  },
  {
    uuid: 'd3d49619-792d-448a-b99b-edbc8d79ca39',
    name: 'Lupus Rex',
    image: require('../images/collection/Lupus Rex.png').default,
    tagline: ' The Wolf King',
    race: 'Human',
    description:
      "The last son of a long-deposed dynasty, Lupus Rex is bound and determined to restore his family's honor and position of power. With whatever meager scraps of gold and jewels he can scrounge up, he likes to pay adventurers to engage in some half-baked scheme or another to do something he's convinced will bring him political power. It rarely works out for him, but adventurers looking for treasure rarely concern themselves with that.",
  },

  //   {
  //     uuid: '05839632-2e17-49f3-9877-165357f52750',
  //     name: 'Lillith Edelbrine',
  //     image: require('../images/collection/Lillith Edelbrine.png').default,
  //     tagline: ' Experimental Witch',
  //     race: 'Human',
  //     description:
  //       "Some study magic to gain an understanding of the natural laws and rediscover old secrets. Lillith Edelbrine, however, sees magic as a force to innovate with. She's constantly crafting new wacky magic items, though it's anyone's guess whether they'll do anything useful or just blow up in a fiery explosion of arcane energy.",
  //   },
  {
    uuid: '7d898976-beb0-457b-92fe-ce589f95f0ae',
    name: 'Cave Goatrider',
    image: require('../images/collection/Cave Goatrider.png').default,
    tagline: ' Barbarian Raider',
    race: 'Dwarf',
    description:
      "The chief of a mountain barbarian tribe, Cave Goatrider is the appropriately-named frontline warrior who charges into battle head-on on a mighty ram, an unconventional mount but one capable of holding it's own in battle!",
  },
  {
    uuid: '72d939a1-c5bf-41d0-bc11-b1fb835a186a',
    name: 'Eddie Spikes',
    image: require('../images/collection/Eddie Spikes.png').default,
    tagline: ' Punk Rock Barbarian',
    race: 'Human',
    description:
      'Even in a cyberpunk world ruled by technology, there are those who seek to end the technocracy and return to the old ways. Eddie Spikes, a member of such a tribe living in the wastelands outside the megacities, has become the poster boy of the movement through his aggressive hard rock which slams the tech-dominated world.',
  },
  {
    uuid: 'e1dc3dbf-b3cc-4528-a54e-e374aedbb564',
    name: 'Lady Tiffany Penford',
    image: require('../images/collection/Lady Tiffany Penford.png').default,
    tagline: ' The Raven Lady',
    race: 'Human',
    description:
      'A wealthy eccentric, the Raven Lady is prone to flights of fancy, hiring adventurers for bizarre tasks, such as trying to track down fictional characters and ask for non-existent artifacts. Some speculate that she has some sort of witchcraft sight-beyond-sight that blurs the line between fiction and reality, but no one knows for sure.',
  },
  {
    uuid: 'b7508b4c-1ec4-4c77-8eb3-7d40e507dfb7',
    name: 'Professor Gretel Gatwick',
    image: require('../images/collection/Professor Gretel Gatwick.png').default,
    tagline: ' Renowned Archaeologist',
    race: 'Human',
    description:
      "Adventurers typically spend a lot of time plundering tombs and disturbing ancient ruins. As an archaeologist, Professor Gatwick has made it her life's work to stop this phenomenon. The historical value of a typical dungeon is immense, and she refuses to continue to allow adventurers to pilfer them for their own ends.",
  },
  {
    uuid: '67ffad10-dbcf-4c20-bd04-157848721e52',
    name: 'Patricia Patrick',
    image: require('../images/collection/Patricia Patrick.png').default,
    tagline: ' Streetwise Urchin',
    race: 'Human',
    description:
      'A homeless child in the streets of a large city, Patricia fends for herself and the other street children by trading in secrets. Adventurers will usually gladly hand over a few coins or morsels of food for intelligence on local goings-on that a sneaky child can easily witness without attracting too much suspicion.',
  },
  {
    uuid: '98cb3f05-01dc-4653-9bfe-187586d7f917',
    name: 'Josephine Mandrake',
    image: require('../images/collection/Josephine Mandrake.png').default,
    tagline: ' Eldritch Charlatan',
    race: 'Tiefling',
    description:
      "Most tieflings are frustrated by the average person's suspicion and fear of them. Josephine Mandrake, however, is counting on it. She goes from town to town, uses a few tricks of prestidigitation and pyrotechnics to intimidate the populace, and then demands gold or else she'll 'destroy' the town. The sad part is that it usually works. ",
  },
  {
    uuid: '46f879ec-3ffd-446d-ab76-60b64ac14077',
    name: 'Luna Waxwood',
    image: require('../images/collection/Luna Waxwood.png').default,
    tagline: ' Holy Avenger',
    race: 'Wolf',
    description:
      'Luna Waxwood reveres the gods more than anything else. Growing up as a feral child in the wilderness later adopted into a temple, she believes she was created by sheer divine will and strives to be a conduit for the gods, carrying out their missions in the mortal world. She has undergone much suffering and injury, but it has done nothing to dampen her resolve. ',
  },
  {
    uuid: '43fe5485-b16e-4cc4-922e-d481b7bd3192',
    name: "Liz 'Reptyl' Rockwell",
    image: require("../images/collection/Liz 'Reptyl' Rockwell.png").default,
    tagline: ' Hacker Activist',
    race: 'Human',
    description:
      "In the technocratic cyberpunk world, the control of data is the control of power. Few personify this like the hacker Reptyl. For the right price, whether that be creds or favors, she'll get you into any system and past any firewall. Just don't try to double-cross her or you'll end up finding all the creds hacked out of your account and  a hundred PD warrants to your name...",
  },
  {
    uuid: '3a2e78f8-d01a-4810-afe4-65a2f3728953',
    name: 'The Nightmare',
    image: require('../images/collection/The Nightmare.png').default,
    tagline: " Despot's Enforcer",
    race: 'Eagle',
    description:
      "The true mark of a tyrant is how he's able to enforce his will. The loyal servant of a despotic monarch, the Nightmare is ruthless and without mercy when it comes to following his master's orders. Those who would get on the wrong side of the Emperor would think twice about whether they think they can best the Nightmare in combat...",
  },
  {
    uuid: 'b0c75882',
    name: 'Dr. Sarah Redwoode',
    image: require('../images/collection/Dr Sarah Redwoode.png').default,
    tagline: ' Arcane Scholar',
    race: 'Human',
    description:
      "When one hears of mythical lost arcane lore hidden in a far-off land, most fantasize about going to get it, but rarely go further than that. Dr. Redwoode on the other hand starts packing and gets in the carriage. A foremost university professor, the entire magical world owes her a debt for all the arcane secrets she's recovered over the years.",
  },
  {
    uuid: '530a6e0e-8e55-4421-ad7d-860806dab038',
    name: 'Gronk Ember',
    image: require('../images/collection/Gronk Ember.png').default,
    tagline: ' Roving Scoundrel',
    race: 'Goblin',
    description:
      "If ever a creature personified the expression 'a menace to society', it would be Gronk Ember. Looting, rioting, even the occasional murder are usual tricks of his. Offer him gold, booze, or drugs, however, and the chaos he unleashes could be directed in your favor...",
  },
  {
    uuid: 'a7bdfee2-5673-4229-8f26-103848027a4e',
    name: 'Thron Wildheart',
    image: require('../images/collection/Thron Wildheart.png').default,
    tagline: ' Beastmaster Chieftain',
    race: 'Orc',
    description:
      'The world is divided between nature and civilization. The balance between the two must be maintained, and this is a responsibility that Thron Wildheart takes very seriously. Whenever civilization threatens nature, or vice versa, he mobilizes his legions of beasts in order to correct the imbalance.',
  },
  {
    uuid: '09b99bff-f136-49b7-99db-b478149646b7',
    name: 'Prince Thomas Greene',
    image: require('../images/collection/Prince Thomas Greene.png').default,
    tagline: ' Frog Prince',
    race: 'Frog',
    description:
      'Cursed into the form of a frog for his vanity and hedonistic ways, Prince Thomas Greene has taken up the life of an adventurer in order to redeem himself for his past misdeeds, and with any luck, find a way to reverse the curse that afflicts him.',
  },
  {
    uuid: 'f7066be8-0183-4a71-8e24-c7da62f4aad3',
    name: 'Hephesta Morlox',
    image: require('../images/collection/Hephesta Morlox.png').default,
    tagline: ' Broodmother Warrior',
    race: 'Dragon',
    description:
      'Morlox is on a mission to make the Dragonborn a force to be reckoned with, by humanoids and full dragons alike. Through careful breeding, she is cultivating the most powerful dragonborn offspring. Anyone who threatens her eggs will no doubt face her wrath...',
  },
  {
    uuid: 'c375271c-8693-47f1-953c-70236580c959',
    name: 'Adam Quintus',
    image: require('../images/collection/Adam Quintus.png').default,
    tagline: ' Traitorous Butler',
    race: 'Cat',
    description:
      "It's a cliche that 'the butler did it', but Adam Quintus is no mere butler. A highly skilled assassin, he intimates himself into his targets' lives, and becomes such a part of them that no one dare suspect him of foul play when the target inevitably dies under mysterious circumstances...",
  },
  {
    uuid: '35f9e6a2',
    name: 'Professor Zachary Prometheus',
    image: require('../images/collection/Professor Zachary Prometheus.png')
      .default,
    tagline: ' Arcane Professor',
    race: 'Human',
    description:
      "Every student at the mage's college dreads seeing the name Zachary Prometheus on their class schedule. He's known for being incredibly demanding of his students, holding them to high and often archaic standards. But no one can argue that the old man is a powerful wizard of great age and experience, often knowing old-time magic lore that's scarcely known these days. Impress him, and he might share his secrets...",
  },
  {
    uuid: '33c73433-5e12-456e-864a-446d5ff3c9f4',
    name: 'Kuro',
    image: require('../images/collection/Kuro.png').default,
    tagline: ' Minotaur Samurai',
    race: 'Minotaur',
    description:
      'Minotaur kind is known for their brutish, savage tactics on the battlefield. Kuro never liked that reputation. He vowed at a young age to follow the path of the samurai, and to conduct himself with honor without compromising any of his combat ability.',
  },
  {
    uuid: '8e66c6db-7a4a-4860-bceb-18226a2537c2',
    name: 'Olaf Thunderblast',
    image: require('../images/collection/Olaf Thunderblast.png').default,
    tagline: ' Musketeer Barbarian',
    race: 'Dwarf',
    description:
      "Most barbarians eschew technology such as firearms, believing them to be the tools of an unskilled warrior. Olaf Thunderblast on the other hand believes that if a musket can make a barbarian a more effective killer, why shouldn't they use one?",
  },
  {
    uuid: 'b3145a60-0b59-4765-a5d5-f8c711d1ffb7',
    name: 'Sycamoricus',
    image: require('../images/collection/Sycamoricus.png').default,
    tagline: ' Minstrel of Nature',
    race: 'Treant',
    description:
      "It's often said that nature has its own song. Sycamoricus takes that expression to a literal extreme. Moved by the music of the natural world, this treant has created a sublime harmony between druidic magic and bardic magic that few others could ever hope to master.",
  },
  {
    uuid: '58df1afb-d3c9-4245-aa8b-5992c1ef9040',
    name: "William 'Bazooka Billy' Walton",
    image: require("../images/collection/William 'Bazooka Billy' Walton.png")
      .default,
    tagline: ' Self-Assured Grenadier',
    race: 'Human',
    description:
      '"If it moves, I can blow it up!" is the personal motto of Bazooka Billy. In the trenches of war, no one is a more sure shot with a long-range explosive device. If you need something far away to be completely obliterated, he\'s the soldier you want to talk to.',
  },
  {
    uuid: '7f60ce76-331b-4493-9aaf-8b19178bae92',
    name: 'Whamo the Clown',
    image: require('../images/collection/Whamo the Clown.png').default,
    tagline: ' Jester of Death',
    race: 'Human',
    description:
      'Whamo the Clown lives up to his name. Whether its a bullet firing from his signature revolver or a deadblow from his comically oversized hammer, his love for physical comedy extends to the point where the punch line is often lethal!',
  },

  // Elf Pack

  {
    uuid: '427460a3',
    name: 'Norwyn Edelbrawn',
    image: require('../images/collection/Norwyn Edelbrawn.png').default,
    tagline: 'Warrior Princess',
    race: 'Elf',
    description:
      'When enemies threaten the elven kingdoms and war becomes neccessary, the people know they have someone they can turn to. Norwyn Edelbrawn has trained in the art of war her entire life to defend her people, and her unstoppable charges on the battlefield certainly show it.',
  },

  {
    uuid: '555ec879',
    name: 'Prince Whyndon',
    image: require('../images/collection/Prince Whyndon.png').default,
    tagline: 'Headstrong Prince',
    race: 'Elf',
    description:
      "A student of both martial and arcane arts, Prince Whyndon's natural combat prowess combined with his royal status make him extremely assured of himself. He feels that nothing is outside of his abilities, and the surest way to get him to do what you want is to use reverse psychology and insinuate that he couldn't do it.",
  },
  {
    uuid: 'aa3eaefd',
    name: 'Thornicus',
    image: require('../images/collection/Thornicus.png').default,
    tagline: 'Nature Worshipper',
    race: 'Elf',
    description:
      'Elves have a deep connection with nature, but Thornicus takes it a step further. He follows what he dubs "nature\'s path," taking up a life that does nothing to disturb nature and in turn nature reveals its secrets to him. There are many secrets of nature known to him and him alone, and should anything threaten the natural realm, it is his calling to defend it.',
  },

  {
    uuid: 'aefb3b5a',
    name: 'Atticus Tabernacle',
    image: require('../images/collection/Atticus Tabernacle.png').default,
    tagline: 'Elderly Scholar',
    race: 'Elf',
    description:
      "Elves are among the longest-lived races of the mortal realm, but Atticus Tabernacle pushes that to an extreme. Believed by many to be the oldest elf ever, he has studied much in his multiple milennia. While he can't be expected to remember everything at his age, it's not unheard of for him to spit out an arcane secret that's been forgotten for centuries in a fit of senility ",
  },

  {
    uuid: '85e9e065',
    name: 'Winefred Dewalla',
    image: require('../images/collection/Winefred Dewalla.png').default,
    tagline: 'Folk Legend Archer',
    race: 'Elf',
    description:
      "Ask any elf, and they'll surely have a favorite Winefred Dewalla story. From managing to fell two hill giants with a single arrow to arcing an arrow through the window of an evil warlord's tallest tower, the list of feats attributed to her is nearly endless. But just how does the real woman stack up to the myth? ",
  },
  {
    uuid: '64812710',
    name: 'Tyren VII',
    image: require('../images/collection/Tyren VII.png').default,
    tagline: 'False King',
    race: 'Elf',
    description:
      "Tyren is convinced that he is, beyond a shadow of a doubt, the rightful king of all elves. Though he does maintain a small order of followers, the average elf considers him a laughing stock. Adventurers are most likely to encounter him on a foolhardy crusade to topple an existing elven government figure and claim his 'throne', or trying to dig up some forgotten archaeological evidence that proves his divine right is legitimate.",
  },
  {
    uuid: '61983ae4-b891-4641-bfd3-5ae1b2008c7f',
    name: 'Henry Granitebeard',
    image: require('../images/collection/Henry Granitebeard.png').default,
    tagline: 'Master Weaponsmith',
    race: 'Dwarf',
    description:
      "When wealthy adventurers need quality weapons, the Granitebeard clan are the smiths they seek. Henry, the clan's latest patriarch, is just as formidable using weapons as he is making them.",
  },

  //Skelly Pack

  {
    uuid: 'ab65f20b',
    name: 'The Stranger',
    image: require('../images/collection/The Stranger.png').default,
    tagline: 'Undead Vagabond',
    race: 'Skeleton',
    description:
      "No one knows who he was or where he came from. But he wanders the frontier, putting things right that once went wrong, all without speaking a word. Some say he was a sinner cursed to walk the earth until he'd atoned for all the sins he'd committed in life. Some say he's the soul of a man so dedicated to justice that even in death he could not rest. But all we know for sure is that when the Stranger rides into town, the lawless are about to have a bad day.",
  },

  {
    uuid: 'fa4842d0',
    name: 'Victor the Unholy',
    image: require('../images/collection/Victor the Unholy.png').default,
    tagline: 'Lich Tyrant',
    race: 'Skeleton',
    description:
      "The king is dead, long live the king. It's hard to bring an end to a tyrant's rule when the tyrant is incapable of dying. That's exactly the principle by which Victor the Unholy rules. Dominating his kingdom for at least two centuries, countless fanatical cultists have sacrificed their life essence to artificially extend his life, and just as many adventurers have met their deaths trying to stop the flow of his unlife. ",
  },

  {
    uuid: 'b3738f5a',
    name: "Ol' Bones",
    image: require('../images/collection/Ol Bones.png').default,
    tagline: 'Skeletal Pirate',
    race: 'Skeleton',
    description:
      "The skeleton of a first mate that met a grisly end that no one had the heart to toss overboard. Retaining much of the skills he knew in life, Ol' Bones can still swab the deck, haul cargo, and man the cannons. Despite a lack of speech, he's just as capable a crewman as any of the living sailors aboard!",
  },

  {
    uuid: 'ec2d790b',
    name: 'Danny Anarchy',
    image: require('../images/collection/Danny Anarchy.png').default,
    tagline: 'Spirit of Punk Rock',
    race: 'Skeleton',
    description:
      "Once a frontman for the loudest, most out of control punk group on the east coast, Danny Anarchy famously died in a riot. But miraculously a few years later, his reanimated skeleton started making public appearances. No one knows for sure what brought Danny Anarchy back into the world. But he's been known to show up at punk rock shows, riots, and just generally any opportunity to stick it to the man. ",
  },

  {
    uuid: '147f46de',
    name: 'The Spectre',
    image: require('../images/collection/The Spectre.png').default,
    tagline: 'Avatar of the Reaper',
    race: 'Skeleton',
    description:
      'From the moment a mortal is born, it instinctively fears death. For most, death is merely a force of the universe, the natural end of life. But some see it as more than that, as an entity with its own force of will and agency. Should this Death need to represent itself to a mortal, it takes the guise of the Reaper. This is merely an image, and cannot be harmed, but those who try will not like the consequences...',
  },

  {
    uuid: '79ae8199',
    name: 'Gatekeeper Robert',
    image: require('../images/collection/Gatekeeper Robert.png').default,
    tagline: 'Skeletal Guard',
    race: 'Skeleton',
    description:
      "Robert, a humble town guard, was one of those who lost their lives defending the town from a necromancer's rampage. His reanimated skeleton took over the habits of his mortal life and continues to stand watch outside the town's drawbridge, just as Robert did in life. Since he's something of a novelty and doesn't cause anyone any harm, the town's government just decides to let him stay. He's even managed to alert other living guards to trespassers on a few occasions!",
  },

  // {
  //   uuid: 'cbad41f3',
  //   name: 'Skip Eddard',
  //   image: require('../images/collection/Skip Eddard.png').default,
  //   tagline: 'The Mouth of Nature',
  //   race: 'Skeleton',
  //   description:
  //     "A long-lived and long-devoted druid, Skip Eddard continues to serve the forces of nature even after his body died long ago. If someone wishes to 'communicate' with nature, they can go to the tree stump in the deep woods where Skip lies, and makes a small offering of something with natural value, such as water, seeds, fruit, etc., Nature itself may use Skip's skeleton as a conduit for speech, communicating its secrets in the ancient language of the druids.",
  // },

  // Orcs

  {
    uuid: '39a38878',
    name: 'Greta Hellscream',
    image: require('../images/collection/Greta Hellscream.png').default,
    tagline: 'Master Alchemist',
    race: 'Orc',
    description:
      "Usually orcs don't have respect for magic and potions, but it seems that either Greta knows how to add amazing flavours to whatever she brews, or she happens to be the leader's sister.",
  },
  {
    uuid: '2c7dedf2',
    name: 'Jeffrey Hellscream',
    image: require('../images/collection/Jeffrey Hellscream.png').default,
    tagline: 'Tribe Leader',
    race: 'Orc',
    description:
      'The constant battles have forged Jeffrey from a skilled but quick-tempered orc, to a skilled and wise leader. He recently started warning his rivals before smashing them to pieces. Great job Jeffrey!',
  },
  {
    uuid: 'c33b8e23',
    name: 'Varbag The Annihilator',
    image: require('../images/collection/Varbag The Annihilator.png').default,
    tagline: 'Orc Captain',
    race: 'Orc',
    description:
      "Goes by many names. Jeffrey's Lion. Land pillager. Wall breaker. Or simply, Varbag The Annihilator. The only thing bigger than his rage and aggresion are his biceps. Kill-Eat-Sleep-Repeat, this is his everyday routine.",
  },
  {
    uuid: '4b5a8f34',
    name: 'Zackreth The Rat',
    image: require('../images/collection/Zackreth The Rat.png').default,
    tagline: 'Peltast Trainee',
    race: 'Orc',
    description:
      'The clan finally found a use for this skinny short orc. Give him a spear or a sling, and his high agility will take care of the rest.',
  },
  {
    uuid: '76ed0e99',
    name: 'Bazur The White',
    image: require('../images/collection/Bazur The White.png').default,
    tagline: 'Royal Orc',
    race: 'Orc',
    description:
      "It's unusual to meet a civilized orc, but not impossible. Bazur is a heroic orc, reminiscent of old human folklore heroes. But don't be fooled by his gentlemanly conduct, he's been known to bite!",
  },
  {
    uuid: '756c993b',
    name: 'Peanut',
    image: require('../images/collection/Peanut.png').default,
    tagline: 'Novice Bard',
    race: 'Orc',
    description:
      'He may not be the toughest, strongest, most fearsome orc, but he sure knows how to play the guitar... or at least he tries.',
  },
  {
    uuid: 'e7c9a12c',
    name: 'Hoborc',
    image: require('../images/collection/Hoborc.png').default,
    tagline: 'Hobo Orc',
    race: 'Orc',
    description:
      "Where did he come from? Who are his parents? What's his name? Questions probably not even him can answer. He lives on the streets, but somehow he manages to always be around good food. The other orcs just call him 'Hoborc', and it's a fitting name.",
  },
  //Dwarves
  {
    uuid: 'bbd6ee65',
    name: 'Tor Firestone',
    image: require('../images/collection/Tor Firestone.png').default,
    tagline: 'Scholar of Arcana',
    race: 'Dwarf',
    description:
      "While the path of the mage is not one frequently followed by Dwarves, Tor Firestone is one such legendary exception. Probably the most venerated Dwarven wizard that ever lived, he has spent much of his long life advising Dwarven kings, nobles, and craftsman's guilds about all matters concerning magic. For those few young dwarves interested in wizardry, Tor Firestone is almos undoubtedly who they wanted to be when they grew up.",
  },
  {
    uuid: '3c66a1b1',
    name: 'Warwick Neverfail',
    image: require('../images/collection/Warwick Neverfail.png').default,
    tagline: 'Retired Adventurer ',
    race: 'Dwarf',
    description:
      "Anyone who knows one thing about Dwarves knows they're fond of drink. And thus it makes sense that a retird Dwarf adventurer would use his fortune to pursue the most Dwarven aspiration of opening his own pub. This is precisely what Warwick Neverfail did. He's always got stories of his heyday to share, and adventurers looking for a bit of information from decades long gone will no doubt find what he has to say very useful.",
  },
  {
    uuid: 'd16f6e11',
    name: 'Quentin Boulderhand',
    image: require('../images/collection/Quentin Boulderhand.png').default,
    tagline: 'Dwarf General',
    race: 'Dwarf',
    description:
      "The history of the Dwarves is one frought with warfare. Orcs, giants, dragons, you name it, the Dwarves have fought it. Quentin Boulderhand is the end result of this history, a personification of the Dwarves' constant hypervigilence against any potential threat. While most generals are content to sit in their field barracks and give orders from a far, Boulderhand is quite eager to take up steel himself and face combat firsthand.",
  },
  {
    uuid: '193cf125',
    name: 'Adam Greatcleaver',
    image: require('../images/collection/Adam Greatcleaver.png').default,
    tagline: 'Mine Explorer',
    race: 'Dwarf',
    description:
      'From mining operations to military strongholds, to communal living spaces, a great deal of Dwarven civilization is built around underground tunnels. Adam Greatcleaver, the patriarch of the Greatcleaver clan, has served as the trailblazer of these tunnels. He maintains a profitable family business responsible for both trailblazing new tunnels and rediscovering old ones with his brothers, sons, and cousins. ',
  },

  {
    uuid: '267ae000',
    name: 'Braun Sunforge',
    image: require('../images/collection/Braun Sunforge.png').default,
    tagline: 'Master Weaponsmith',
    race: 'Dwarf',
    description:
      "The Dwarves reputation as skilled craftsmen preceeds them, and few represent that better than Braun Sunforge. From the heaviest warhammer to the thinnest, lightest dagger, weapons bearing the Sunforge signature are sought after by everyone from kings to thieves' guilds to barbarian tribes. While his services are in extremely high demand, he may be willing to bump a special order to the top of his commission queue if something out-of-the-ordinary catches his interest...",
  },
  {
    uuid: 'e64ac8ba',
    name: 'Myrtle Mightstone',
    image: require('../images/collection/Myrtle Mightstone.png').default,
    tagline: 'Bawdy Barmaid',
    race: 'Dwarf',
    description:
      'Leave it to Myrtle Mightstone to know the drinking songs and funny folk tales that have been known make even the most stone-faced dwarves crack a smile. Far from mere entertainment, however, her songs and stories can  often provide a clue into the oral history of Dwarves that may have escaped being written down by scholars. ',
  },
  {
    uuid: '416d359b',
    name: 'Ingrid Frostborn',
    image: require('../images/collection/Ingrid Frostborn.png').default,
    tagline: 'Guardian of the Arctic',
    race: 'Dwarf',
    description:
      'There are few more dangerous regions to travel through than the permanently icy wilds of the north. Yet this inhospitable tundra is where Ingrid, last of the Frostborn clan, thrives the best. She maintains quite a healthy business sheperding travelers and adventurers through the frigid wilderness, who rely on her generational expertise to survive.',
  },
];

export default COLLECTION_ITEMS;
