import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

import { useAuth } from '../utils/auth';
import UserBasket from '../components/UserBasket';
import {
  FaCoins,
  FaList,
  FaRegUser,
  FaUser,
  FaUsers,
  // FaTools,
  // FaDoorOpen,
} from 'react-icons/fa';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function MenuIconUser() {
  const [auth] = useAuth();

  if (!auth?.token) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="outline-none focus:outline-none border-none shadow-none">
          <span className="inline-flex items-center bg-indigo-300 bg-opacity-30 border-2 border-indigo-200 border-opacity-10 shadow-md bg w-8 h-8 justify-center rounded-full transition hover:bg-opacity-40 outline-none">
            {/* <strong className="text-sm">S</strong> */}
            <strong className="text-sm">
              <FaRegUser className="text-sm text-gray-200" />
            </strong>
          </span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right z-50 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            {!'hidden' && (
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/profile"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm'
                    )}
                  >
                    <FaUser
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Profile
                  </Link>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/characters"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <FaUsers
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Characters
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/orders"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <FaList
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Orders
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/credits"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <FaCoins
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Credits
                </Link>
              )}
            </Menu.Item>
          </div>
          {/*<div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/settings"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <FaTools
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Settings
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/logout"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <FaDoorOpen
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Logout
                </Link>
              )}
            </Menu.Item>
          </div>*/}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export function MenuIconBasket() {
  return (
    <span className="inline-flex items-center justify-center ">
      {/*<BasketIcon quantity={1} />*/}
      <UserBasket />
    </span>
  );
}

export default function MenuIcons({ user, basket }) {
  return (
    <div className="flex mr-16 lg:mr-11 ml-16 lg:ml-6 gap-10">
      {user && <MenuIconUser />}
      {basket && <MenuIconBasket />}
    </div>
  );
}
