import React from 'react';

let id = 0;

export default class ClickOffEvent extends React.Component {
  wrapper = null;

  constructor(self) {
    super(self);

    this.id = id++;
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleBodyClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  }

  handleBodyClick = (event) => {
    if (this.props.disabled) {
      return;
    }

    if (this.wrapper && !this.wrapper.contains(event.target)) {
      // fire if clicking somewhere outside the wrapper
      if (typeof this.props.handler === 'function') {
        // console.log('firing handler', this.id, 'of', id);
        this.props.handler(event);

        if (this.props.preventDefault) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  };

  render() {
    return (
      <div ref={(ref) => (this.wrapper = ref)} className={this.props.className}>
        {this.props.children}
      </div>
    );
  }
}
