import './TermsAndConditions.scss';

export default function TermsAndConditions() {
  return (
    <section className="app-TermsAndConditions">
      <section className="wrapper">
        <h2>Terms Of Service</h2>
        <p>
          ANVL Global LLC (“anvl.co") owns and operates this Website. If you
          continue to browse and use this website, you are agreeing to comply
          with and be bound by the following Terms and Conditions of use, which
          together with our Privacy Policy govern ANVL's relationship with you
          in relation to this Website. These Terms and Conditions apply between
          you, the User of this Website and ANVL, the owner and operator of this
          Website.
        </p>
        <p>
          Your agreement to comply with and be bound by these Terms and
          Conditions is deemed to occur upon your first use of this Website.
          Please read these terms and conditions carefully, as they affect your
          legal rights. If you do not agree to be bound by these Terms and
          Conditions, you should stop using the Website immediately.
        </p>
        <p>
          The content of the pages of this website are for your general
          information and use only. It is subject to change without notice.
          Website access is provided on the understanding that we may modify or
          withdraw, temporarily or permanently, the Website (or any part of it).
          We are not liable to you or to any third party for any changes or
          withdrawal of the site. If you do not agree to our right to change the
          Website or the Terms and Conditions from time to time, you should stop
          using the Website immediately.
        </p>
        <p>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness or suitability
          of the information and materials found or offered on this website for
          any particular purpose. You acknowledge that such information and
          materials may contain inaccuracies or errors and we expressly exclude
          liability for any such inaccuracies or errors to the fullest extent
          permitted by law.
        </p>
        <p>
          Headings used in these terms and conditions are for convenience only
          and shall not affect their interpretation.
        </p>
        <p className="tos-last-point">
          Your use of this website and any dispute arising out of such use of
          the website is subject to the laws of the United States.
        </p>
        <h3>Availability of the Website</h3>
        <p>
          We may make improvements or changes to the information, services,
          products and other materials on this website, or terminate this
          website, at any time without notice. We may also modify these Terms
          and Conditions at any time, and such modification shall be effective
          immediately upon posting of the modified Terms and Conditions on this
          website. Accordingly, your continued access or use of this website is
          deemed to be your acceptance of the modified Terms and Conditions.
        </p>
        <p>
          Any online facilities, tools, services or information that ANVL makes
          available through the Website (the Service) is provided "as is" and on
          an "as available" basis. We give no warranty that the Service will be
          free of defects and/or faults. To the maximum extent permitted by the
          law, we provide no warranties (express or implied) of fitness for a
          particular purpose, accuracy of information, compatibility and
          satisfactory quality. ANVL is under no obligation to update
          information on the Website.
        </p>
        <p>
          Whilst ANVL uses reasonable endeavours to ensure that the Website is
          secure and free of errors, viruses and other malware, we give no
          warranty or guaranty in that regard and all Users take responsibility
          for their own security, that of their personal details and their
          computers. ANVL accepts no liability for any disruption or
          non-availability of the Website.
        </p>
        <p className="tos-last-point">
          ANVL reserves the right to alter, suspend or discontinue any part (or
          the whole of) the Website including, but not limited to, any products
          and/or services available. These terms and conditions shall continue
          to apply to any modified version of the Website unless it is expressly
          stated otherwise.
        </p>
        <h3>Third Party Content</h3>
        <p className="tos-last-point">
          Any link contained on this Site which provides access to third party
          resources or content is provided for convenience only. ANVL does not
          express any opinion on or endorsement of the content of any such third
          party links and expressly disclaims any liability for the quality,
          content, nature, or reliability of any such third party resources and
          the information, products and services found thereon.
        </p>
        <h3>Intellectual property and acceptable use</h3>
        <p>
          All of the text, graphics, photographs, logos, artwork, sounds, music,
          video, user interfaces, visual interfaces and computer code (the
          "Content") belongs to ANVL. In these terms and conditions, Content
          means any text, graphics, images, audio, video, software, data
          compilations, page layout, underlying code and software and any other
          form of information capable of being stored in a computer that appears
          on or forms part of this Website, including any such content uploaded
          by Users. By continuing to use the Website you acknowledge that such
          Content is protected by copyright, trademarks, database rights and
          other intellectual property rights. Nothing on this site shall be
          construed as granting, by implication, estoppel, or otherwise, any
          license or right to use any trademark, logo or service mark displayed
          on the site without the owner's prior written permission. You are not
          allowed to copy, reproduce, republish, upload, post, publicly display,
          translate, transmit or distribute this Content in any way without
          ANVL’s prior written permission.
        </p>
        <p>
          You must not otherwise reproduce, modify, copy, distribute or use any
          Content without the written permission of ANVL.
        </p>
        <p>You may not use the Website for any of the following purposes:</p>
        <p>
          a) in any way which causes, or may cause, damage to the Website or
          interferes with any other person's use or enjoyment of the Website.
        </p>
        <p>
          b) in any way which is harmful, unlawful, illegal, abusive, harassing,
          threatening or otherwise objectionable or in breach of any applicable
          law, regulation, governmental order.
        </p>
        <p className="tos-last-point">
          c) making, transmitting or storing electronic copies of Content
          protected by copyright without the permission of the owner.
        </p>
        <h3>Prohibited Activity</h3>
        <p>
          You must not misuse the Website. You will not: upload viruses,
          trojans, worms, logic bombs or other malicious code; corrupt data;
          cause annoyance to other Users; use the Website to do anything
          unlawful, misleading, malicious or discriminatory; facilitate or
          encourage any violation of these Terms and Conditions.
        </p>
        <p>
          We reserve the right, in our sole discretion, to restrict, suspend, or
          terminate your access to all or any part of the Website or your ANVL
          Account at any time, for any or no reason, with or without prior
          notice, and without liability.
        </p>
        <p className="tos-last-point">
          ANVL reserves the right to refuse site access if we believe a User is
          using proxy Internet Protocol addresses as a method to hide the use of
          multiple accounts or to disrupt the Website in any way. By submitting
          any material to us, you automatically grant ANVL a royalty-free,
          perpetual, exclusive right and license to use, modify, edit, adapt,
          publish, re-use, translate, distribute, perform and display such
          material in whole or part worldwide and/or to incorporate it in other
          works in any form, media, or technology now known or later developed.
        </p>
        <h3>Linking to the Website</h3>
        <p>
          You must not establish any link to the Website to suggest any form of
          association, approval or endorsement on our part where none exists or
          establish a link from any website that is not owned by you.
        </p>
        <p className="tos-last-point">
          The Website must not be framed or embedded on any other site.
        </p>
        <h3>Compliance with Laws</h3>
        <p className="tos-last-point">
          The Website may be used only for lawful purposes and in a lawful
          manner. You agree to comply with all applicable laws, statutes and
          regulations regarding the Website and any transactions conducted on or
          through the Website.
        </p>
        <h3>Product Descriptions on our Website</h3>
        <p>
          Your use of any information or materials on this Website is entirely
          at your own risk, for which we shall not be liable. It shall be your
          own responsibility to ensure that any products, services or
          information available through this website meet your specific
          requirements.
        </p>
        <p className="tos-last-point">
          We take reasonable care to ensure that all Product Descriptions are
          correct at the time when the relevant information was first entered
          onto the Website. We have made every effort to display as accurately
          as possible the appearances, colours, textures or finishes of our
          Products. What you see will depend on your computer equipment, screen
          or monitor. We are unable to guarantee that a Product's images are an
          accurate representation of the actual merchandise.
        </p>
        <h3>Product Availability</h3>
        <p className="tos-last-point">
          The Website features Products that have been carefully selected for
          sale on the Website. Products are subject to availability so once a
          Product has sold out it will be taken off the Website at the earliest
          opportunity and may not be available again. As there is a delay
          between the time when your Order is placed, and the time when the
          Order is accepted, the stock position relating to particular Products
          may change. If a Product you have ordered goes out of stock before we
          accept your Order, then ANVL shall not be liable to you for being
          unable to provide that Product. If such a situation arises, then we
          shall notify you as soon as possible and you will not be charged for
          the out of stock Product.
        </p>
        <h3>Pricing Policy</h3>
        <p className="tos-last-point">
          Although we try to ensure all Product prices displayed in the Product
          Description are accurate, errors may sometimes occur. If we discover
          an error in the price of a Product you have ordered we will be unable
          to accept your Order and shall contact you as soon as possible in
          order to give the option of either reconfirming your Order at the
          correct price or cancelling it. If we are unable to contact you, we
          will treat your Order in respect of the incorrectly priced item as
          cancelled.
        </p>
        <h3>Order Process</h3>
        <p>
          ANVL takes all reasonable care, in so far as possible, to keep the
          details of your Order and payment secure, but in the absence of
          negligence on our part we will not be liable for any loss you may
          suffer if a third party procures unauthorised access to any Personal
          Information you provide when accessing or placing an Order on the
          Website. For more information on how we use and protect your Personal
          Information, please refer to our privacy policy.
        </p>
        <p>
          The technical steps to place your Order and create a contract of sale
          between you and us are, as follows:
        </p>
        <p>
          a) You place an Order on the Website by pressing the 'Checkout' button
          at the end of the checkout process. You will be guided through the
          process of placing an Order by a series of simple instructions on the
          Website.
        </p>
        <p>
          b) We will send you an email confirming your Order which will detail
          the Product(s) (including their Product Descriptions) that you have
          ordered. This email does not constitute an acceptance of your Order by
          us.
        </p>
        <p>
          c) As your Order is shipped by us we will send you a despatch
          confirmation email. Upon sending this email, your Order will be taken
          to have been accepted by us unless we have notified you that we do not
          accept your Order, or you have cancelled it.
        </p>
        <p>
          d) Subject to us having accepted your Order, we will then arrange for
          delivery of it to you at your nominated address.
        </p>
        <p>
          We shall be entitled to supply Products ordered as part of one Order
          in instalments and each instalment shall be deemed to constitute a
          separate contract between you and us. This may mean that Products are
          dispatched separately.
        </p>
        <p>
          Non-acceptance of your Order (or parts of your Order) may be due to
          any one or more of the following non-exhaustive reasons:
        </p>
        <p>a) A product you ordered is out of stock.</p>
        <p>b) We are unable to obtain authorisation for your payment.</p>
        <p>c) We have identified an error with a Product Description.</p>
        <p>d) You are not eligible to order a Product.</p>
        <p>e) There is a system or procurement failure.</p>
        <p>f) You fail our customer validation checks.</p>
        <p>
          g) There are restrictions (legal or otherwise) or practices in
          relation to a Product which prevent us from being able to sell or
          deliver it to you.
        </p>
        <p>
          All Products purchased from this website are for personal use only and
          not for resale.
        </p>
        <p className="tos-last-point">
          The contract between you and ANVL will be concluded in English and
          subject to the laws of the United States and the jurisdiction of the
          American courts.
        </p>
        <h3>Payment and Payment Methods on the Website</h3>
        <p>
          To process your order, you will be asked to provide customary billing
          information such as name, billing address and credit card, debit card
          or bank account information to its third party payment processor. ANVL
          will never store your bank account details. If you are directed to
          ANVL’s third party payment processor, you may be subject to terms and
          conditions governing use of that third party’s service and that third
          party’s personal information collection practices. Please review such
          terms and conditions and privacy policy before using the services.
          Once your transaction is complete you will receive a confirmation
          email summarising your order.
        </p>
        <p>
          Payment by bank/credit/debit card will be taken at the point of
          dispatch by our Customer Service team.
        </p>
        <p className="tos-last-point">
          By placing your Order and making an offer to buy a Product, you
          authorise us to transmit information (including any updated
          information) or to obtain information about you from third parties
          from time to time and this may include verification checks involving
          your debit or credit card number or credit reports in order to
          authenticate your identity, to validate your credit card, to obtain an
          initial credit card authorisation, to protect you and us from fraud;
          and to enable us to arrange delivery of your Order to your nominated
          delivery address.
        </p>
        <h3>Promotional Discounts</h3>
        <p className="tos-last-point">
          Any coupon, discount, offer or promotional discount offered on the
          Website is valid only for use as part of a purchase made via the
          Website, unless otherwise stated and subject to availability.
        </p>
        <h3>Delivery</h3>
        <p>
          You must provide us with complete and accurate delivery address
          information. For the avoidance of doubt, this includes not only the
          address that your Order is going to, but also the name of its
          recipient. We cannot be liable for the delivery of your Order to the
          wrong address or the wrong person as a result of you supplying us with
          incomplete or inaccurate information.
        </p>
        <p className="tos-last-point">
          Please note that delivery of your Order may take longer during a sale
          or other busy periods.
        </p>
        <h3>Cancellation</h3>
        <p>
          If you are an EU customer, you have the right to cancel your contract
          at any time up to fourteen (14) days after the day on which you
          receive your Order. If you place an Order for several Products and
          they are delivered separately then your fourteen (14) days will start
          the day after you receive the last Product on your Order.
        </p>
        <p>
          If, for any reason, you wish to cancel your contract before your Order
          has been dispatched or you have been notified that it is available for
          collection, then you need to let us know. In order to do so, you can
          let us know by emailing or telephoning ANVL's Customer Services team.
          We have received notification by you of your wish to cancel your
          contract, subject to any monies having been taken from you, we will
          refund you the original purchase price and delivery charge of your
          Order within fourteen (14) days.
        </p>
        <p className="tos-last-point">
          As it is our policy to try to process your Order immediately it may
          not always be possible to stop an Order from being dispatched. If you
          decide to cancel your contract and your Order has already been
          dispatched, then you need to let us know within fourteen (14) days
          after the day on which you receive your Order. Once we have received
          notification by you of your wish to cancel your contract, you then
          have a further fourteen (14) days to return your Order to us.
        </p>
        <h3>Warranty</h3>
        <p>
          The conditions below describe the prerequisites and scope of our
          warranty. They do not affect your statutory rights.
        </p>
        <p>
          ANVL will rectify defects affecting the purchased product which are
          clearly attributable to material and/or manufacturing faults, provided
          they are reported immediately after being identified, and within 24
          months of the date of purchase (the “Warranty Period”).
        </p>
        <p>
          We reserve the right to invalidate the warranty, if repairs or other
          interventions are performed by persons not authorised by us to take
          such action.
        </p>
        <p className="tos-last-point">
          Warranty provision will be free of charge and we will decide whether
          this will take the form of a repair or the replacement of the
          purchased item.
        </p>
        <h3>Returns Policy</h3>
        <p>
          Please note that your right to cancel or return a Product does not
          apply to personalised Products. Personalised products being: Products
          which have been made to measure, altered, or personalised in any way
          to your specification.
        </p>
        <p>
          Your statutory rights are unaffected in regards to faulty or
          incorrectly supplied Products.
        </p>
        <p className="tos-last-point">
          In these terms and conditions, faulty goods means any goods we supply
          to you that do not conform to the contract. Faulty goods does not
          include any goods that are faulty due to fair wear and tear, wilful
          damage, accident, negligence by you or any third party, use otherwise
          than in accordance with their intended use, failure to follow the
          manufacturer's or our instructions, or any alteration or repair
          carried out without the prior approval of us or the manufacturer. You
          should notify us as soon as possible if you discover that any goods
          are faulty goods. If you return goods because you think they are
          faulty, we may charge you the cost of all transport and our actual
          costs and expenses in the event that the goods are not in fact faulty.
        </p>
        <h3>Liability and Indemnity</h3>
        <p>
          The choice of our product for any particular intended use is the sole
          and exclusive responsibility of the customer; and ANVL shall in no
          event ever have any responsibility for, nor incur any liability for
          any particular use or application of its product by the customer.
        </p>
        <p>
          While we will use reasonable endeavours to verify the accuracy of any
          information we place on the Website, we make no warranties, whether
          express or implied in relation to its accuracy. The Website is
          provided on an "as is" and "as available" basis without any
          representation or endorsement made and we make no warranties of any
          kind, whether express or implied, in relation to the Website, or any
          transaction that may be conducted on or through the Website including
          but not limited to, implied warranties of non-infringement,
          compatibility, security, accuracy, conditions of completeness, or any
          implied warranty arising from course of dealing or usage or trade.
        </p>
        <p>
          We make no warranty that the Website will meet your requirements or
          will be uninterrupted, timely or error-free, that defects will be
          corrected, or that the site or the server that makes it available are
          free of viruses or bugs or represents the full functionality,
          accuracy, and reliability of the Website.
        </p>
        <p>
          To the fullest extent permissible under applicable law, we disclaim
          any and all warranties of any kind, whether express or implied, in
          relation to the Products. This does not affect your statutory rights
          as a consumer.
        </p>
        <p>
          We will not be liable, in contract, tort (including, without
          limitation, negligence), pre-contract or other representations (other
          than fraudulent or negligent misrepresentations) or otherwise out of
          or in connection with the Terms and Conditions for any direct,
          special, incidental, indirect or consequential damages including loss
          of profit or loss of opportunity that result from the use of, or the
          inability to use, the material or Content on the Website or the
          performance of any Product purchased through the Website, even if ANVL
          has been advised of the possibility of such damages.
        </p>
        <p>
          The Website may also contain links to other websites, which are not
          operated by ANVL. When you activate any of these you will leave the
          Website and we have no control over, and will not accept any
          responsibility or liability in respect of, the material on any website
          which is not under our control.
        </p>
        <p>
          You agree to fully indemnify, defend and hold us and our agents,
          officers, directors, employees and suppliers, harmless immediately on
          demand, from and against all claims, liability, damages, losses, costs
          and expenses, including reasonable legal fees, arising out of any
          breach of these Terms and Conditions by you or any other liabilities
          arising out of your use of the Website, or the use by any other person
          accessing the Website using your ANVL Account and/or your Personal
          Information.
        </p>
        <p>
          We may terminate your use of the Website immediately if we consider
          that you have breached these Terms and Conditions.
        </p>
        <p className="tos-last-point">
          Nothing in these Terms and Conditions shall exclude or limit our
          liability for death or personal injury resulting from our negligence
          or that of our servants, agents or employees.
        </p>
        <h3>Comments and Complaints Procedure</h3>
        <p className="tos-last-point">
          Please contact us{' '}
          <a href="mailto:support@anvl.co">(support@anvl.co)</a> if you have any
          comments or complaints. We will always try to resolve any dispute as
          quickly as possible.
        </p>
        <h3>Cookie Policy</h3>
        <p>
          ANVL’s website uses cookies, in order to personalise our service to
          you. If you do not want cookies to be used, you can set your internet
          browser to deny them. Cookies are small text files which are stored on
          your computer or mobile device and which can be used to track your
          activities on the website.
        </p>
        <p>There are two types of cookies:</p>
        <p>
          a) Persistent cookies, which are stored on your computer for a fixed
          period of time.
        </p>
        <p>
          These are used to store your personal settings as well as the choices
          you make on the website. ANVL uses persistent cookies to facilitate
          your website navigation, based on your previous visits to the website.
        </p>
        <p>
          b) Session cookies, which are only stored during the time you are
          accessing the website.
        </p>
        <p>
          The session cookies disappear when you close the web browser. Session
          cookies are used to store data in personal accounts, so that
          information entered will be saved until the next time you log in. ANVL
          uses session cookies to enable your log in services and to store
          information in connection with such visitor accounts. If you do not
          want your personal settings to be saved by cookies, you can
          automatically block them, by using the settings in your web browser.
        </p>
        <p>ANVL’s cookies have three different functions:</p>
        <p>
          a) Essential cookies, i.e. cookies necessary or essential for the use
          of the website. These cookies for example allow us to identify
          subscribers or members and assure access to personal pages and orders.
        </p>
        <p>
          b) Performance cookies, i.e. cookies, such as Google Analytics,
          allowing ANVL to monitor how visitors use the website and website
          performance. This information ANVL uses as a tool to improve the
          functions of the Website, to correct any issues arising and to develop
          its business and customer facing information.
        </p>
        <p>
          c) Functionality cookies, i.e. cookies that allow the website to
          remember the choices you make (such as your user name, language or the
          region you are in) and provide enhanced, more personal features.
        </p>
        <p>ANVL does not use any behaviourally targeted advertising cookies.</p>
        <p>
          In your web browser you can control which types of cookies you allow.
          You can turn cookies on or off using your browser's settings. You can
          also delete cookies and clear your browser's cache (history). But if
          you choose to block cookies, this may impair some of the functions on
          the website and ANVL cannot ensure that you will have access to all
          services offered on the website.
        </p>
        <p className="tos-last-point">
          If you have any questions in relation to ANVL’s use of cookies, please
          contact <a href="mailto:support@anvl.co">support@anvl.co</a>
        </p>
        <h3>General</h3>
        <p>
          We may change these Terms and Conditions at any time. If any clause of
          these Terms and Conditions shall be deemed unlawful, void or for any
          reason unenforceable, then that clause shall be deemed severable from
          the Terms and Conditions and shall not affect the validity and
          enforceability of the remainder of these Terms and Conditions which
          shall continue to have full force and effect.
        </p>
        <p>
          We will not be responsible to you for any delay or failure to comply
          with our obligations under these Terms and Conditions if the delay or
          failure arises from any cause beyond our reasonable control.
        </p>
        <p>
          If you breach these Terms and Conditions and we take no action we will
          still be entitled to use our rights and remedies in other situations
          where you are in breach. No waiver by us shall be construed as a
          waiver of any proceeding or succeeding breach of any clause of these
          Terms and Conditions.
        </p>
        <p className="tos-last-point">
          ANVL reserves the right to amend, remove or vary the Website, any page
          of the Website, any Content on the Website, or any service offered on
          the Website at any time and without notice.
        </p>
        <h3>Entire Agreement</h3>
        <p className="tos-last-point">
          These Terms and Conditions govern our relationship with you and
          supersede any and all preceding and contemporaneous agreements between
          you and ANVL. Any waiver of any provision of the Terms and Conditions
          will only be effective if in writing and signed by a Director of ANVL.
          You confirm that, in agreeing to accept the Terms and Conditions, you
          have not relied on any representation save insofar as the same has
          expressly been made a clause of these Terms and Conditions and you
          agree that you shall have no remedy in respect of any representation.
          Your statutory rights are not affected by these Terms and Conditions.
        </p>
        <h3>Our Details</h3>
        <p className="tos-last-point">
          ANVL Global LLC is registered in the United States.
          Its registered office is 1925 GRAND AVE STE 129 NUM 514442, BILLINGS, MT 59102, USA.
          You can contact us at <a href="mailto:support@anvl.co">support@anvl.co</a>
        </p>
        <h3>Definitions</h3>
        <p>
          "Order" means an online transaction made by you via the Website for
          one or more Products, to which these Terms and Conditions apply.
        </p>
        <p>
          "Personal Information" means the details you provide to us when you
          use the Website, such as your name, e-mail address, billing address,
          delivery address, telephone number, Product selections, credit card or
          other payment information and a password.
        </p>
        <p>"Product" means a product displayed for sale on the Website.</p>
        <p>
          "Product Description" means that part of the Website where certain
          terms and conditions in respect of the individual Product are provided
          which shall include, but not be limited to, all details, prices (cost
          of the Product and delivery charges), photographic representations and
          descriptions of a Product. It shall also include, where appropriate,
          specific delivery dates and times, warranties, after-sales service and
          guarantees about that Product.
        </p>
        <p>"Terms and Conditions" means these terms and conditions.</p>
        <p>
          "We", "us" or "ANVL" means ANVL Global LLC. Please note, that when
          arranging delivery of your Order, ANVL may instruct a third party
          courier or postal carrier to deliver it to you. However, ANVL will
          ultimately be responsible for managing the delivery of your Order, so
          for the purposes of these Terms and Conditions, all references to
          "we", "us", or "ANVL", will be taken to include such third parties.
        </p>
        <p>
          "Website" means the website located at{' '}
          <a href="https://anvl.co/">https://anvl.co/</a> or any subsequent URL
          which may replace it.
        </p>
        <p>
          "US" means, for the purposes of these Terms and Conditions, United States.
        </p>
        <p>"Users" means the users of the Website collectively.</p>
        <p id="tos-bottom-point">"You" means a user of this Website.</p>
      </section>
    </section>
  );
}
