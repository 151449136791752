import { useState } from 'react';
// import { StarIcon } from '@heroicons/react/solid';
import { RadioGroup } from '@headlessui/react';
import { FaShoppingCart, FaCog } from 'react-icons/fa';

import './ViewMini.scss';

const product = {
  name: 'Abraham Matthias',
  price: '$19.97',
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Collection', href: '#' },
    { id: 2, name: 'ANVL', href: '#' },
  ],
  images: [
    {
      src: require('../images/placeholder/Abraham Matthias.png').default,
      alt: 'Full',
    },
    {
      src: require('../images/placeholder/Abraham Matthias (3).png').default,
      alt: 'Face',
    },
    {
      src: require('../images/placeholder/Abraham Matthias (2).png').default,
      alt: 'Base',
    },
    {
      src: require('../images/placeholder/Abraham Matthias (1).png').default,
      alt: 'Back',
    },
  ],
  materials: [
    { name: 'Download', class: 'm-download', selectedClass: 'm-download-s' },
    {
      name: 'Premium Resin',
      class: 'm-resin',
      selectedClass: 'm-resin-s',
    },
    { name: 'Steel', class: 'm-steel', selectedClass: 'm-steel-s' },
    { name: 'Brass', class: 'm-brass', selectedClass: 'm-brass-s' },
    { name: 'Bronze', class: 'm-bronze', selectedClass: 'm-bronze-s' },
    { name: 'Silver', class: 'm-silver', selectedClass: 'm-silver-s' },
    { name: 'Gold', class: 'm-gold', selectedClass: 'm-gold-s' },
  ],
  sizes: [
    { name: '28mm', inStock: true },
    { name: '56mm', inStock: true },
  ],
  description:
    'Vampires, demons, witches, and all manner of things that go bump in the night. These are the sworn foes of Abraham Matthias. Armed with an arsenal of weapons both conventional and spiritual, Matthias is on a one-man crusade to rid the living world of all things unholy. He never quite says what motivates him so fervently, but whispered rumors say it has to do with a wife and daughter he lost to a vampire...',
  highlights: ['Race: Human Male', 'Skills: Explorer', 'Height: 5ft 11"'],
  details:
    'The 6-Pack includes two black, two white, and two heather gray Basic Tees. Sign up for our subscription service and be the first to get new, exciting materials, like our upcoming "Charcoal Gray" limited release.',
};
// const reviews = { href: '#', average: 4, totalCount: 117 };

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ViewMini() {
  const [selectedMaterial, setSelectedMaterial] = useState(
    product.materials[0]
  );
  const [selectedSize, setSelectedSize] = useState(product.sizes[2]);

  return (
    <div className="app-ViewMini bg-white py-2 px-10 md:px-0">
      <div className="pt-6">
        <nav aria-label="Breadcrumb">
          <ol className="max-w-2xl mx-auto px-4 flex items-center space-x-2 sm:px-6 lg:max-w-7xl lg:px-8">
            {product.breadcrumbs.map((breadcrumb) => (
              <li key={breadcrumb.id}>
                <div className="flex items-center">
                  <a
                    href={breadcrumb.href}
                    className="mr-2 text-sm font-medium text-gray-900"
                  >
                    {breadcrumb.name}
                  </a>
                  <svg
                    width={16}
                    height={20}
                    viewBox="0 0 16 20"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="w-4 h-5 text-gray-300"
                  >
                    <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                  </svg>
                </div>
              </li>
            ))}
            <li className="text-sm">
              <a
                href={product.href}
                aria-current="page"
                className="font-medium text-gray-500 hover:text-gray-600"
              >
                {product.name}
              </a>
            </li>
          </ol>
        </nav>

        <hr className="my-8" />

        {/* Image gallery */}
        <div className="mt-6 max-w-2xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="hidden bg-gray-100 aspect-w-3 aspect-h-4 rounded-lg overflow-hidden lg:block">
            <img
              src={product.images[0].src}
              alt={product.images[0].alt}
              className="w-full h-full object-center object-cover"
            />
          </div>
          <div className="hidden   lg:grid lg:grid-cols-1 lg:gap-y-8">
            <div className=" bg-gray-200 aspect-w-3 aspect-h-2 rounded-lg overflow-hidden">
              <img
                src={product.images[1].src}
                alt={product.images[1].alt}
                className="w-full h-full object-center object-cover"
              />
            </div>
            <div className="bg-gray-100  aspect-w-3 aspect-h-2 rounded-lg overflow-hidden">
              <img
                src={product.images[2].src}
                alt={product.images[2].alt}
                className="w-full h-full object-center object-cover"
              />
            </div>
          </div>
          <div className="bg-gray-300  aspect-w-4 aspect-h-5 sm:rounded-lg sm:overflow-hidden lg:aspect-w-3 lg:aspect-h-4">
            <img
              src={product.images[3].src}
              alt={product.images[3].alt}
              className="w-full h-full object-center object-cover"
            />
          </div>
        </div>

        {/* Product info */}
        <div className="max-w-2xl mx-auto pt-10 pb-16 px-4 sm:px-6 lg:max-w-7xl lg:pt-16 lg:pb-24 lg:px-8 lg:grid lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8">
          <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {product.name}
            </h1>
          </div>

          {/* Options */}
          <div className="mt-4 lg:mt-0 lg:row-span-3">
            <h2 className="sr-only">Product information</h2>

            {/* Reviews */}
            {/* <div className="mt-6">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={classNames(
                        reviews.average > rating
                          ? 'text-gray-900'
                          : 'text-gray-200',
                        'h-5 w-5 flex-shrink-0'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{reviews.average} out of 5 stars</p>
                <a
                  href={reviews.href}
                  className="ml-3 text-sm font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {reviews.totalCount} reviews
                </a>
              </div>
            </div> */}

            <form>
              {/* materials */}
              <div>
                <div className="flex items-center justify-between">
                  <h3 className="text-sm text-gray-900 font-medium">
                    Material
                  </h3>
                  <a
                    href="#material-resin"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Premium Resin
                  </a>
                </div>

                <RadioGroup
                  value={selectedMaterial}
                  onChange={setSelectedMaterial}
                  className="mt-4"
                >
                  <RadioGroup.Label className="sr-only">
                    Choose a color
                  </RadioGroup.Label>
                  <div className="flex items-center space-x-3">
                    {product.materials.map((color) => (
                      <RadioGroup.Option
                        key={color.name}
                        value={color}
                        className={({ active, checked }) =>
                          classNames(
                            color.selectedClass,
                            active && checked ? 'ring ring-offset-1' : '',
                            !active && checked ? 'ring-2' : '',
                            '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                          )
                        }
                      >
                        <RadioGroup.Label as="p" className="sr-only">
                          {color.name}
                        </RadioGroup.Label>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            color.class,
                            'h-8 w-8 border border-black border-opacity-10 rounded-full'
                          )}
                        />
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>

              {/* Sizes */}
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm text-gray-900 font-medium">Size</h3>
                  {/* <a
                    href="#"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Size guide
                  </a> */}
                </div>

                <RadioGroup
                  value={selectedSize}
                  onChange={setSelectedSize}
                  className="mt-4"
                >
                  <RadioGroup.Label className="sr-only">
                    Choose a size
                  </RadioGroup.Label>
                  <div className="grid grid-cols-4 gap-4 sm:grid-cols-8 lg:grid-cols-4">
                    {product.sizes.map((size) => (
                      <RadioGroup.Option
                        key={size.name}
                        value={size}
                        disabled={!size.inStock}
                        className={({ active }) =>
                          classNames(
                            size.inStock
                              ? 'bg-white shadow-sm text-gray-900 cursor-pointer'
                              : 'bg-gray-50 text-gray-200 cursor-not-allowed',
                            active ? 'ring-2 ring-indigo-500' : '',
                            'group relative border rounded-md py-3 px-4 flex items-center justify-center text-sm font-medium uppercase hover:bg-gray-50 focus:outline-none sm:flex-1 sm:py-6'
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <RadioGroup.Label as="p">
                              {size.name}
                            </RadioGroup.Label>
                            {size.inStock ? (
                              <div
                                className={classNames(
                                  active ? 'border' : 'border-2',
                                  checked
                                    ? 'border-indigo-500'
                                    : 'border-transparent',
                                  'absolute -inset-px rounded-md pointer-events-none'
                                )}
                                aria-hidden="true"
                              />
                            ) : (
                              <div
                                aria-hidden="true"
                                className="absolute -inset-px rounded-md border-2 border-gray-200 pointer-events-none"
                              >
                                <svg
                                  className="absolute inset-0 w-full h-full text-gray-200 stroke-2"
                                  viewBox="0 0 100 100"
                                  preserveAspectRatio="none"
                                  stroke="currentColor"
                                >
                                  <line
                                    x1={0}
                                    y1={100}
                                    x2={100}
                                    y2={0}
                                    vectorEffect="non-scaling-stroke"
                                  />
                                </svg>
                              </div>
                            )}
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>

              <p className="mt-10 text-3xl text-gray-900">{product.price}</p>

              {/* <button
                type="submit"
                className="goldButton my-8 flex items-center justify-center px-7 py-3 border border-transparent text-base font-medium rounded-md bg-indigo-800 hover:bg-indigo-900 text-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <strong>Add To Cart</strong>
              </button> */}

              <div className="CTA">
                <a
                  href="#buy"
                  className="goldButton inline-flex justify-center w-full my-4 mt-8 text-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaShoppingCart className="mr-2" />
                  <span>Buy Now</span>
                </a>
                <a
                  href="#edit"
                  className="featureButton w-full inline-flex justify-center text-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaCog className="mr-2" />
                  <span>Customize</span>
                </a>
              </div>
            </form>
          </div>

          <div className="py-10 lg:pt-6 lg:pb-16 lg:col-start-1 lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            {/* Description and details */}
            <div>
              <h3 className="sr-only">Description</h3>

              <div className="space-y-6">
                <p className="text-base text-gray-900">{product.description}</p>
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-900">Highlights</h3>

              <div className="mt-4">
                <ul className="pl-4 list-disc text-sm space-y-2">
                  {product.highlights.map((highlight) => (
                    <li key={highlight} className="text-gray-400">
                      <span className="text-gray-600">{highlight}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* <div className="mt-10">
              <h2 className="text-sm font-medium text-gray-900">Details</h2>

              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-600">{product.details}</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
