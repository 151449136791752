import config from '../config';
import HybridLink from '../components/HybridLink';

import './ThreeSteps.scss';

function StepBox(props) {
  return (
    <div className="stepBox font-sans" id={'step-' + props.stepNumber}>
      <div className="box-border flex flex-col items-center h-full sm:items-start sm:text-left">
        <div className="font-sans text-center pb-10">
          <div className="stepHeader mb-3">
            <img src={props.stepImage} alt="Custom Tabletop Figurines" />

            <span className="stepNumber">{props.stepNumber}</span>

            <span className="stepTitle box-border text-xl font-bold leading-none tracking-wider">
              {props.stepTitle}
            </span>
          </div>

          <p className="box-border p-6 mt-6 mx-0 mb-1 text-gray-700 lg:text-center">
            {props.stepDescription}
          </p>

          <HybridLink
            className="transition stepButton inline-block px-6 py-3 mx-auto text-base font-normal leading-normal text-white no-underline align-middle bg-indigo-600 rounded-full cursor-pointer select-none md:text-xl lg:py-3 lg:px-8 hover:bg-indigo-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700"
            href={props.href}
          >
            {props.buttonText}
          </HybridLink>
        </div>
      </div>
    </div>
  );
}

export default function ThreeSteps() {
  return (
    <section className="app-ThreeSteps bg-gray-100 relative w-full font-sans leading-6 border-0 border-gray-200 border-solid text-gray-50">
      <div className="flex flex-col items-center max-w-7xl py-10 mx-auto leading-6 border-solid lg:items-stretch md:justify-center md:py-20">
        <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 px-8">
          <StepBox
            stepImage={require('../images/step-header-1.jpg').default}
            stepTitle="Design"
            stepNumber="1"
            stepDescription="Create your one of a kind custom miniature using our powerful
            character designer and get ready for your next tabletop adventure!"
            buttonText="Start Designing"
            href={config.appUrl}
          />
          <StepBox
            stepImage={require('../images/step-header-2.jpg').default}
            stepTitle="3D Print"
            stepNumber="2"
            stepDescription="From our world class Premium Resin to our stunning 14K
            Gold finish we’ve got the perfect material for all of your
            custom miniatures."
            buttonText="How It Works"
            href="/features"
          />
          <StepBox
            stepImage={require('../images/step-header-3.jpg').default}
            stepTitle="Adventure"
            stepNumber="3"
            stepDescription="Kickstart your adventure by exploring our curated collection of tabletop gaming miniatures. Available to purchase or customize."
            buttonText="Explore Collection"
            href="/collection"
          />
        </div>
      </div>
    </section>
  );
}
