import Heading from '../components/Heading';

import { BiDownload, BiCoinStack } from 'react-icons/bi';
import { FaRegHandSpock } from 'react-icons/fa';
import { RiPercentLine, RiDiscordLine } from 'react-icons/ri';
import { BsLightning } from 'react-icons/bs';

import './GoldFeatures.scss';

function GoldFeatureItem({ icon, title, description }) {
  return (
    <div className="app-GoldFeatureItem pt-6">
      <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
        <div className="-mt-6">
          <div>
            <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
              <span className="icon text-white">{icon}</span>
            </span>
          </div>
          <h3 className="heading text-lg font-medium text-gray-900 tracking-tight">
            {title}
          </h3>
          <p className="mt-2 text-base text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
}

export default function GoldFeatures() {
  return (
    <div className="app-GoldFeatures relative bg-white py-16 sm:py-24 lg:py-32 lg:pt-24 pb-0 lg:pb-0">
      <div className="anchor" id="features"></div>

      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <span className="anvil"></span>

        <Heading
          bottomHeading="The best way to download and 3D print your own custom miniatures"
          mainHeading="Subscribe To ANVL Gold Today"
        />

        {/* 
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          Phasellus lorem quam molestie id quisque diam aenean nulla in.
          Accumsan in quis quis nunc, ullamcorper malesuada. Eleifend
          condimentum id viverra nulla.
        </p> */}

        <div className="mt-20">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <GoldFeatureItem
              icon={<BiDownload />}
              title="Free STL Download"
              description="Receive an additional free download with your first order when you join ANVL Gold"
            />

            <GoldFeatureItem
              icon={<BiCoinStack />}
              title="5 free credits"
              description="Get your first 5 custom miniature downloads free every month"
            />

            <GoldFeatureItem
              icon={<RiPercentLine />}
              title={
                <>
                  50<span style={{ fontFamily: 'sans-serif' }}>%</span> off
                  downloads
                </>
              }
              description="Unlimited custom mini downloads at half price for the lifetime of your membership"
            />

            <GoldFeatureItem
              icon={<BsLightning />}
              title="Priority content"
              description="Gold members get priority treatment when requesting new content for ANVL"
            />

            <GoldFeatureItem
              icon={<RiDiscordLine />}
              title="Exclusive discord"
              description="Hang out with other ANVL Gold members in the super top secret invite-only Discord channel"
            />

            <GoldFeatureItem
              icon={<FaRegHandSpock />}
              title="Support us"
              description="Help support our mission to create the best custom miniatures on the planet"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
