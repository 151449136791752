import React, { useState } from 'react';

export default function NewsletterAlt() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = () => {
    setIsSubmitted(true); // Update state to show "Success!" after submission
  };

  return (
    <div className="app-NewsletterAlt bg-white py-16 sm:py-24 px-8">
      <div className="relative">
        <div className="mx-auto max-w-6xl px-2 sm:px-6 lg:px-8">
          <div className="relative rounded-2xl px-6 py-10 bg-indigo-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div
              aria-hidden="true"
              className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
            >
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-indigo-500 text-opacity-40"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  className="text-indigo-700 text-opacity-40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="heading text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  Never miss out on a good deal
                </h2>
                <p className="mt-6 mx-auto max-w-2xl text-lg text-indigo-200">
                  Sign up here and get the latest news, updates and special
                  offers delivered straight to your inbox. It'll be worth it, we
                  promise!
                </p>
              </div>
              <form
                onSubmit={handleSubmit} // Attach onSubmit handler here to update state
                method="POST"
                action="https://customerioforms.com/forms/submit_action?site_id=6d540b6f9fd37b2a9c89&form_id=01jckfv3e9xn1r4a7h2zhp4je5&success_url=https://anvl.co/store"
                className="mt-12 sm:mx-auto sm:max-w-xl sm:flex"
              >
                <div className="min-w-0">
                  <label htmlFor="cta_name" className="sr-only">
                    Name
                  </label>
                  <input
                    id="cta_name"
                    type="text"
                    name="name"
                    className="transition block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="min-w-0 flex-1 sm:ml-3 mt-4 sm:mt-0">
                  <label htmlFor="cta_email" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="cta_email"
                    type="email"
                    name="email"
                    className="transition block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    disabled={isSubmitted} // Disable button if submitted
                    className={`transition block w-full rounded-md border border-transparent px-5 py-3 ${
                      isSubmitted ? 'bg-green-600' : 'bg-indigo-500 hover:bg-indigo-400'
                    } text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-7`}
                  >
                    {isSubmitted ? 'Success!' : 'Subscribe'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
