import config from '../config';

import './FeatureHero.scss';

export default function FeatureHero() {
  return (
    <section className="app-FeatureHero">
      <div
        className="heroBg bg-gray-900 box-border flex-grow w-full px-8 pt-8 pb-12 mx-auto leading-6 text-gray-800 lg:pt-64 lg:pb-80"
        id=""
      >
        <div className="py-20 xs:py-0 w-full max-w-7xl mx-auto px-8 my-12 leading-6 text-left lg:flex-grow-0 lg:flex-shrink-0">
          <div className="verticalCenter">
            <div className="max-w-2xl">
              <h1
                className="mt-0 mb-5 text-8xl md:text-4xl lg:text-5xl xl:text-6xl"
                id=""
              >
                What will{' '}
                {/* <span className="font-black text-indigo-500" id=""> */}
                you
                {/* </span>{' '} */} create?
              </h1>

              <h2
                className="ribbon box-border mt-0 text-base font-normal"
                id=""
              >
                <strong>
                  Unlock trillions of unique character combinations
                </strong>
                <span className="ribbonBg" />
              </h2>

              <p
                className="tagline box-border mt-0 text-base font-normal text-gray-500 opacity-90 lg:text-xl xl:text-2xl"
                id=""
              >
                Designing your completely custom miniatures has never been
                easier! Bring your imagination to the table and use our powerful
                character designer to create your one of a kind 3D printed
                custom tabletop figurines.{' '}
              </p>

              <div className="box-border text-left text-gray-800 mt-5" id="">
                <a
                  className="goldButton inline-block mt-5 mb-5 cursor-pointer select-none"
                  href={config.appUrl}
                >
                  Start Creating Now
                </a>
              </div>
            </div>
          </div>
          <div className="hand" />

          {/* <div className="screenshot" /> */}

          {/* <div className="overlay" /> */}
        </div>
      </div>
    </section>
  );
}
