export function formatMaterial(materialId = '') {
  switch (materialId) {
    case 'resin':
      return 'Premium Resin';
    default:
      return (
        materialId.charAt(0).toUpperCase() + materialId.substr(1).toLowerCase()
      );
  }
}
