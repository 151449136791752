import { useState, useEffect, useCallback } from 'react';
import ls from 'store';

import { setToken } from '../utils/services';

const LS_KEY = '__anvl_rootStore';

export function loadAuth() {
  const loadedData = ls.get(LS_KEY);

  if (loadedData && loadedData.token) {
    setToken(loadedData.token);
  }

  if (loadedData && loadedData.basket) {
    // basket:
    //   basketData, basketDataPending, basketId, isMenuOpen,
    //   isSubscribing, paymentType, shippingAddress
  }

  return loadedData;
}

export function saveAuth(content) {
  if (!content) {
    console.warn('skip empty saveAuth');
    return;
  }

  ls.set(LS_KEY, content);
}

export function useAuth() {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const d = loadAuth();
    setAuth(d);
  }, []);

  useEffect(() => {
    const cb = (e) => {
      if (e.key === LS_KEY) {
        // console.log('store update', e.key);
        const d = loadAuth();
        setAuth(d);
      }
    };
    window.addEventListener('storage', cb);

    return () => window.removeEventListener('storage', cb);
  }, []);

  const setLocalState = useCallback((newState) => {
    ls.set(LS_KEY);
  }, []);

  return [auth, setLocalState];
}
