import { Link } from 'react-router-dom';

import config from '../config';
import Heading from '../components/Heading';

import './ShowReel.scss';

export default function ShowReel({
  moreButtonText = 'Learn More',
  moreButtonUrl = '/features',
  topHeading = 'Turn your ideas into stunning 3D models with just a few easy clicks',
  mainHeading = 'Start creating your own custom miniatures',
  description = `
            Our team of talented artists have worked hard to skillfully craft
            thousands of stunning 3D assets ready for you to mix and match as
            you please! Customize your character and choose from a huge
            selection of items, accessories and outfits to create your perfect
            custom miniature tabletop figurines.`,
}) {
  return (
    <section className="app-ShowReel">
      <div className="overflow-hidden py-14 md:py-24 md:pb-28 px-10" id="">
        {/* <h3 className="subHeading relative flex items-start w-full text-l md:text-xl font-bold tracking-wider text-indigo-500 uppercase justify-center lg:items-center">
          Turn your ideas into stunning 3D models with just a few easy clicks
        </h3>
        <h2 className="heading leading-10 mx-auto text-3xl md:text-4xl relative mt-4 flex items-start w-full font-bold justify-center mb-4">
          Start creating your own custom miniatures
        </h2> */}

        <Heading topHeading={topHeading} mainHeading={mainHeading} />

        <div className="w-full md:text-center">
          <div className="imageMarq">
            <div className="features-item-container big">
              <img
                src={
                  require('../images/thumbs/Pistol_Prop_LaserPistol_000.png')
                    .default
                }
                alt="Pistol"
              />
            </div>
            <div className="features-item-container small">
              <img
                src={require('../images/thumbs/Bow_Prop_Short_001.png').default}
                alt="Bow"
              />
            </div>
            <div className="features-item-container med">
              <img
                src={
                  require('../images/thumbs/Armor_Prop_HeavySteel_000.png')
                    .default
                }
                alt="Armor"
              />
            </div>
            <div className="features-item-container small">
              <img
                src={
                  require('../images/thumbs/Fighter_Torso_StyleTwo_000.png')
                    .default
                }
                alt="Fighter Torso"
              />
            </div>
            <div className="features-item-container big">
              <img
                src={
                  require('../images/thumbs/Rooster_Mount_StyleOne_000.png')
                    .default
                }
                alt="Rooster Mount"
              />
            </div>
            <div className="features-item-container small">
              <img
                src={
                  require('../images/thumbs/Cleric_Torso_StyleOne_000.png')
                    .default
                }
                alt="Cleric Torso"
              />
            </div>
            <div className="features-item-container med">
              <img
                src={
                  require('../images/thumbs/AxeOneHanded_Prop_Tomahawk_001.png')
                    .default
                }
                alt="Axe"
              />
            </div>
            <div className="features-item-container small">
              <img
                src={
                  require('../images/thumbs/Bandit_Legs_StyleOne_000.png')
                    .default
                }
                alt="Bandit Legs"
              />
            </div>
            <div className="features-item-container big">
              <img
                src={
                  require('../images/thumbs/Nobelwoman_Torso_StyleOne_000.png')
                    .default
                }
                alt="Nobelwoman Torso"
              />
            </div>
            <div className="features-item-container small">
              <img
                src={
                  require('../images/thumbs/Samurai_Legs_StyleOne_000.png')
                    .default
                }
                alt="Samurai Legs"
              />
            </div>
            <div className="features-item-container med">
              <img
                src={
                  require('../images/thumbs/Ranger_Torso_StyleOne_000.png')
                    .default
                }
                alt="Ranger Torso"
              />
            </div>
            <div
              className="features-item-container big"
              id="features-marq-model-3"
            >
              <img
                src={
                  require('../images/thumbs/Ranger_Head_StyleOne_000.png')
                    .default
                }
                alt="Ranger Head"
              />
            </div>
          </div>

          <p className="max-w-5xl mx-auto text-left text-lg md:text-center text-gray-600">
            {description}
          </p>

          <div className="flex flex-wrap items-left md:items-center justify-left md:justify-center mt-3">
            <a
              href={config.appUrl}
              className="goldButton mt-7 mr-3 md:mx-3 inline-flex items-center"
            >
              <span className="">Start Creating</span>
              <svg viewBox="0 0 20 20" fill="currentColor" className="h-4 ml-1">
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>

            <Link
              to={moreButtonUrl}
              className="featureButton md:mx-3 mt-7 inline-flex items-center"
            >
              <span>{moreButtonText}</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
