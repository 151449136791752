import config from '../config';

import './GoldCTA.scss';

export default function GoldCTA() {
  return (
    <div className="app-GoldCTA bg-white pb-4 sm:pb-8 md:pb-12">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="relative bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div
            aria-hidden="true"
            className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
          >
            <svg
              className="absolute inset-0 h-full w-full"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 1463 360"
            >
              <path
                className="text-indigo-500 text-opacity-40"
                fill="currentColor"
                d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
              />
              <path
                className="text-indigo-700 text-opacity-40"
                fill="currentColor"
                d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
              />
            </svg>
          </div>
          <div className="z-10 pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-18">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Got a 3D printer?</span>
                <span className="block">Join ANVL Gold today.</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                Unleash your printer's full potential with ANVL Gold. The #1
                service to download custom minis. Only $19.97 a month.
              </p>
              <a
                href={`${config.appUrl}/subscribe`}
                className="goldButton mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Sign up today</strong>
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-4 ml-1"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="-mt-6">
            <video
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              src={process.env.PUBLIC_URL + '/demo.mp4'}
              alt="App screenshot"
              autoPlay
              loop
              muted
              playsInline
              controls={false}
              disableRemotePlayback
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
}
