/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/outline';

import config from '../config';
import HybridLink from '../components/HybridLink';

import './GoldPricing.scss';

const tiers = [
  {
    name: 'Standard',
    href: `${config.appUrl}/subscribe`,
    priceMonthly: 49,
    description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
    features: [
      <>
        <strong>5 free</strong> miniature downloads a month
      </>,
      <>
        Unlimited <strong>half price</strong> downloads (50% off)
      </>,
      <>
        Get <strong>priority</strong> ANVL Gold content requests
      </>,
      <>
        Additonal <strong>free download</strong> when you subscribe
      </>,
    ],
  },
  // {
  //   name: 'Enterprise',
  //   href: '#',
  //   priceMonthly: 79,
  //   description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit.',
  //   features: [
  //     'Pariatur quod similique',
  //     'Sapiente libero doloribus modi nostrum',
  //     'Vel ipsa esse repudiandae excepturi',
  //     'Itaque cupiditate adipisci quibusdam',
  //   ],
  // },
];

export default function GoldPricing() {
  return (
    <div className="app-GoldPricing bg-gray-900">
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            {/* <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
              Pricing
            </h2> */}

            <img
              className="mx-auto mb-10 w-40"
              src={require('../images/footer-logo.png').default}
              alt="ANVL Logo"
            />

            <p className="heading text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
              Become A Gold Member
            </p>
            <p className="text-xl text-gray-300 pt-2">
              Take 3D printing your custom miniatures to the next level
            </p>
          </div>
        </div>
      </div>
      <div className="groupShot mt-8 pb-12 bg-gradient-to-br from-gray-900 to-indigo-900 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-gray-900" />
          <div className="relative z-9 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-1 lg:gap-5 lg:space-y-0">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className="glow max-w-1xl mx-auto flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="px-4 py-6 bg-white sm:p-10 sm:pb-6">
                    <img
                      src={require('../images/gold-logo.jpg').default}
                      className="lg:max-w-md rounded-2xl"
                      alt="ANVL Gold Membership"
                    />

                    {/* <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                      $19 .97
                      <span className="ml-1 text-2xl font-medium text-gray-500">
                        /mo
                      </span>
                    </div> */}
                    {/* <div>
                      <h3
                        className="mt-4 inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
                        id="tier-standard"
                      >
                        Monthly Subscription
                      </h3>
                    </div> */}

                    {/* <p className="mt-5 text-lg text-gray-500">
                      {tier.description}
                    </p> */}
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      {tier.features.map((feature, idx) => (
                        <li key={idx} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon
                              className="h-6 w-6 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-lg text-gray-700">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div className="rounded-md shadow">
                      <HybridLink
                        href={tier.href}
                        className="goldButton flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md bg-indigo-800 hover:bg-indigo-900 text-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        aria-describedby="tier-standard"
                      >
                        <strong>Join Now</strong> $19.97/mo
                      </HybridLink>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
          <div className="max-w-md mx-auto lg:max-w-5xl"></div>
        </div>
      </div>
    </div>
  );
}
