export default function BasketIcon({ quantity, onClick }) {
  return (
    <span
      className="absolute text-gray-400 hover:text-gray-200 transition duration-300"
      onClick={onClick}
    >
      <span className="sr-only">Cart</span>
      <svg
        width="24"
        height="22"
        viewBox="0 0 24 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 22C17.622 22 16.5 20.878 16.5 19.5C16.5 18.122 17.622 17 19 17C20.378 17 21.5 18.122 21.5 19.5C21.5 20.878 20.378 22 19 22ZM19 18C18.173 18 17.5 18.673 17.5 19.5C17.5 20.327 18.173 21 19 21C19.827 21 20.5 20.327 20.5 19.5C20.5 18.673 19.827 18 19 18Z"
          fill="#9EA3AE"
        />
        <path
          d="M9 22C7.622 22 6.5 20.878 6.5 19.5C6.5 18.122 7.622 17 9 17C10.378 17 11.5 18.122 11.5 19.5C11.5 20.878 10.378 22 9 22ZM9 18C8.173 18 7.5 18.673 7.5 19.5C7.5 20.327 8.173 21 9 21C9.827 21 10.5 20.327 10.5 19.5C10.5 18.673 9.827 18 9 18Z"
          fill="#9EA3AE"
        />
        <path
          d="M21 16H9.459C7.797 16 6.353 14.817 6.027 13.187L3.59 1H1C0.724 1 0.5 0.776 0.5 0.5C0.5 0.224 0.724 0 1 0H4C4.238 0 4.443 0.168 4.49 0.402L7.008 12.99C7.241 14.155 8.272 15 9.459 15H21C21.276 15 21.5 15.224 21.5 15.5C21.5 15.776 21.276 16 21 16Z"
          fill="#9EA3AE"
        />
        <path
          d="M20.234 12H6.5C6.224 12 6 11.776 6 11.5C6 11.224 6.224 11 6.5 11H20.234C20.989 11 21.629 10.435 21.723 9.686L22.434 4H5C4.724 4 4.5 3.776 4.5 3.5C4.5 3.224 4.724 3 5 3H23C23.144 3 23.28 3.062 23.375 3.169C23.47 3.276 23.514 3.42 23.496 3.562L22.715 9.81C22.559 11.059 21.492 12 20.234 12Z"
          fill="#9EA3AE"
        />
      </svg>

      {quantity > 0 && <span className="basketQuantity">{quantity}</span>}
    </span>
  );
}
