import config from '../config';

import './Hero.scss';

export default function Hero() {
  return (
    <section className="app-Hero">
      {/* //   <article className="container mx-auto px-1 text-center">
    //     <h2>Bring your characters to life</h2>
    //     <h3>
    //       Take your tabletop gaming experience to the{' '}
    //       <strong>next level</strong>
    //     </h3>
    //     <ul className="inline-flex justify-between">
    //       <li className="mr-3">
    //         <a href={config.appUrl}>Start Designing </a>
    //       </li>
    //       <li>
    //         <a href="/features">Explore Features</a>
    //       </li>
    //     </ul>
    //   </article>
    // </section> */}

      <div
        className="heroBg bg-gray-900 box-border flex-grow w-full px-10 pt-8 pb-12 mx-auto leading-6 text-gray-800 lg:pt-64 lg:pb-80"
        id=""
      >
        <div className="verticalCenter relative w-full px-0 my-12 leading-6 text-left md:text-center lg:flex-grow-0 lg:flex-shrink-0">
          <h1
            className="mt-0 mb-5 text-8xl md:text-4xl lg:text-5xl xl:text-6xl"
            id=""
          >
            Bring your characters to{' '}
            <span className="font-black text-indigo-500" id="">
              life
            </span>
          </h1>
          <p
            className="box-border mt-0 text-base font-normal text-gray-500 opacity-90 lg:text-xl xl:text-2xl"
            id=""
          >
            Take your tabletop gaming experience to the next level
          </p>
          <div
            className="box-border text-left text-gray-800 mt-5 md:text-center"
            id=""
          >
            <a
              className="goldButton inline-block mt-5 mb-5 cursor-pointer select-none"
              href={config.appUrl}
            >
              Create Your Custom Miniature
            </a>

            {/* <a
              className="inline-block px-6 py-3 mt-5 mb-5 mr-3 text-base font-normal leading-normal text-white no-underline align-middle bg-indigo-600 rounded-full cursor-pointer select-none md:text-xl lg:py-3 lg:px-8 hover:bg-indigo-700 hover:text-white focus-within:bg-blue-700 focus-within:border-blue-700"
              href="#_"
            >
              Watch Video
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}
