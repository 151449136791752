import {
  Fragment,
  // useState,
  useRef,
} from 'react';

import {
  Dialog,
  // RadioGroup,
  Transition,
} from '@headlessui/react';

import { FaShoppingCart, FaCog } from 'react-icons/fa';

import { XIcon } from '@heroicons/react/outline';
// import { StarIcon } from '@heroicons/react/solid';

import config from '../config';
import { runCheckout } from '../utils/frame';
import './CollectionQuickview.scss';

// const product = {
//   name: "Women's Basic Tee",
//   price: '$32',
//   rating: 3.9,
//   reviewCount: 512,
//   href: '#',
//   image: require('../images/collection/Whalford.png').default,
//   imageSrc:
//     'https://tailwindui.com/img/ecommerce-images/product-page-01-featured-product-shot.jpg',
//   imageAlt: "Back of women's Basic Tee in black.",
//   colors: [
//     { name: 'Black', bgColor: 'bg-gray-900', selectedColor: 'ring-gray-900' },
//     {
//       name: 'Heather Grey',
//       bgColor: 'bg-gray-400',
//       selectedColor: 'ring-gray-400',
//     },
//   ],
//   sizes: [
//     {
//       name: 'Standard',
//       description: 'Perfect for a reasonable amount of snacks.',
//     },
//     {
//       name: 'Double',
//       description: 'Enough room for a serious amount of snacks.',
//     },
//   ],
// };

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

export default function CollectionQuickview({ open, setOpen, previewMini }) {
  //   const [selectedColor, setSelectedColor] = useState(previewMini.colors[0]);
  //   const [selectedSize, setSelectedSize] = useState(previewMini.sizes[2]);

  const cartButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        initialFocus={cartButtonRef}
        as="div"
        className="app-CollectionQuickview fixed z-10 inset-0 overflow-y-auto"
        onClose={() => setOpen(false)}
      >
        <div
          className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
          style={{ fontSize: 0 }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:align-middle md:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
              <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="w-full grid grid-cols-1 gap-y-8 gap-x-6 items-start sm:grid-cols-12 lg:items-center lg:gap-x-8">
                  <div className="aspect-w-2 aspect-h-3 rounded-lg bg-gray-100 overflow-hidden sm:col-span-4 lg:col-span-5">
                    <img
                      src={previewMini.image}
                      alt={previewMini.name}
                      className="object-center object-contain p-4 px-8"
                    />
                  </div>
                  <div className="sm:col-span-8 lg:col-span-7">
                    <h2 className="heading text-2xl font-medium text-gray-900 sm:pr-12">
                      {previewMini.name}
                    </h2>

                    <section
                      aria-labelledby="information-heading"
                      className="mt-1"
                    >
                      <p className="font-medium text-xl text-gray-900">
                        {previewMini.tagline}
                      </p>

                      <hr className="mt-5" />

                      {/* Reviews */}
                      {/* <div className="mt-4">
                        <h4 className="sr-only">Reviews</h4>
                        <div className="flex items-center">
                          <p className="text-sm text-gray-700">
                            {previewMini.rating}
                            <span className="sr-only"> out of 5 stars</span>
                          </p>
                          <div className="ml-1 flex items-center">
                            {[0, 1, 2, 3, 4].map((rating) => (
                              <StarIcon
                                key={rating}
                                className={classNames(
                                  previewMini.rating > rating
                                    ? 'text-yellow-400'
                                    : 'text-gray-200',
                                  'h-5 w-5 flex-shrink-0'
                                )}
                                aria-hidden="true"
                              />
                            ))}
                          </div>
                          <div className="hidden ml-4 lg:flex lg:items-center">
                            <span className="text-gray-300" aria-hidden="true">
                              &middot;
                            </span>
                            <a
                              href="#"
                              className="ml-4 text-sm font-medium text-indigo-600 hover:text-indigo-500"
                            >
                              See all {previewMini.reviewCount} reviews
                            </a>
                          </div>
                        </div>
                      </div>
                   */}
                    </section>
                    <section aria-labelledby="options-heading" className="mt-5">
                      <p className="font-medium text-md leading-6 text-gray-500">
                        {previewMini.description}
                      </p>
                      <form>
                        {/* Color picker */}
                        {/* <div>
                          <h4 className="text-sm font-medium text-gray-900">
                            Color
                          </h4>

                          <RadioGroup
                            value={selectedColor}
                            onChange={setSelectedColor}
                            className="mt-2"
                          >
                            <RadioGroup.Label className="sr-only">
                              Choose a color
                            </RadioGroup.Label>
                            <div className="flex items-center space-x-3">
                              {previewMini.colors.map((color) => (
                                <RadioGroup.Option
                                  key={color.name}
                                  value={color}
                                  className={({ active, checked }) =>
                                    classNames(
                                      color.selectedColor,
                                      active && checked
                                        ? 'ring ring-offset-1'
                                        : '',
                                      !active && checked ? 'ring-2' : '',
                                      '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                                    )
                                  }
                                >
                                  <RadioGroup.Label as="p" className="sr-only">
                                    {color.name}
                                  </RadioGroup.Label>
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      color.bgColor,
                                      'h-8 w-8 border border-black border-opacity-10 rounded-full'
                                    )}
                                  />
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        </div> */}

                        {/* Size picker */}
                        {/* <div className="mt-8">
                          <div className="flex items-center justify-between">
                            <h4 className="text-sm font-medium text-gray-900">
                              Size
                            </h4>
                            <a
                              href="#"
                              className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                            >
                              Size guide
                            </a>
                          </div>

                          <RadioGroup
                            value={selectedSize}
                            onChange={setSelectedSize}
                            className="mt-2"
                          >
                            <RadioGroup.Label className="sr-only">
                              Choose a size
                            </RadioGroup.Label>
                            <div className="grid grid-cols-2 gap-5">
                              {previewMini.sizes.map((size) => (
                                <RadioGroup.Option
                                  key={size.name}
                                  value={size}
                                  className={({ active, checked }) =>
                                    classNames(
                                      active ? 'ring-2 ring-indigo-500' : '',
                                      'relative block border border-gray-300 rounded-lg p-4 cursor-pointer focus:outline-none'
                                    )
                                  }
                                  disabled={!size.inStock}
                                >
                                  <RadioGroup.Label
                                    as="p"
                                    className="text-base font-medium text-gray-900"
                                  >
                                    {size.name}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="p"
                                    className="mt-1 text-sm text-gray-500"
                                  >
                                    {size.description}
                                  </RadioGroup.Description>
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        </div> */}

                        <a
                          ref={cartButtonRef}
                          href={`${config.appUrl}/buy/${previewMini.uuid}`}
                          className="goldButton inline-flex justify-center items-center w-full my-4 mt-6 text-center"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.preventDefault();
                            runCheckout(`/buy/${previewMini.uuid}`);
                          }}
                        >
                          <FaShoppingCart className="mr-2" />
                          <span>Add To Cart</span>
                        </a>

                        <a
                          href={`${config.appUrl}/share/${previewMini.uuid}`}
                          className="featureButton w-full inline-flex items-center justify-center text-center"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaCog className="mr-2" />
                          <span>Customize</span>
                        </a>

                        {/* <p className="absolute top-4 left-4 text-center sm:static sm:mt-8">
                          <a
                            href={previewMini.href}
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            View full details
                          </a>
                        </p> */}
                      </form>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
