import { Link } from 'react-router-dom';

import config from '../config';
import SocialIcons from './SocialIcons';

import './Footer.scss';

export default function Footer() {
  return (
    <section className="app-Footer w-full bg-gray-900">
      <div className="px-6 mx-auto max-w-7xl xl:px-12">
        <div className="box-border flex flex-wrap pt-20 pb-0 text-base leading-tight text-gray-500 md:pb-12">
          <div className="flex-initial w-1/2 px-2 pb-12 leading-tight md:w-1/4">
            <div className="box-border text-base text-gray-500">
              <div className="flex items-center justify-center w-12 h-10 mr-3 rounded-lg mb-7">
                <img
                  className="footerLogo"
                  src={require('../images/footer-logo.png').default}
                  alt="ANVL Logo"
                />
              </div>
              <ul className="p-0 m-0 text-base leading-tight list-none">
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/custom-miniatures"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Welcome
                  </Link>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/features"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Features
                  </Link>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/store"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Store
                  </Link>
                </li>

                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/collection"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Collection
                  </Link>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/deals"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Deals
                  </Link>
                </li>
                {/* <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/gold"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    ANVL Gold
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div
            width="6,3"
            className="flex-initial w-1/2 px-2 pb-12 leading-tight md:w-1/4"
          >
            <div className="box-border text-base text-gray-500">
              <h2 className="mb-8  text-xl font-bold tracking-wide text-gray-100 md:text-2xl">
                Product
              </h2>
              <ul className="p-0 m-0 text-base leading-tight list-none">
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href={config.appUrl}
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    ANVL Custom Miniatures
                  </a>
                </li>

                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href={config.appUrl}
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Custom D&amp;D Miniatures
                  </a>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href={config.appUrl}
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Custom Tabletop Miniatures
                  </a>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href={config.appUrl}
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Custom Action Figures
                  </a>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href={config.appUrl}
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Custom Mini Maker
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            width="6,3"
            className="flex-initial w-1/2 px-2 pb-12 leading-tight md:w-1/4"
          >
            <div className="box-border text-base text-gray-500">
              <h2 className="mb-8  text-xl font-bold tracking-wide text-gray-100 md:text-2xl">
                Company
              </h2>
              <ul className="p-0 m-0 text-base leading-tight list-none">
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/contact"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/partnership-requests"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Partnership Requests
                  </Link>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/ambassador-program"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Ambassador Program
                  </Link>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href="https://blog.anvl.co"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
                {/* <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href="#_"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Careers
                  </a>
                </li> */}
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <Link
                    to="/press"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                  >
                    Press Kit
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-initial w-1/2 px-2 pb-12 leading-tight md:w-1/4">
            <div className="box-border text-base text-gray-500">
              <h2 className="mb-8  text-xl font-bold tracking-wide text-gray-100 md:text-2xl">
                Support
              </h2>
              <ul className="p-0 m-0 text-base leading-tight list-none">
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href="https://hub.anvl.co/categories/getting-started"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Getting Started
                  </a>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href="https://hub.anvl.co/categories/frequently-asked-questions"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Frequently Asked Questions
                  </a>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href="/suggest"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Suggest New Content
                  </a>
                </li>
                <li className="box-border py-2 text-sm font-normal text-left text-gray-500 md:text-base md:mb-1">
                  <a
                    href="https://hub.anvl.co"
                    className="text-base leading-tight no-underline bg-transparent cursor-pointer hover:text-gray-400"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Help Center
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-between w-full py-12 text-xs leading-none text-gray-500 border-t border-gray-800 lg:flex-row">
          <div className="flex self-start flex-grow-0 flex-shrink-0 w-full leading-none lg:w-auto">
            <SocialIcons />
          </div>
          <ul className="flex my-6 text-sm text-gray-500 list-none lg:flex-grow-0 lg:flex-shrink-0 lg:my-0">
            <li className="box-border block font-semibold text-left">
              <Link
                to="/tos"
                className="pr-5 mr-5 text-gray-500 no-underline bg-transparent border-r border-gray-700 cursor-pointer"
              >
                Terms of Service
              </Link>
            </li>
            <li className="box-border block font-semibold text-left">
              <Link
                to="/privacy"
                className="pr-5 mr-5 text-gray-500 no-underline bg-transparent border-r border-gray-700 cursor-pointer"
              >
                Privacy Policy
              </Link>
            </li>
            <li className="box-border block font-semibold text-left">
              <Link
                to="/licenses"
                className="pr-5 mr-5 text-gray-500 no-underline bg-transparent border-r border-gray-700 cursor-pointer"
              >
                Licenses
              </Link>
            </li>
            <li className="box-border block font-semibold text-left">
              <a
                href="/sitemap.xml"
                className="text-gray-500 no-underline bg-transparent cursor-pointer"
              >
                Sitemap
              </a>
            </li>
          </ul>
          <p className="block m-0 text-base font-semibold text-gray-500">
            Copyright &copy; {new Date().getFullYear()} ANVL Global LLC
          </p>
        </div>
      </div>
    </section>

    // <footer>
    //   <section>
    //     <article>
    //       <a href={config.appUrl}> Custom Miniatures </a>{' '}
    //       <a href="/accessories"> Accessories </a> <a href="/deals"> Deals </a>{' '}
    //       <a href="/contact">
    //         {' '}
    //         Ambassadors{' '}
    //         <span>
    //           Earn <strong>10%</strong> Commission
    //         </span>{' '}
    //       </a>
    //     </article>
    //   </section>
    //   <section>
    //     <article>
    //       <div>
    //         <ul>
    //           <li>
    //             <a href="/custom-miniatures">Welcome</a>
    //           </li>
    //           <li>
    //             <a href="/features">Features</a>
    //           </li>
    //           <li>
    //             <a href="/accessories">Accessories</a>
    //           </li>
    //           <li>
    //             <a href="https://help.anvl.co" target="_blank">
    //               Help
    //             </a>
    //           </li>
    //         </ul>
    //         <ul>
    //           <li>
    //             <a href="/contact">Contact Us</a>
    //           </li>
    //           <li>
    //             <a href="/tos">Terms Of Service</a>
    //           </li>
    //           <li>
    //             <a href="/privacy">Privacy Policy</a>
    //           </li>
    //         </ul>
    //         <ul>
    //           <ul>
    //             <li>
    //               <a href="mailto:support@anvl.co">support@anvl.co </a>
    //             </li>
    //           </ul>
    //         </ul>
    //         <select></select>
    //       </div>
    //       <div>
    //         <div>
    //           <span></span>
    //           <h5>ANVL - 3D Printed Custom Miniatures</h5>
    //           <a
    //             href="https://blog.anvl.co/anvl-3d-printed-custom-miniatures/"
    //             target="_blank"
    //           >
    //             <span>18th of December 2018</span> - General News{' '}
    //           </a>
    //         </div>
    //       </div>
    //     </article>
    //   </section>
    //   <section>
    //     <article>
    //       <h6>
    //         <a href="/custom-miniatures">ANVL</a>
    //       </h6>
    //       <p>©2024 ANVL Global LLC - All Rights Reserved</p>
    //       <ul>
    //         <li>
    //           <a href="https://www.instagram.com/anvlminis/" target="_blank">
    //             Instagram{' '}
    //           </a>
    //         </li>
    //         <li>
    //           <a href="https://twitter.com/anvlminis" target="_blank">
    //             Twitter{' '}
    //           </a>
    //         </li>
    //         <li>
    //           <a href="https://www.facebook.com/anvlminis" target="_blank">
    //             Facebook{' '}
    //           </a>
    //         </li>
    //         <li>
    //           <a href="mailto:yg9lvgs2@anvl.intercom-mail.com">
    //             Need Some Help?{' '}
    //           </a>
    //         </li>
    //       </ul>
    //     </article>
    //   </section>
    // </footer>
  );
}
