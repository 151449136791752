import { Fragment, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { XIcon } from '@heroicons/react/outline';

import './CollectionShareModal.scss';

export default function CollectionShareModal({
  shareModalOpen,
  setShareModalOpen,
}) {
  const cartButtonRef = useRef(null);

  return (
    <Transition.Root show={shareModalOpen} as={Fragment}>
      <Dialog
        initialFocus={cartButtonRef}
        as="div"
        className="app-CollectionShareModal fixed z-10 inset-0 overflow-y-auto"
        onClose={() => setShareModalOpen(false)}
      >
        <div
          className="flex min-h-screen text-center md:block md:px-2 lg:px-4"
          style={{ fontSize: 0 }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity md:block" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden md:inline-block md:align-middle md:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="flex text-base text-left transform transition w-full md:inline-block md:max-w-2xl md:px-4 md:my-8 md:align-middle lg:max-w-4xl">
              <div className="w-full relative flex items-center bg-white px-4 pt-14 pb-8 overflow-hidden shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                <button
                  type="button"
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                  onClick={() => setShareModalOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className="w-full grid grid-cols-1 gap-x-6 items-start text-center">
                  <h2 className="heading text-2xl lg:text-4xl">
                    Share Your Favorite Designs
                  </h2>
                  <h3 className="text-2xl lg:text-4xl text-indigo-700">
                    For a chance to win <strong>free</strong> credits
                  </h3>

                  <div className="flex items-center justify-center rounded-lg w-full h-40 lg:h-96 my-10 bg-gray-100 overflow-hidden relative">
                    <img
                      className="object-center object-cover w-full"
                      src={require('../images/suggest-bg.png').default}
                      alt=""
                    />

                    <span className="absolute p-4 px-5 text-white text-4xl lg:text-6xl tracking-wide bg-indigo-600 bg-opacity-80 backdrop-blur-2xl">
                      #ANVLMinis
                    </span>
                  </div>

                  <div className="socialIcons grid grid-cols-5 gap-4 justify-center text-center">
                    <a
                      href="https://facebook.com/ANVLMinis"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M50 8.3335C26.9875 8.3335 8.33337 26.9877 8.33337 50.0002C8.33337 73.0127 26.9875 91.6668 50 91.6668C73.0125 91.6668 91.6667 73.0127 91.6667 50.0002C91.6667 26.9877 73.0125 8.3335 50 8.3335Z"
                          fill="url(#paint0_linear_4_86)"
                        />
                        <path
                          d="M55.6396 61.044H66.423L68.1167 50.0898H55.6396V44.1023C55.6396 39.5523 57.1271 35.5169 61.3834 35.5169H68.223V25.9586C67.0209 25.7961 64.4792 25.4419 59.6771 25.4419C49.648 25.4419 43.7688 30.7377 43.7688 42.8044V50.0919H33.4584V61.0461H43.7667V91.1544C45.8084 91.4586 47.8771 91.6669 50 91.6669C51.9188 91.6669 53.7917 91.4919 55.6396 91.2419V61.044Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_4_86"
                            x1="20.8188"
                            y1="20.8189"
                            x2="84.6146"
                            y2="84.6147"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#2AA4F4" />
                            <stop offset="1" stop-color="#007AD9" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </a>

                    <a
                      href="https://instagram.com/ANVLMinis"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M70.8688 87.479L29.2022 87.5186C20.0355 87.5269 12.5293 80.0353 12.5188 70.8686L12.4793 29.2019C12.4709 20.0353 19.9626 12.529 29.1293 12.5186L70.7959 12.479C79.9626 12.4707 87.4688 19.9623 87.4793 29.129L87.5188 70.7957C87.5293 79.9644 80.0355 87.4707 70.8688 87.479Z"
                          fill="url(#paint0_radial_4_94)"
                        />
                        <path
                          d="M70.8688 87.479L29.2022 87.5186C20.0355 87.5269 12.5293 80.0353 12.5188 70.8686L12.4793 29.2019C12.4709 20.0353 19.9626 12.529 29.1293 12.5186L70.7959 12.479C79.9626 12.4707 87.4688 19.9623 87.4793 29.129L87.5188 70.7957C87.5293 79.9644 80.0355 87.4707 70.8688 87.479Z"
                          fill="url(#paint1_radial_4_94)"
                        />
                        <path
                          d="M50.0001 64.5832C41.9605 64.5832 35.4167 58.0415 35.4167 49.9998C35.4167 41.9582 41.9605 35.4165 50.0001 35.4165C58.0397 35.4165 64.5834 41.9582 64.5834 49.9998C64.5834 58.0415 58.0397 64.5832 50.0001 64.5832ZM50.0001 39.5832C44.2563 39.5832 39.5834 44.2561 39.5834 49.9998C39.5834 55.7436 44.2563 60.4165 50.0001 60.4165C55.7438 60.4165 60.4167 55.7436 60.4167 49.9998C60.4167 44.2561 55.7438 39.5832 50.0001 39.5832Z"
                          fill="white"
                        />
                        <path
                          d="M65.625 37.5C67.3509 37.5 68.75 36.1009 68.75 34.375C68.75 32.6491 67.3509 31.25 65.625 31.25C63.8991 31.25 62.5 32.6491 62.5 34.375C62.5 36.1009 63.8991 37.5 65.625 37.5Z"
                          fill="white"
                        />
                        <path
                          d="M62.5001 77.0832H37.5001C29.4605 77.0832 22.9167 70.5415 22.9167 62.4998V37.4998C22.9167 29.4582 29.4605 22.9165 37.5001 22.9165H62.5001C70.5397 22.9165 77.0834 29.4582 77.0834 37.4998V62.4998C77.0834 70.5415 70.5397 77.0832 62.5001 77.0832ZM37.5001 27.0832C31.7563 27.0832 27.0834 31.7561 27.0834 37.4998V62.4998C27.0834 68.2436 31.7563 72.9165 37.5001 72.9165H62.5001C68.2438 72.9165 72.9167 68.2436 72.9167 62.4998V37.4998C72.9167 31.7561 68.2438 27.0832 62.5001 27.0832H37.5001Z"
                          fill="white"
                        />
                        <defs>
                          <radialGradient
                            id="paint0_radial_4_94"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(40.3751 87.5728) scale(93.5396)"
                          >
                            <stop stop-color="#FFDD55" />
                            <stop offset="0.328" stop-color="#FF543F" />
                            <stop offset="0.348" stop-color="#FC5245" />
                            <stop offset="0.504" stop-color="#E64771" />
                            <stop offset="0.643" stop-color="#D53E91" />
                            <stop offset="0.761" stop-color="#CC39A4" />
                            <stop offset="0.841" stop-color="#C837AB" />
                          </radialGradient>
                          <radialGradient
                            id="paint1_radial_4_94"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(24.5543 11.5421) scale(62.1104 41.3842)"
                          >
                            <stop stop-color="#4168C9" />
                            <stop
                              offset="0.999"
                              stop-color="#4168C9"
                              stop-opacity="0"
                            />
                          </radialGradient>
                        </defs>
                      </svg>
                    </a>

                    <a
                      href="https://twitter.com/ANVLMinis"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M96.052 22.9585C92.8208 24.3897 89.3458 25.3439 85.6791 25.796C89.4062 23.5835 92.2749 20.0543 93.6208 15.8835C90.1333 17.9272 86.2687 19.4293 82.1583 20.221C78.8749 16.7418 74.1916 14.5835 69.0041 14.5835C59.0395 14.5835 50.9604 22.5897 50.9604 32.4627C50.9604 33.8606 51.1249 35.221 51.4312 36.5418C36.4395 35.7897 23.1416 28.6627 14.2458 17.846C12.6833 20.4897 11.8041 23.5835 11.8041 26.8502C11.8041 33.0564 14.9812 38.521 19.827 41.7397C16.8687 41.6502 14.0854 40.8272 11.6479 39.5064C11.6479 39.5606 11.6479 39.6397 11.6479 39.7168C11.6479 48.3897 17.8687 55.6127 26.1145 57.2564C24.6083 57.6689 23.0145 57.8981 21.3729 57.8981C20.2083 57.8981 19.0729 57.7668 17.9729 57.5689C20.2687 64.6564 26.9291 69.8377 34.8208 69.9918C28.6458 74.7793 20.8687 77.646 12.4124 77.646C10.952 77.646 9.52285 77.5627 8.11035 77.3918C16.1041 82.4522 25.5895 85.4168 35.7729 85.4168C68.9541 85.4168 87.1083 58.1689 87.1083 34.5293C87.1083 33.7543 87.0833 32.9835 87.0479 32.221C90.5895 29.7189 93.6416 26.5647 96.052 22.9585Z"
                          fill="url(#paint0_linear_4_105)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_4_105"
                            x1="21.5437"
                            y1="17.3168"
                            x2="84.9958"
                            y2="80.7689"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#2AA4F4" />
                            <stop offset="1" stop-color="#007AD9" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </a>

                    <a
                      href="https://www.youtube.com/channel/UC2SGS3Ko4H1OI4LKyBHdskA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M93.7751 72.3333C92.8605 77 88.9751 80.5562 84.1751 81.2229C76.6313 82.3333 64.0584 83.6667 49.8855 83.6667C35.9417 83.6667 23.3688 82.3333 15.5959 81.2229C10.7959 80.5562 6.90841 77 5.99591 72.3333C5.08133 67.2229 4.16675 59.6667 4.16675 50.3333C4.16675 41 5.08133 33.4437 5.99591 28.3333C6.9105 23.6667 10.7959 20.1104 15.5959 19.4438C23.1397 18.3333 35.7126 17 49.8855 17C64.0584 17 76.4022 18.3333 84.1751 19.4438C88.9751 20.1104 92.8626 23.6667 93.7751 28.3333C94.6897 33.4437 95.8334 41 95.8334 50.3333C95.6042 59.6667 94.6897 67.2229 93.7751 72.3333Z"
                          fill="url(#paint0_linear_4_107)"
                        />
                        <path
                          opacity="0.05"
                          d="M67.4 46.5418L43.575 30.6585C42.3729 29.8564 40.8375 29.7814 39.5646 30.4647C38.2917 31.146 37.5 32.4647 37.5 33.9085V65.6751C37.5 67.1189 38.2917 68.4397 39.5646 69.121C40.1437 69.4314 40.775 69.5835 41.4062 69.5835C42.1646 69.5835 42.9188 69.3626 43.5729 68.9272L67.3979 53.0439C68.4875 52.3168 69.1375 51.1022 69.1375 49.7939C69.1396 48.4835 68.4875 47.2689 67.4 46.5418Z"
                          fill="black"
                        />
                        <path
                          opacity="0.07"
                          d="M43.0855 31.4828L65.5647 46.4703C67.0001 47.5015 67.9668 48.4245 67.9668 49.6224C67.9668 50.8203 67.5001 51.6557 66.4772 52.4015C65.7043 52.964 43.4667 67.7432 43.4667 67.7432C41.5897 69.0015 38.5417 68.7349 38.5417 64.6203V34.6224C38.5417 30.4453 41.8417 30.6536 43.0855 31.4828Z"
                          fill="black"
                        />
                        <path
                          d="M39.5833 65.4733V33.942C39.5833 32.3941 41.3083 31.4691 42.5978 32.3295L66.2478 48.0962C67.3999 48.8629 67.3999 50.5545 66.2478 51.3233L42.5978 67.0899C41.3083 67.9462 39.5833 67.0233 39.5833 65.4733Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_4_107"
                            x1="20.4501"
                            y1="20.8979"
                            x2="85.9292"
                            y2="86.3771"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#F44F5A" />
                            <stop offset="0.443" stop-color="#EE3D4A" />
                            <stop offset="1" stop-color="#E52030" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </a>

                    <a
                      href="https://discord.gg/XRDhBds6Wg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M83.5562 25.3126C83.4208 25.1001 83.2666 24.9334 83.0645 24.7813C81.4625 23.5771 73.3333 17.8251 62.9291 16.7084C62.9083 16.7063 62.8291 16.7021 62.8104 16.7021C62.6062 16.7021 62.4291 16.8209 62.3437 16.9938L61.7833 18.1146H61.7812C61.7458 18.1834 61.727 18.2626 61.727 18.3459C61.727 18.573 61.8729 18.7667 62.077 18.8376C62.0895 18.8417 62.177 18.8605 62.2083 18.8667C71.0979 21.0084 76.5062 24.8876 81.2687 29.1688C72.8375 24.8646 64.5104 20.8334 50 20.8334C35.4895 20.8334 27.1645 24.8667 18.7312 29.1688C23.4937 24.8876 28.902 21.0084 37.7916 18.8667C37.8229 18.8584 37.9104 18.8417 37.9229 18.8376C38.127 18.7667 38.2729 18.573 38.2729 18.3459C38.2729 18.2626 38.2541 18.1834 38.2187 18.1146H38.2166L37.6562 16.9938C37.5708 16.8209 37.3958 16.7021 37.1895 16.7021C37.1708 16.7021 37.0895 16.7063 37.0708 16.7084C26.6666 17.8251 18.5374 23.5771 16.9354 24.7813C16.7333 24.9334 16.5812 25.1001 16.4437 25.3126C14.9687 27.6188 5.92495 42.7876 4.1687 70.3792C4.14995 70.6751 4.25203 70.9813 4.44995 71.2021C14.0166 81.9188 27.7458 83.1855 30.675 83.3334C31.0229 83.3501 31.3562 83.198 31.5645 82.9188L34.2354 79.3584C34.4437 79.0792 34.3312 78.6876 34.0041 78.5688C28.3687 76.5146 22.0937 72.9438 16.6458 66.6813C23.3979 71.7855 33.5833 77.0834 50 77.0834C66.4166 77.0834 76.602 71.7855 83.352 66.6813C77.902 72.9438 71.6291 76.5126 65.9937 78.5688C65.6666 78.6876 65.5541 79.0792 65.7625 79.3584L68.4333 82.9188C68.6437 83.198 68.975 83.3521 69.3229 83.3334C72.2541 83.1834 85.9812 81.9188 95.5479 71.2021C95.7458 70.9813 95.8479 70.6751 95.8291 70.3792C94.075 42.7876 85.0312 27.6188 83.5562 25.3126Z"
                          fill="url(#paint0_radial_4_113)"
                        />
                        <path
                          opacity="0.05"
                          d="M63.5417 64.5833C68.7194 64.5833 72.9168 59.9196 72.9168 54.1667C72.9168 48.4137 68.7194 43.75 63.5417 43.75C58.3641 43.75 54.1667 48.4137 54.1667 54.1667C54.1667 59.9196 58.3641 64.5833 63.5417 64.5833Z"
                          fill="black"
                        />
                        <path
                          opacity="0.05"
                          d="M63.5416 63.5415C68.144 63.5415 71.8749 59.3442 71.8749 54.1665C71.8749 48.9888 68.144 44.7915 63.5416 44.7915C58.9392 44.7915 55.2083 48.9888 55.2083 54.1665C55.2083 59.3442 58.9392 63.5415 63.5416 63.5415Z"
                          fill="black"
                        />
                        <path
                          d="M63.5417 62.5002C67.5687 62.5002 70.8333 58.7692 70.8333 54.1668C70.8333 49.5645 67.5687 45.8335 63.5417 45.8335C59.5146 45.8335 56.25 49.5645 56.25 54.1668C56.25 58.7692 59.5146 62.5002 63.5417 62.5002Z"
                          fill="white"
                        />
                        <path
                          opacity="0.05"
                          d="M36.4583 64.5833C41.6359 64.5833 45.8333 59.9196 45.8333 54.1667C45.8333 48.4137 41.6359 43.75 36.4583 43.75C31.2806 43.75 27.0833 48.4137 27.0833 54.1667C27.0833 59.9196 31.2806 64.5833 36.4583 64.5833Z"
                          fill="black"
                        />
                        <path
                          opacity="0.05"
                          d="M36.4583 63.5415C41.0607 63.5415 44.7917 59.3442 44.7917 54.1665C44.7917 48.9888 41.0607 44.7915 36.4583 44.7915C31.856 44.7915 28.125 48.9888 28.125 54.1665C28.125 59.3442 31.856 63.5415 36.4583 63.5415Z"
                          fill="black"
                        />
                        <path
                          d="M36.4584 62.5002C40.4855 62.5002 43.7501 58.7692 43.7501 54.1668C43.7501 49.5645 40.4855 45.8335 36.4584 45.8335C32.4313 45.8335 29.1667 49.5645 29.1667 54.1668C29.1667 58.7692 32.4313 62.5002 36.4584 62.5002Z"
                          fill="white"
                        />
                        <defs>
                          <radialGradient
                            id="paint0_radial_4_113"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(50 20.8521) scale(67.1917)"
                          >
                            <stop stop-color="#8C9EFF" />
                            <stop offset="0.368" stop-color="#889AF8" />
                            <stop offset="0.889" stop-color="#7E8FE6" />
                            <stop offset="1" stop-color="#7B8CE1" />
                          </radialGradient>
                        </defs>
                      </svg>
                    </a>
                  </div>

                  <p className="text-xl w-full text-center px-2 lg:px-8 my-8 mb-5">
                    We love seeing your custom minis out in the wild, so share
                    your favorites and we might just reach out to you with a
                    special prize! Simply post a photo and a link to your best
                    minis on social media using <strong>#ANVLMinis</strong> 😃
                  </p>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
