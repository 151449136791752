import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import config from '../config';
import AttachClassToElement from '../components/AttachClassToElement';

import '../components/CheckoutFrame.scss';

export function CheckoutFrame({
  url = '/checkout/full',
  append = '?iframe=true',
  onClose,
}) {
  useEffect(() => {
    const cb = (m) => {
      if (m.origin !== window.origin) {
        return;
      }

      let data;
      try {
        data = JSON.parse(m.data);
      } catch (err) {
        console.warn('failed to parse message', m.data);
        return;
      }

      if (!data || !data.type) {
        // console.warn('missing event data type', data);
        return;
      }

      if (data.type === 'CHECKOUT_CLOSE') {
        console.log('closing', ...Object.keys(data));

        if (data.order) {
          window.location = window.origin + '/confirmation/' + data.order.id;
        }

        onClose?.();

        return;
      }

      console.log('unknown data type:', data.type);
    };

    window.addEventListener('message', cb);
    // frame.contentWindow.postMessage('123', '*');

    return () => {
      console.log('cleanup');
      window.removeEventListener('message', cb);
    };

    // only want this effect to run once on mount:
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <iframe
        title="Checkout"
        src={config.appUrl + url + append}
        className="-iframe"
        // onLoad={handleCheckoutLoaded}
      />
    </>
  );
}

// /buy/7f6c25ed-80e3-4fa7-8545-5448fb7ac33d
export function runCheckout(url = '/checkout/full') {
  const container = document.createElement('div');
  document.body.appendChild(container);

  ReactDOM.render(
    <div className="app-CheckoutFrame">
      <div className="-popover">
        <CheckoutFrame url={url} onClose={cleanup} />
      </div>

      <AttachClassToElement
        getElement={() => document.body}
        className="app-CheckoutFrame-active"
      />
    </div>,
    container
  );

  function cleanup() {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  }
}
