import './DealsCTA.scss';
import HybridLink from './HybridLink';

export default function DealsCTA() {
  return (
    <section className="app-DealsCTA bg-indigo-700">
      <section className="z-10 relative py-24 ">
        <div className="max-w-6xl px-10 mx-auto xl:max-w-7xl">
          <div className="flex flex-col items-start justify-between lg:flex-row">
            <div className="relative">
              <h2 className="heading text-4xl font-extrabold text-white xl:text-5xl">
                Looking for more great deals?
              </h2>
              <p className="mt-2 text-xl text-indigo-400">
                Check out the latest discounts and promotions from ANVL Custom
                Miniatures
              </p>
            </div>
            <HybridLink
              href="/deals"
              className="goldButton bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
            >
              <strong>View All Deals</strong>
              <svg viewBox="0 0 20 20" fill="currentColor" className="h-4 ml-1">
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </HybridLink>
          </div>
        </div>
      </section>

      <img src={require('../images/groupshot.jpg').default} alt="" />
    </section>
  );
}
